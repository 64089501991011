import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { MatDialog } from '@angular/material/dialog';
import { RiskItem } from '../model/risks';

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.css']
})
export class RiskComponent extends NpcRequestSectionBase implements OnInit, OnChanges, AfterViewInit {

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    this.fields = {
      generalRemarks: new FormControl(null, []),
      marketRisk : this.createSubFormGroup('marketRisk'),
      liquidityRisk : this.createSubFormGroup('liquidityRisk'),
      creditRisk : this.createSubFormGroup('creditRisk'),
      riskRequirementForProductSetup : this.createSubFormGroup('riskRequirementForProductSetup'),
      otherRisks : this.createSubFormGroup('otherRisks'),
    };
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  private createSubFormGroup(fieldId: string): FormGroup {
    if (this.isReadOnly(fieldId)) {
      return new FormGroup({
        isPresent: new FormControl({value: false, disabled: true}, []),
        mitigation: new FormControl({value: null, disabled: true}, [])
      });
    } else {
      return new FormGroup({
        isPresent: new FormControl(false, []),
        mitigation: new FormControl(null, []),
      });
    }
  }

  public isEnabled(riskName: string): boolean {
    return this.form.get(riskName)?.get('isPresent')?.value || false;
  }

  public changeRisk(event: any, riskId: string) {
    const isPresent: boolean = this.form.get(riskId)?.get('isPresent')?.value || false;
    if (!isPresent) {
      this.form.get(riskId)?.get('mitigation')?.setValue(null);
      this.form.get(riskId)?.get('mitigation')?.setValidators([]);
    } else {
      this.form.get(riskId)?.get('mitigation')?.setValidators([Validators.required]);
    }
    this.form.get(riskId)?.get('mitigation')?.updateValueAndValidity(); 
  }

  protected override getFormValue(): any {
    const risks: string[] = Object.keys(this.form.controls).filter((key: string) => key !== 'generalRemarks');
    const model: any = {
      generalRemarks: this.form.get('generalRemarks')?.value
    };
    for (const risk of risks) {
      const isPresent: boolean = this.form.get(risk)?.get('isPresent')?.value || false;
      const mitigation: string[] = isPresent ? [this.form.get(risk)?.get('mitigation')?.value] || [] : [];
      model[risk] = { isPresent, mitigation };
    }
    return model;
  }


  protected override getValueFromModel(fieldName: string): any {
    if (this.model && this.model.hasOwnProperty(fieldName)) {
      if (fieldName === 'generalRemarks') {
        return (this.model as any)[fieldName];
      }
      const value: RiskItem = (this.model as any)[fieldName];
      return { 
        isPresent: value.isPresent, 
        mitigation: Array.isArray(value.mitigation) && value.mitigation.length > 0 ? value.mitigation[0] : null };
    }
    return undefined;
  }

}
