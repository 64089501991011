import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { LoginComponent } from './login/login.component';
import { NpcRequestListComponent } from './npc-request-list/npc-request-list.component';
import { NpcRequestComponent } from './npc-request/npc-request.component';
import { ProductCatalogueComponent } from './product-catalogue/product-catalogue.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { RichTextPageDemoComponent } from './rich-text-editor/rich-text-page-demo.component';
import { AttachmentPageDemoComponent } from './attachment/attachment-page-demo.component';
import { RequestApprovalDialogComponent } from './npc-request/workflow/request-approval/request-approval-dialog.component';
import { CreateImplementationDialogComponent } from './npc-request/workflow/create-implementation/create-implementation-dialog.component';
import { UpdateImplementationDialogComponent } from './npc-request/workflow/update-implementation/update-implementation-dialog.component';
import { UsersListComponent } from './users/list/users-list.component';
import { ProductCheckerComponent } from './npc-request/workflow/check-product/check-product.component';
import { ProductViewerComponent } from './product-catalogue/product-viewer/product-viewer.component';
import { AuthService } from './authentication/auth.service';

const routes: Routes = [
  {path: 'npc-request', component: NpcRequestComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'npc-list', component: NpcRequestListComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'products', component: ProductCatalogueComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'product', component: ProductViewComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'login', component: LoginComponent},
  {path: 'rich-text-demo', component: RichTextPageDemoComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'attachment-demo', component: AttachmentPageDemoComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'request-approval', component: RequestApprovalDialogComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'create-implementation', component: CreateImplementationDialogComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'update-implementation', component: UpdateImplementationDialogComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'users', component: UsersListComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'check-product', component: ProductCheckerComponent, canActivate: [AuthGuard, AuthService]},
  {path: 'product-viewer', component: ProductViewerComponent, canActivate: [AuthGuard, AuthService]},
  {path: '', pathMatch: 'full', redirectTo: 'products'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
