<div [ngClass]="{'floating-tree-opened': visible, 'floating-tree-closed' : !visible }">
  <div class="tree-header">
    <div class="tree-header-heading">
      <h2><mat-icon class="mat-icon-rtl-mirror">
          filter_alt
        </mat-icon> Navigate Product Tree Selection</h2>
    </div>
    <div class="tree-header-breadcrumb" cdkDropList 
      (cdkDropListDropped)="drop($event)" 
      cdkDropListOrientation="horizontal" cdkDropListLockAxis="x">
      <ng-template #placeholderTemplate>
        <span class="breadcrumb-drag-placeholder">Placeholder Content</span>
      </ng-template>
      <span *ngFor="let group of filteringGroups; last as isLast" class="tree-header-breadcrumb-item" [ngClass]="{'drag-visualization': isDragging }" cdkDrag (cdkDragStarted)="drag($event)">
        {{ capitalizeText(group) }} 
        <span>
          <mat-icon class="mat-icon-rtl-mirror" style="font-size: small; vertical-align: sub; width: 12px; height: 12px;">
            drag_indicator
          </mat-icon>
        </span> 
        <span *ngIf="!isLast"> > </span>
        <!-- Apply the placeholder template -->
        <!-- Drag Placeholder Template -->
        <ng-template cdkDragPlaceholder>
          <span class="breadcrumb-drag-placeholder">{{ capitalizeText(group) }}</span>
        </ng-template>
      </span>
      
    </div>
  </div>
  <div class="containerDrop" #containerDiv>
    <!--div class="drop-zone" [style.display]="isShaking?null:'none'">
      <div [style.display]="isShaking?null:'none'" class="level-list" #levelContainer>
        <div *ngFor="let level of levels" [class.active]="level === currentDragLevel" class="drop-node">
          {{ filteringGroups[level] }}
        </div>
      </div>
    </div-->
    <div *ngIf="this.dataSource && this.dataSource.data && isDataReady">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" id="plm-filter-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <button mat-icon-button disabled color="primary"></button>
          <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
            (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox [checked]="descendantsAllSelected(node)" color="primary"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
</div>
<div [ngClass]="{'tree-opener-opened': visible, 'tree-opener-closed' : !visible }" style="cursor: pointer;"
  (click)="visible = !visible">
  <mat-icon class="mat-icon-rtl-mirror" style="writing-mode: horizontal-tb">
    menu_open
  </mat-icon>
  <span style="margin-left: 10px; margin-right: 10px"><span *ngIf="!visible">Open</span> <span
      *ngIf="visible">Close</span> filter panel</span>
</div>