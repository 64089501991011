<div class="title-container">
    <div>
        <h1 class="title">{{ requestType | titlecase }}</h1>
        <span *ngIf="readOnlyMode && canEdit">
            <button mat-icon-button (click)="goToEditMode()">
            <mat-icon>edit</mat-icon>
            </button>
        </span>
    </div>
    <div class="workflow-actions-container" *ngIf="isAdmin">
        <div class="workflow-actions">
            <mat-form-field *ngIf="!isNew">
                <mat-label>Actions</mat-label>
                <mat-select (selectionChange)="onActionSelected($event.value)" [formControl]="selectedOptionControl" class="actions-selector">
                    <mat-option *ngFor="let transition of transitions" [value]="transition">
                    {{transition.action}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="workflow-actions">
            <mat-form-field *ngIf="!isNew">
                <mat-label>Admin Actions</mat-label>
                <mat-select (selectionChange)="onAdminActionSelected($event.value)" [formControl]="selectedAdminOptionControl" class="actions-selector">
                    <mat-option *ngFor="let action of adminActions" [value]="action">{{action}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>

<mat-accordion *ngIf="!isNew && requestMetadata">
    <mat-expansion-panel (opened)="infoPanelOpenState = true" (closed)="infoPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title><h3>Request Information</h3></mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <div>Status: {{getStatusLabel(requestMetadata.status)}}</div>
            <div>Type: {{requestType}}</div>
            <div>NPC Request Number: {{requestMetadata.productNumber}}</div>
            <div>Created by {{requestMetadata.authorCreation}} on {{requestMetadata.created | date:'YYYY-MM-dd H:mm:ss'}}</div>
            <div>Last modified by {{requestMetadata.authorModification}} on {{requestMetadata.modified | date:'YYYY-MM-dd H:mm:ss'}}</div>
            <div *ngIf="(isTechnicalChange() || isExtension()) && requestMetadata.parentProductId">
                <span>Parent product:&nbsp;</span>
                <span><a href="#" (click)="expandProductView($event, requestMetadata.parentProductId)">{{requestMetadata.parentProductId}}</a></span>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="!isNew && requestMetadata && requestMetadata.transitions && requestMetadata.transitions.length > 0">
    <mat-expansion-panel (opened)="transitionsPanelOpenState = true" (closed)="transitionsPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title><h3>Workflow Transitions</h3></mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <table class="info-table">
            <tr>
                <th>Date</th>
                <th>Initiator</th>
                <th>From status</th>
                <th>To status</th>
                <th>Approval Request Type</th>
                <th>Approval Response Type</th>
            </tr>
            <tr *ngFor="let transition of requestMetadata.transitions">
                <td>{{transition.doneAt | date:'YYYY-MM-dd H:mm:ss'}}</td>
                <td>{{transition.initiator}}</td>
                <td>{{getStatusLabel(transition.fromStatus)}}</td>
                <td>{{getStatusLabel(transition.toStatus)}}</td>
                <td>{{transition.approvalRequestType}}</td>
                <td>{{transition.approvalType}}</td>
            </tr>
        </table>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="!isNew && requestMetadata && requestMetadata.approvals && requestMetadata.approvals.length > 0">
    <mat-expansion-panel (opened)="approvalsPanelOpenState = true" (closed)="approvalsPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title><h3>Approvals</h3></mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <table class="info-table">
            <tr>
                <th>Type</th>
                <th>Approval Status</th>
                <th>Creation Date</th>
                <th>Termination Date</th>
                <th>Individual Results</th>
            </tr>
            <tr *ngFor="let approval of requestMetadata.approvals">
                <td>{{approval.approvalType}}</td>
                <td>{{ approval.status !== null ? (approval.status ? "Approved" : "Rejected") : "pending"}}</td>
                <td>{{approval.created | date:'YYYY-MM-dd H:mm:ss'}}</td>
                <td>{{approval.terminated ? (approval.terminated | date:'YYYY-MM-dd H:mm:ss') : ""}}</td>
                <td>
                    <table *ngIf="approval.individualResults && approval.individualResults.length > 0" class="info-table">
                        <tr>
                            <th>Approver</th>
                            <th>Status</th>
                        </tr>
                        <tr *ngFor="let individualResult of approval.individualResults">
                            <td>{{individualResult.name}}</td>
                            <td>{{individualResult.status}}</td>
                        </tr>
                    </table>
                </td>
        </table>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="!isNew && requestMetadata && requestMetadata.task?.holderTask">
    <mat-expansion-panel (opened)="jiraTasksPanelOpenState = true" (closed)="jiraTasksPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title><h3>Jira Implementation Tasks</h3></mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div>Main JIRA Task:&nbsp;
            <a href="https://alpiqdigital.atlassian.net/browse/{{requestMetadata.task.holderTask}}" target="_blank">
                {{requestMetadata.task.holderTask}}
            </a>
        </div>
        <div *ngIf="requestMetadata.task.holderTask && jiraTaskInfos[requestMetadata.task.holderTask]">
            <ul>
                <li>Summary: {{jiraTaskInfos[requestMetadata.task.holderTask].summary}}</li>
                <li>Status: {{jiraTaskInfos[requestMetadata.task.holderTask].status}}</li>
                <li>Estimation: {{jiraTaskInfos[requestMetadata.task.holderTask].estimationQualified}}</li>
            </ul>
            <div *ngIf="requestMetadata.task.subTasks && requestMetadata.task.subTasks.length > 0">
                <h4>JIRA Subtasks:</h4>
                <div>
                    <table class="jira-subtasks">
                        <tr>
                            <th>Subtask</th>
                            <th>Summary</th>
                            <th>Status</th>
                            <th>Estimation</th>
                        </tr>
                        <tr *ngFor="let subTask of requestMetadata.task.subTasks">
                            <td>
                                <a href="https://alpiqdigital.atlassian.net/browse/{{subTask}}" target="_blank">{{subTask}}</a>
                            </td>
                            <td *ngIf="jiraTaskInfos[subTask]; else emptyCell">{{jiraTaskInfos[subTask].summary}}</td>
                            <td *ngIf="jiraTaskInfos[subTask]; else emptyCell">{{jiraTaskInfos[subTask].status}}</td>
                            <td *ngIf="jiraTaskInfos[subTask]; else emptyCell">{{jiraTaskInfos[subTask].estimationQualified}}</td>
                            <ng-template #emptyCell>
                                <td></td>
                            </ng-template>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div>
            <a [routerLink]="['/update-implementation']" [queryParams]="{npcId, productNumber: requestMetadata.productNumber,  productName: requestMetadata.productName}">
                Manage JIRA implementation Subtasks
            </a>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="!isNew && requestMetadata && (requestMetadata.sharepoint || requestMetadata.sharepointHistorical)">
    <mat-expansion-panel (opened)="sharepointPanelOpenState = true" (closed)="sharepointPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title><h3>Sharepoint Documents</h3></mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="requestMetadata && requestMetadata.sharepointHistorical">
            <a [href]="requestMetadata.sharepointHistorical" target="_blank">Historical request</a>
        </div>
        <div *ngIf="requestMetadata && requestMetadata.sharepoint && requestMetadata.sharepoint.npcUrl">
            <a [href]="requestMetadata.sharepoint.npcUrl" target="_blank">{{requestMetadata.productName}}.pdf</a>
        </div>
        <div *ngIf="requestMetadata && requestMetadata.sharepoint 
        && requestMetadata.sharepoint.attachments && requestMetadata.sharepoint.attachments.length > 0">
            <ul>
                <li *ngFor="let attachment of requestMetadata.sharepoint.attachments">
                    <a [href]="attachment.url" target="_blank">{{attachment.filename}}</a>
                </li>
            </ul>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="!isNew && requestMetadata && requestMetadata.products && requestMetadata.products.length > 0">
    <mat-expansion-panel (opened)="productsPanelOpenState = true" (closed)="productsPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title><h3>Products</h3></mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
            <li *ngFor="let product of requestMetadata.products">
                <a href="#" (click)="expandProductView($event, product)">{{product}}</a>
            </li>
        </ul>
    </mat-expansion-panel>
</mat-accordion>

<mat-stepper orientation="vertical" [linear]="isNew" #stepper *ngIf="npcRequest">
    <mat-step>
        <ng-template matStepLabel>General information</ng-template>
        <app-general-info [npcId]="npcId" [model]="npcRequest.genericInfo" sectionId="genericInfo" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'genericInfo')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('genericInfo')"> 
        </app-general-info>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Concise of Request</ng-template>
        <app-concise-request [npcId]="npcId" [model]="npcRequest.conciseOfRequest" sectionId="conciseOfRequest" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'conciseOfRequest')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('conciseOfRequest')">
        </app-concise-request>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Product description</ng-template>
        <app-product-description [npcId]="npcId" [model]="npcRequest.productDescription" sectionId="productDescription"  
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'productDescription')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('productDescription') && !isTechnicalChange()">
        </app-product-description>
        <app-product-description-technical-change [npcId]="npcId" [model]="npcRequest.productDescription" sectionId="productDescription"  
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'productDescription')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('productDescription') && isTechnicalChange()">
        </app-product-description-technical-change>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step *ngIf="!isTechnicalChange()">
        <ng-template matStepLabel>Risks</ng-template>
        <app-risk [npcId]="npcId" [model]="getNpcRequestNew()?.risks" sectionId="risks" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode"  [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'risks')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('risks')">
        </app-risk>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step *ngIf="!isTechnicalChange()">
        <ng-template matStepLabel>Classification</ng-template>
        <app-classifications [npcId]="npcId" [model]="getNpcRequestNew()?.classifications" sectionId="classifications" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode"  [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'classifications')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('classifications')">
        </app-classifications>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Operational Responsibilities</ng-template>
        <app-operational-responsibilities [npcId]="npcId" [model]="npcRequest.operationalResponsibilities" sectionId="operationalResponsibilities" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'operationalResponsibilities')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('operationalResponsibilities')">
        </app-operational-responsibilities>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>P&L and Exposure Calculation</ng-template>
        <app-valuation [npcId]="npcId" [model]="npcRequest.valuation" sectionId="valuation" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew" 
            (modelUpdates)="updateSection($event, 'valuation')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('valuation')">
        </app-valuation>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Deal Entry Instructions</ng-template>
        <app-deal-entry-instructions [npcId]="npcId" [model]="npcRequest.dealEntryInstructions" sectionId="dealEntryInstructions" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew" 
            (modelUpdates)="updateSection($event, 'dealEntryInstructions')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('dealEntryInstructions')">
        </app-deal-entry-instructions>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Specific remarks</ng-template>
        <app-specific-remarks [npcId]="npcId" [model]="npcRequest.specificRemarks" sectionId="specificRemarks" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'specificRemarks')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('specificRemarks')">
        </app-specific-remarks>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Attachments</ng-template>
        <app-npc-attachments [npcId]="npcId" [model]="npcRequest.attachments" sectionId="attachments" 
            [requestType]="requestType" [readOnlyMode]="readOnlyMode" [isNew]="isNew"
            (modelUpdates)="updateSection($event, 'attachments')" (richTextGetStatus)="updateRichTextContentGetStatus($event)"
            [editPermissions]="npcEditPermissions"
            *ngIf="isSectionEnabled('attachments')">
        </app-npc-attachments>
    </mat-step>
</mat-stepper>
<div class="npc-request-buttons" *ngIf="!readOnlyMode">
    <button mat-raised-button color="primary" (click)="save(false)" *ngIf="!isSaving && !isNew && requestMetadata?.status !== 'draft'">
        Save
    </button>
    <button mat-raised-button color="primary" (click)="save(false)" *ngIf="!isSaving && (isNew || requestMetadata?.status === 'draft')">
        Save as draft
    </button>
    <span>&nbsp;&nbsp;</span>
    <button mat-raised-button color="primary" (click)="save(true)" *ngIf="!isSaving && (isNew || requestMetadata?.status === 'draft')">
        Save and Submit
    </button>
    <span>&nbsp;&nbsp;</span>
    <button mat-raised-button color="primary" (click)="cancel()" *ngIf="!isSaving">Cancel</button>
    <div *ngIf="isSaving" class="npc-request-spinner">
        <mat-spinner [diameter]="24"></mat-spinner>
    </div>
</div>
