import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NpcRequestService } from "../../npc-request.service";
import { Router } from "@angular/router";

import { getApiErrorMessage } from '../../../utils/api-error';
import { Status, StatusTransition, getTransitions, getStatusLabel } from '../../workflow/actions';
import { Subscription } from "rxjs";

export interface ChangeStatusDialogData {
  fromStatus: string;
  toStatus: string | undefined;
  npcId: string;
  productNumber: string;
  isAdmin: boolean;
}

@Component({
  selector: 'status-change-dialog',
  templateUrl: 'status-change-dialog.component.html',
  styleUrls: ['./status-change-dialog.component.css']
})
export class StatusChangeDialogComponent implements OnDestroy {
  public fromStatus!: string;
  public toStatus: string | undefined;
  public npcId!: string;
  public productNumber!: string;
  public isAdmin: boolean = false;
  public error?: string;
  public nonStandardTargetStatuses: string[] = [];
  public isSaving: boolean = false;
  private subscription: Subscription | undefined = undefined;
 
  constructor(@Inject(MAT_DIALOG_DATA) public data: ChangeStatusDialogData, public dialogRef: MatDialogRef<ChangeStatusDialogData>, 
    private npcRequestService: NpcRequestService, private router: Router) { 
    this.fromStatus = data.fromStatus;
    this.toStatus = data.toStatus;
    this.npcId = data.npcId;
    this.productNumber = data.productNumber;
    this.isAdmin = data.isAdmin;
    if (this.isAdmin) {
      this.nonStandardTargetStatuses = this.getNonStandardTargetStatuses();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      try {
        this.subscription.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    }
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public confirm() {
    if (!this.toStatus) {
      return;
    }
    this.isSaving = true;
    this.subscription = this.npcRequestService.changeStatus(this.npcId, this.toStatus, this.isAdmin).subscribe({
      next: () => {
        console.log("Status changed successfully");
        this.isSaving = false;
        this.dialogRef.close(true);
      },
      error: (error: any) => {
        console.error("Status change failed", error);
        this.error = getApiErrorMessage(error);
        this.isSaving = false;
      }
    });
  }

  public onStatusSelected(status: string) {
    this.toStatus = status;
  }

  private getNonStandardTargetStatuses(): string[] {
    const allStatuses = Object.values(Status).filter((status: string) => status !== this.fromStatus);
    const standardTransitions: StatusTransition[] = getTransitions(this.fromStatus);
    return allStatuses.filter((status: string) => 
      !standardTransitions.some((transition: StatusTransition) => transition.to === status)
    );
  }

  public getStatusLabel(status: Status | string): string {
    return getStatusLabel(status);
  }

}