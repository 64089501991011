import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from "../product";
import { ProductListService } from "../product-list.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-product-viewer',
  templateUrl: './product-viewer.component.html',
  styleUrls: ['./product-viewer.component.css']
})
export class ProductViewerComponent implements OnInit, OnDestroy {
  @Input() inputProduct?: Product;
  @Input() inputId?: string;
  product?: Product;
  id?: string;
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private productsListService: ProductListService) { 
  }

  ngOnInit(): void {
    if (this.inputProduct) {
      this.product = this.inputProduct;
      return;
    } else if (this.inputId) {
      this.id = this.inputId;
      this.getProduct();
    } else {
      this.subscriptions.push(
        this.route.queryParams.subscribe(params => {
            if (params && params['id'] !== undefined) {
              this.id = params['id'];
              this.getProduct();
            }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private getProduct(): void {
    if (this.id === undefined) {
      return;
    }
    this.subscriptions.push(
      this.productsListService.getProduct(this.id!).subscribe(product => {
        this.product = product as Product;
      })
    );
  }

  public getMandates(): string {
    return this.product?.mandates.join(', ') || '';
  }

}