import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, SimpleChange, ViewChildren } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from "rxjs";
import { CdkDragDrop, CdkDragEnd, CdkDragMove, CdkDragStart, moveItemInArray } from "@angular/cdk/drag-drop";
import { ProductUi } from "../product";

export interface TreeFilterItemNode {
  children: TreeFilterItemNode[];
  item: string;
}

export interface TreeFilterItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-product-catalogue-tree-filter',
  templateUrl: './product-tree-filter.component.html',
  styleUrls: ['./product-tree-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductTreeFilterComponent implements OnDestroy, OnInit {
  treeControl: FlatTreeControl<TreeFilterItemFlatNode>;
  treeFlattener: MatTreeFlattener<TreeFilterItemNode, TreeFilterItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeFilterItemNode, TreeFilterItemFlatNode>;
  flatNodeMap = new Map<TreeFilterItemFlatNode, TreeFilterItemNode>();
  nestedNodeMap = new Map<TreeFilterItemNode, TreeFilterItemFlatNode>();
  checklistSelection = new SelectionModel<TreeFilterItemFlatNode>(true /* multiple */);
  isShaking = false;
  currentDragLevel: number | null = null;
  levels: number[] = [];
  filteringGroups: string[] = ['productGroup', 'product',  'market', 'commodity',]
  previousLevel: number | null = null;
  visible: boolean = false;
  subscription?: Subscription;
  isDataReady: boolean = false;

  isDragging: boolean = false;
  currentTargetPosition: number = -1;

  @ViewChildren('levelContainer') levelContainer!: QueryList<ElementRef<HTMLElement>>;
  @Output() resultChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() products!: ProductUi[];
  @Input() clearFilters?: Boolean;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeFilterItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      try {
        this.subscription.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    }
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if (changes['clearFilters']) {
      this.checklistSelection.clear();
    }
  }

  private init(): void {
    const nodes: TreeFilterItemNode[] = this.getFilterTree(this.filteringGroups)
    this.dataSource!.data = [...nodes];
    this.isDataReady = true;
    this.visible = true;
    this.changeDetector.markForCheck();
  }

  getLevel = (node: TreeFilterItemFlatNode) => node.level;

  isExpandable = (node: TreeFilterItemFlatNode) => node.expandable;

  getChildren = (node: TreeFilterItemNode): TreeFilterItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TreeFilterItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TreeFilterItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeFilterItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : { item: node.item, level: level, expandable: false };
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeFilterItemFlatNode): boolean {
    const descendants = this.treeControl!.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeFilterItemFlatNode): boolean {
    const descendants = this.treeControl!.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeFilterItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl!.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.determineFilters()
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeFilterItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.determineFilters()
  }

  determineFilters() {
    let filters: Map<string, Set<string>> = new Map<string, Set<string>>();
  
    // Initialize the filters map with empty sets for each filtering group
    this.filteringGroups.forEach(filterName => filters.set(filterName, new Set<string>()));
  
    let currentLevel = this.filteringGroups.length - 1;
    let nodes = this.getSelectedNodesByLevel(currentLevel);
  
    while (currentLevel >= 0) {
      // Update the filter sets directly
      const currentFilterSet = filters.get(this.filteringGroups[currentLevel]);
      if (currentFilterSet) {
        nodes.forEach(node => currentFilterSet.add(node.item));
      }
  
      // Prepare for the next iteration by fetching parent nodes
      nodes = nodes.map(child => this.getParentNode(child)!).filter(parent => parent !== null);
      currentLevel--;
    }
    this.resultChanged.emit(filters);
  }
  
  getSelectedNodesByLevel(level: number): TreeFilterItemFlatNode[] {
    return this.checklistSelection.selected.filter(node => node.level === level);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeFilterItemFlatNode): void {
    let parent: TreeFilterItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeFilterItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl!.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeFilterItemFlatNode): TreeFilterItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl!.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl!.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onDropTree(event: any): void {
    this.isShaking = false
//    const dropPositionY = event.pointerPosition.y;
    const dropPositionY = event.dropPoint.y;
    const targetLevel = this.determineLevelFromPosition(dropPositionY);

    if (targetLevel !== null) {
      //console.log(targetLevel)
      //reschuffle array
      if (targetLevel != this.previousLevel && this.previousLevel != null){
        let categoryToBeMoved = this.filteringGroups[targetLevel]
        this.filteringGroups[targetLevel] = this.filteringGroups[this.previousLevel]
        this.filteringGroups[this.previousLevel] = categoryToBeMoved
        this.previousLevel = null
        this.init()
      }
    }
  }
  
  getLevels(dataNodes: TreeFilterItemFlatNode[]): number[] {
    let levels: number[] = [];
    for (let node of dataNodes) {
      if (!levels.includes(node.level)) {
        levels.push(node.level);
      }
    }
    return levels.sort();
  }

  dragStarted(event: any, node: any): void {
    this.currentDragLevel = node.level;
    this.levels = this.getLevels(this.treeControl.dataNodes);
    this.isShaking = true
  }

  dragEnded(event: any): void {
    this.isShaking = false
    this.previousLevel = this.currentDragLevel
    this.currentDragLevel = null;
  }

  determineLevelFromPosition(yPosition: number): number {
    let targetLevel: number = -1;

    this.levelContainer.first.nativeElement.childNodes.forEach((element, index) => {
      if(element instanceof Element){
        //console.log((element as Element).getBoundingClientRect())
        const rect = element.getBoundingClientRect();
        if(yPosition >= rect.top && yPosition <= rect.bottom) {
          targetLevel = index
        }
      }
    });
  
    return targetLevel;
  }

  capitalizeText(input: string): string {
    const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  drop(event: CdkDragDrop<string[]>) {
    //console.log('Current Index: ', event.container.data.indexOf());
    moveItemInArray(this.filteringGroups, event.previousIndex, event.currentIndex);
    this.init();
  }

  drag(event: CdkDragStart<string[]>){
    this.isDragging = true;
  }

  dragMoved(event: CdkDragMove<string[]>){
    
  }

  dragEnd(event: CdkDragEnd<string[]>){
    this.isDragging = false;
    this.currentTargetPosition = -1;
  }

  private getFilterTree(filterNames: string[]): TreeFilterItemNode[] {
    let tree: TreeFilterItemNode[] = [];
    this.products.map((product: ProductUi) => filterNames.map(filterName => product[filterName] as string))
    .forEach((filterValues: string[]) => {
      this.insertTreeNode(tree, filterValues);
    });
    return tree;
  }

  private insertTreeNode(tree: TreeFilterItemNode[], filterValues: string[]): void {
    if (filterValues.length === 0) {
      return;
    }
    const rootValue: string = filterValues[0];
    let rootFilterItemNode: TreeFilterItemNode | undefined = tree.find(item => item.item === rootValue);
    if (rootFilterItemNode === undefined) {
      rootFilterItemNode = {
        item: rootValue,
        children: []
      };
      tree.push(rootFilterItemNode);
    }
    this.insertTreeNode(rootFilterItemNode.children, filterValues.slice(1));
  }

}