import { Component, ViewChild } from '@angular/core';
import { RichTextContentStatus, RichTextContentMetadata } from './rich-text-editor.types';
import { RichTextEditorComponent } from './rich-text-editor.component';

@Component({
  selector: 'rich-text-page-demo',
  templateUrl: './rich-text-page-demo.component.html',
  styleUrls: []
})
export class RichTextPageDemoComponent {
  isDisplayed = true; // defines whether the rich text editor is collapsed or expanded by default
  npcId = '17c02659-0367-4711-908c-351960b51c3b'; // ID of a valid test NPC
  sectionId = 'concise'; 
  fieldId = 'situation';
  // if version id is undefined, the rich text editor will assume that the section is not yet initialized
  // if version id is defined, it will retrieve the related HTML content from the browser cache (if it exists and is not yet expired), 
  // otherwise it will retrieve the content from the API (get presigned S3 download URL)+ S3
  versionId?: string = undefined;
  title = 'Specific Remarks';
  saveMessage?: string;
  // this metadata for the given rich text section must derive from the NPC object
  metadata: RichTextContentMetadata = {
    versionId: this.versionId
  };

  @ViewChild(RichTextEditorComponent) editorComponent!: RichTextEditorComponent;

  onSave(): void {
    this.editorComponent.saveContent();
  }

  onSaveStatus(status: RichTextContentStatus): void {
    if (status.status) {
        this.saveMessage = 'Save successful. New section metadata: ' + JSON.stringify(status.metadata);
        this.metadata = status.metadata;
    } else {
        this.saveMessage = 'Save failed';
    }
  }

  onGetStatus(status: RichTextContentStatus): void {
    if (status.status) {
        this.saveMessage = 'Get successful. New section metadata: ' + JSON.stringify(status.metadata);
        this.metadata = status.metadata;
    } else {
        this.saveMessage = 'Get failed';
    }
  }
}