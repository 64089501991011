<div
  class="app-splash-screen"
  [ngStyle]="{'display': this.showSplash ? 'flex' : 'none' }"
  *ngIf="this.showSplash"
  [class.fade-out]="startFadeOut"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-label">Product Management Tool</div>
    <div class="app-loader"></div>
  </div>
</div>