import { Component, OnInit } from '@angular/core';
import { AuthService } from './authentication/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'npc-ui';
  productsLink: boolean = false;
  usersLink: boolean = false;
  npcLink: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService) { }
  
  public ngOnInit(): void {
    this.subscriptions.push(
      this.authService.isActualAdmin().subscribe(isAdmin => {
        this.usersLink = isAdmin;
      })
    );
    this.subscriptions.push(
      this.authService.canViewProducts().subscribe(canViewProducts => {
        this.productsLink = canViewProducts;
      })
    );
    this.subscriptions.push(
      this.authService.canViewNpc().subscribe(canViewNpc => {
        this.npcLink = canViewNpc;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

}
