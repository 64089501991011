import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../users-types';
import { UsersService } from '../users.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit, OnDestroy {
  public tableDataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  private subscriptions: Subscription[] = [];
  public assumeRole: boolean = false;
  public selectedRole?: string = undefined;

  constructor(private usersService: UsersService, private router: Router) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.usersService.listUsers().subscribe(users => {
        this.tableDataSource.data = users;
      })
    );

    const storedRole = localStorage.getItem('assume_role');
    if (storedRole) {
      this.assumeRole = true;
      this.selectedRole = storedRole;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public saveAssumeRole(): void {
    if (this.assumeRole && this.selectedRole) {
      localStorage.setItem('assume_role', this.selectedRole);
    } else {
      localStorage.removeItem('assume_role');
    }
    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
  }
}