<form [formGroup]="form">
    <div fxLayout="column">
        <mat-form-field *ngIf="!isDisabled('usedPortfolios')">
            <mat-label>Used Portfolios</mat-label>
            <input matInput formControlName="usedPortfolios">
        </mat-form-field>
    </div>
    <rich-text-editor #technicalChangeDefinition *ngIf="model && !isDisabled('technicalChangeDefinition')" [metadata]="getRichTextMetadata('technicalChangeDefinition')" 
        title="Technical Change Definition *" 
        [help]="technicalChangeDefinitionHelp" [npcId]="npcId" [sectionId]="sectionId" fieldId="technicalChangeDefinition" [isReadOnly]="isReadOnly('technicalChangeDefinition')"
        (saveStatus)="onRichTextFieldSaveStatus($event, 'technicalChangeDefinition')" (getStatus)="onRichTextFieldGetStatus($event, 'technicalChangeDefinition')"
        [isDisplayed]="true" [isRequired]="true">
    </rich-text-editor>
    <rich-text-editor #improvementPotential *ngIf="model && !isDisabled('improvementPotential')" [metadata]="getRichTextMetadata('improvementPotential')"
        title="Improvement Potential *" 
        [npcId]="npcId" [sectionId]="sectionId" fieldId="improvementPotential" [isReadOnly]="isReadOnly('improvementPotential')"
        (saveStatus)="onRichTextFieldSaveStatus($event, 'improvementPotential')" (getStatus)="onRichTextFieldGetStatus($event, 'improvementPotential')"
        [isDisplayed]="true" [isRequired]="true">
    </rich-text-editor>
    <npc-attachments #attachments [metadata]="getAttachmentMetadata()" [npcId]="npcId" [sectionId]="sectionId" [isReadOnly]="isReadOnly('attachments')"
        (saveAttachmentsResponse)="handleSaveAttachmentsResponse($event)"></npc-attachments>
</form>