export enum RequestType {
    NEW_REQUEST = 'new request',
    EXTENSION = 'product extension',
    TECH_CHANGE = 'technical change'
};

export type ListsPerRequestType = { 
	[key in RequestType]: string[]
};

export interface NpcSectionError {
    sectionId: string;
    fieldId?: string;
    error: string;
};

export interface NpcSectionResponse {
    sectionId: string;
    model?: any;
    errors: NpcSectionError[];
};

export interface Help {
    field: string;
    title: string;
    help: string;
};

export interface HelpPerRequestType {
    field: string;
    title: string;
    help: {
        [key in RequestType]: string;
    }
};

export type NpcPermissions = {
    [key in string]: string[];
};

export const NpcSectionNames = ['conciseOfRequest', 'risks', 'classifications', 'specificRemarks', 'productDescription', 'genericInfo', 
    'valuation', 'operationalResponsibilities', 'dealEntryInstructions', 'attachments'];

