import { Injectable } from '@angular/core';
import { ExcelExportResponse, Product, ProductCreate, ProductUi, ProductsDownload } from './product';
import { Observable, of, tap, map, ReplaySubject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  private apiUrl = `${environment.apiBaseUrl}/product`;
  private refreshListNeeded: boolean = false;
  private productsSubject = new ReplaySubject<ProductsDownload>();

  CACHE_KEY: string = 'productList';
  CACHE_EXPIRATION_HOURS = 24;
  
  constructor(private http: HttpClient) {
  }

  private enrichProductsWithNPCUrl(products: Product[]): ProductUi[] {
    return products.map((item: Product) => {
      const productUi = {
        ...item,
        npcUrl: `${environment.uiUrl}/product-viewer?id=${item.id}`
      };
      return productUi;
    });
  }

  private updateLocalStorage(products: Product[]): void {
    const currentTime = new Date().getTime();
    sessionStorage.setItem(this.CACHE_KEY, JSON.stringify(products));
    sessionStorage.setItem('cachedTime', currentTime.toString());
    this.refreshListNeeded = false;
  }

  private isCacheExpired(): boolean {
    const cachedTime: string | null = sessionStorage.getItem('cachedTime');
    if (!cachedTime) {
      return true;
    }
    const currentTime = new Date().getTime();
    const expirationDelayInMs = this.CACHE_EXPIRATION_HOURS * 60 * 60 * 1000;
    return currentTime - parseInt(cachedTime) > expirationDelayInMs;
  }

  public fetchProducts(): void {
    this.http.get<Product[]>(this.apiUrl)
      .pipe(
        tap((products: Product[]) => this.updateLocalStorage(products)),
        map((products: Product[]) => this.enrichProductsWithNPCUrl(products))
      )
      .subscribe((products: ProductUi[]) => {
        this.productsSubject.next({
          isOutdated: false,
          products: products
        });
      });
  }

  getProducts(onlyUpToDate: boolean = false): Observable<ProductsDownload> {
    const cachedProductsStr = sessionStorage.getItem(this.CACHE_KEY);
    if (cachedProductsStr && !onlyUpToDate) {
      let isOutdated: boolean = false;
      if (this.refreshListNeeded || this.isCacheExpired()) {
        isOutdated = true; // mark returned products as outdated
      }
      const cachedProducts: Product[] = JSON.parse(cachedProductsStr);
      // serve cached products
      this.productsSubject.next({
        isOutdated,
        products: this.enrichProductsWithNPCUrl(cachedProducts)
      });
      if (isOutdated) {
        // in a second time, fetch new products list from API
        this.fetchProducts();
      }
    } else {
      // No cache returned, fetch new products list from API
      this.fetchProducts();
    }
    return this.productsSubject.asObservable();
  }

  getProduct(id: string): Observable<ProductUi> {
    return this.getProducts().pipe(
      map((download: ProductsDownload) => download.products.find(product => product.id === id) as ProductUi)
    );
  }

  createProduct(product: ProductCreate): Observable<Product> {
    return this.http.post<Product>(this.apiUrl, product)
      .pipe(
        tap(() => this.refreshListNeeded = true)
      );
  }

  excelExport(): Observable<string> {
    return this.http.get<ExcelExportResponse>(`${this.apiUrl}/export`)
      .pipe(
        map((resp: ExcelExportResponse) => resp.url)
      )
  }

}
