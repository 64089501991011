import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from './../users-types';
import { UsersService } from './../users.service';
import { Subscription } from 'rxjs';

const ADMIN_ROLE = 'npc_admin';

function isUserEqual(user1: User, user2: User): boolean {
  return user1.email === user2.email;
}

@Component({
  selector: 'users-selector',
  templateUrl: './users-selector.component.html',
  styleUrls: ['./users-selector.component.css']
})
export class UserSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Output() selectedUsersChange = new EventEmitter<User[]>();
  @Input() title!: string;
  @Input() singleSelection = false;
  @Input() basicStyling = false;
  @Input() initUsers: string[] = [];
  @Input() displayTitle = true;
  @Input() centeredDisplay = true;
  @Input() isMandatory = false;
  @Input() isReadOnly = false;

  users: User[] = [];
  usersOptions: User[] = [];
  selectedUsers: User[] = [];
  selectedUsersControl = new FormControl();
  isValid: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(private usersService: UsersService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.usersService.listUsers().subscribe(users => {
        this.users = users;
        this.computeUsersOptions();

        if (Array.isArray(this.initUsers) && this.initUsers.length > 0) {
          this.subscriptions.push(
            this.usersService.getUsersByEmail(this.initUsers).subscribe(users => {
              this.selectedUsers = users;
              if (this.singleSelection && users.length === 1) {
                this.selectedUsersControl.setValue(users[0]);
              }
              this.selectedUsersChange.emit(this.selectedUsers);
              this.setIsValid();
            })
          );
        }
      })
    );
    if (this.isReadOnly) {
      this.selectedUsersControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isReadOnly']) {
      if (this.isReadOnly) {
        this.selectedUsersControl.disable();
      } else {
        this.selectedUsersControl.enable();
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private isSelectedUser(user: User): boolean {
    return this.selectedUsers.some((u: User) => isUserEqual(u, user));
  }

  private removeUserFormArray(user: User, users: User[]): void {
    const index = users.findIndex(u => isUserEqual(u, user));
    if (index >= 0) {
      users.splice(index, 1);
    }
  }

  private computeUsersOptions(): void {
    this.usersOptions = this.users.filter((user: User) => !this.isSelectedUser(user));
  }

  public removeUser(user: User): void {
    if (this.isReadOnly) {
      return;
    }
    this.removeUserFormArray(user, this.selectedUsers);
    this.computeUsersOptions();
    this.selectedUsersChange.emit(this.selectedUsers);
    this.setIsValid();
  }

  public addUser(user?: User): void {
    if (!user || this.isReadOnly) {
      return;
    }
    if (this.singleSelection) {
      this.selectedUsers = [user];
      this.selectedUsersChange.emit(this.selectedUsers);
    } else {
      if (!this.isSelectedUser(user)) {
        this.selectedUsers.push(user);
        this.selectedUsersControl.setValue(null);
        this.selectedUsersChange.emit(this.selectedUsers);
      }
      this.computeUsersOptions();
    }
    this.setIsValid();
  }

  private setIsValid(): void {
    this.isValid = this.isMandatory ? (Array.isArray(this.selectedUsers) ? this.selectedUsers.length > 0 : false) : true;
  }

  public compareUsers(user1: User, user2: User): boolean {
    return user1 && user2 && user1.email === user2.email;
  }
  
}