export const PlatformExchange: string[] = [
    "EEX",
    "HUPX",
    "POLPX",
    "EPEX",
    "EXAA",
    "OTE",
    "Nordpool",
    "OKTE",
    "Southpool",
    "Seepex",
    "IBEX",
    "Cropex",
    "Enex",
    "MAVIR",
    "EMS",
    "NOSBIH",
    "CGES",
    "ESO",
    "MEPSO",
    "SEE CAO",
    "Transeletrica",
    "Mepx",
    "Alpiq AG",
    "CEEGEX",
    "HUDEX",
    "SEPS",
    "IPEX",
    "OMIE",
    "ICS",
    "ICE Endex",
    "ICE Futures U.S.",
    "ICE Futures Europe",
    "MEFF",
    "OMIP",
    "Mibgas",
    "NASDAQ",
    "GME"
];