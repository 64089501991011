import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Attachment, AttachmentMetadataExtended, AttachmentGetPresignedUrlResponse, AttachmentGetLatestVersionResponse } from './attachment.types';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  private apiUrl = `${environment.apiBaseUrl}/npc-attachment`;
  private httpBypassProxy: HttpClient;

  constructor(private handler: HttpBackend, private http: HttpClient) {
    this.httpBypassProxy = new HttpClient(handler);
   }

  public downloadAttachment(metadata: AttachmentMetadataExtended): Observable<string | undefined> {
    const filename: string = metadata.fileName;
    const sectionId: string = metadata.sectionId;
    if (metadata.versionId === undefined) {
      // section content is not yet initialized, returning empty content
      return of(undefined);
    } else {
      const url = `${this.apiUrl}?npc_id=${metadata.npcId}&section_id=${sectionId}&filename=${filename}&version_id=${metadata.versionId}`;
      return this.http.get<{ presigned_url: string }>(url).pipe(
        map(response => response.presigned_url),
        catchError(error => {
          console.error(`Failed to get URL for downloading attachment for file '${filename}':`, error);
          return of(undefined);
        })
      );
    }
  }

  public uploadAttachment(attachment: Attachment): Observable<AttachmentMetadataExtended> {
    const metadata: AttachmentMetadataExtended = attachment.metadata;
    const sectionId: string = metadata.sectionId;
    const filename: string = metadata.fileName;
    const npcId: string = metadata.npcId;
    const url = `${this.apiUrl}?npc_id=${npcId}&filename=${filename}&section_id=${sectionId}`;
    console.log(`Uploading attachment for file '${filename}' to URL '${url}'`);
    return this.http.put<AttachmentGetPresignedUrlResponse>(url, {}).pipe(
      switchMap((urlResp: AttachmentGetPresignedUrlResponse) => {
        const uploadUrl: string = urlResp.presigned_url;
        return this.httpBypassProxy.put(uploadUrl, attachment.file, 
          {}).pipe(
          switchMap(s3UploadResp => {
            return this.http.get<AttachmentGetLatestVersionResponse>(`${this.apiUrl}/latest-version?npc_id=${npcId}&section_id=${sectionId}&filename=${filename}`)
            .pipe(
              map(latestVersionResponse => {
                const putResponseMetadata: AttachmentMetadataExtended = {
                  npcId, 
                  sectionId,
                  fileName: filename, 
                  versionId: latestVersionResponse.version_id,
                  description: metadata.description,
                  isUploaded: true
                };
                return putResponseMetadata;
              }),
              catchError(error => {
                console.error(`Failed to get latest version of the uploaded attachment for file '${filename}':`, error);
                const putResponseMetadata: AttachmentMetadataExtended = {...attachment.metadata, isUploaded: false};
                return of(putResponseMetadata);
              })
            )
          }),
          catchError(error => {
            console.error(`Failed to upload attachment for file '${filename}':`, error);
            const putResponseMetadata: AttachmentMetadataExtended = {...attachment.metadata, isUploaded: false};
            return of(putResponseMetadata);
          })        
        );
      }),
      catchError(error => {
        console.error(`Failed to get URL for uploading attachment for file '${filename}':`, error);
        const putResponseMetadata: AttachmentMetadataExtended = {...attachment.metadata, isUploaded: false};
        return of(putResponseMetadata);
      }) 
    );
  }

}
