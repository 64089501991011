<div class="container">
    <div class="row">
      <div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Select Fields used for Product unicity check</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>Add a field</mat-label>
            <mat-select (selectionChange)="addField($event.value)" class="fields-selector">
                <mat-option *ngFor="let field of fields" [value]="field">{{field}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div>Selected Fields:</div>
          <div class="selected-fields">
            <mat-card *ngFor="let field of selectedFields">
              <span class="card-text">{{ field }}</span>
              <span>
                <button mat-icon-button (click)="removeField(field)" class="card-remove">
                  <mat-icon>close</mat-icon>
                </button>
              </span>
            </mat-card>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <div class="row" *ngIf="requestProducts.length > 0">
      <h2>Products Candidates:</h2>
    </div>
    <div class="row" *ngIf="singleValueFields.length > 0">
      <div><B>Common values:</B></div>
      <div class="grid-container">
        <mat-form-field *ngFor="let field of singleValueFields">
          <mat-label>{{field}}</mat-label>
          <input matInput [value]="getCommonFieldValue(field)" readonly="true">
        </mat-form-field>
        <mat-form-field>
          <mat-label>comment</mat-label>
          <input matInput [(ngModel)]="requestProducts[0]['comment']">
        </mat-form-field>
        <mat-form-field>
          <mat-label>validity start date</mat-label>
          <input matInput [(ngModel)]="requestProducts[0]['startValidityDate']" placeholder="YYYY-MM-DD">
        </mat-form-field>
        <mat-form-field>
          <mat-label>validity end date</mat-label>
          <input matInput [(ngModel)]="requestProducts[0]['endValidityDate']" placeholder="YYYY-MM-DD">
        </mat-form-field>
        <mat-form-field>
          <mat-label>status</mat-label>
          <mat-select [(ngModel)]="requestProducts[0]['status']">
            <mat-option *ngFor="let status of productStatuses"  [value]="status">
              {{status}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="requestProducts.length == 1" class="single-product-info">
      <div *ngIf="existingProducts[0].length > 0 && isSuccess === undefined">
        <span>This product is already existing in the Products Catalogue: </span>
        <span><a href="#" (click)="viewProduct($event, existingProducts[0])">View Product</a></span>
      </div>
      <div *ngIf="existingProducts[0].length === 0 && isSuccess === undefined">This product is not yet existing in the Products Catalogue</div>
      <div *ngIf="errors.length > 0 && errors[0]" style="color: red;">An error occurred when trying to create the Product: {{errors[0]}}</div>
      <div *ngIf="isSuccess === true">
        <span style="color: red">The Product has been successfully created: </span>
        <span><a href="#" (click)="viewProduct($event, existingProducts[0])" target="_blank">View Product</a></span>
      </div>
    </div>
    <div class="row" *ngIf="multiValuesFields.length > 0">
      <div><B>Values combinations:</B></div>
      <table class="products-combinations">
        <thead>
          <tr>
            <th *ngIf="!processing && isCreate" style="width: 20px" class="products-combinations-cell"></th>
            <th *ngFor="let field of multiValuesFields" class="products-combinations-cell">{{ field }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of requestProducts;  let i = index" (click)="viewProduct($event, existingProducts[i])" 
            [ngClass]="{'error': errors.length > i && errors[i], 'existing': existingProducts[i].length > 0, 'non-existing': existingProducts[i].length === 0}"
            (mouseover)="onRowMouseOver($event, i)" (mouseout)="onRowMouseOut($event)">
            <td *ngIf="!processing && isCreate" style="width: 20px" class="products-combinations-cell">
              <input type="checkbox" [disabled]="existingProducts[i].length > 0 || (errors.length > i && errors[i])"
                (change)="onProductSelect($event, i)" (click)="$event.stopPropagation()">
            </td>
            <td *ngFor="let field of multiValuesFields" class="products-combinations-cell"
              [matTooltip]="errors.length > i && errors[i] ? getErrorTooltip(product, field, i): null">
              {{ product[field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="actions-button-container">
        <button mat-raised-button color="primary" (click)="createProducts()" *ngIf="isCreate && requestProducts.length > 0"
          [disabled]="hasErrors() || requestProducts.length === 0 || (selectedProductsIndexes.length === 0 && requestProducts.length > 1) || (requestProducts.length === 1 && existingProducts[0].length > 0)" 
          class="actions-button">
          <mat-spinner *ngIf="processing" diameter="20" color="accent"></mat-spinner>
          <span *ngIf="!processing">Create</span>
        </button>
        <button mat-raised-button color="primary" mat-dialog-close (click)="cancel()" 
          [disabled]="processing" class="actions-button">
          Close
        </button>
      </div>
    </div>
  </div>
  <div class="legend-color-container" *ngIf="multiValuesFields.length > 0">
    <table class="legend-color-table">
      <colgroup>
        <col style="width: 15px">
        <col>
      </colgroup>
      <tr>
        <td><div class="non-existing legend-color"></div></td>
        <td>Non-existing Product, which can be created</td>
      </tr>
      <tr>
        <td><div class="existing legend-color"></div></td>
        <td>Similar Product already existing in the Products Catalogue</td>
      </tr>
      <tr>
        <td><div class="error legend-color"></div></td>
        <td>Error occurred when trying to create the Product</td>
      </tr>
    </table>
  </div>
