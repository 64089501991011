<div class="grid-container" *ngIf="product">
    <div class="grid-item">
      <div fxLayout="column">
          <mat-form-field>
            <mat-label>ID</mat-label>
            <input matInput [value]="product.id" readonly="true">
          </mat-form-field>
        </div>
      <div fxLayout="column" *ngIf="product.npcId">
        <mat-form-field>
          <mat-label>NPC Request</mat-label>
          <input matInput [readonly]="true" [value]="product.npcId" style="display: none;">
          <a *ngIf="product.npcId" matInput [routerLink]="['/npc-request']" [queryParams]="{action: 'view', npcId: product.npcId}">
            {{product.npcId}}
          </a>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>NPC Number</mat-label>
          <input matInput [value]="product.npc" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Product group</mat-label>
          <input matInput [value]="product.productGroup" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Product</mat-label>
          <input matInput [value]="product.product" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Product Id</mat-label>
          <input matInput [value]="product.productId" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Classification</mat-label>
          <input matInput [value]="product.classification" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Buy / Sell</mat-label>
          <input matInput [value]="product.buySell" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Instrument</mat-label>
          <input matInput [value]="product.instrument" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Legal Entities</mat-label>
          <input matInput [value]="product.legalEntity" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Market</mat-label>
          <input matInput [value]="product.market" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Market Access</mat-label>
          <input matInput [value]="product.marketAccess" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Market Detail</mat-label>
          <input matInput [value]="product.marketDetail" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Commodity</mat-label>
          <input matInput [value]="product.commodity" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Mandates</mat-label>
          <textarea matInput [value]="getMandates()" readonly="true"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="grid-item">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Traded via</mat-label>
          <input matInput [value]="product.tradedVia" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Platform</mat-label>
          <input matInput [value]="product.platform" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Settlement</mat-label>
          <input matInput [value]="product.settlement" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Tenor</mat-label>
          <input matInput [value]="product.tenor" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Profile</mat-label>
          <input matInput [value]="product.profile" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Currency</mat-label>
          <input matInput [value]="product.currency" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Unit</mat-label>
          <input matInput [value]="product.unit" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field *ngIf="product.tradedVia.includes('Exchange')">
          <mat-label>Exchange Product Code</mat-label>
          <input matInput [value]="product.exchangeProductCode" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field *ngIf="product.tradedVia.includes('Exchange')">
          <mat-label>Exchange Product Name</mat-label>
          <input matInput [value]="product.exchangeProductName" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Comment</mat-label>
          <input matInput [value]="product.comment" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field [ngClass]="{'deactivated': product.status === 'Deactivated'}">
          <mat-label>Status</mat-label>
          <input matInput [value]="product.status" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Start Validity Date</mat-label>
          <input matInput [value]="product.startValidityDate" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>End Validity Date</mat-label>
          <input matInput [value]="product.endValidityDate" readonly="true">
        </mat-form-field>
      </div>
    </div>
</div>