<form [formGroup]="form">
    <div class="field-hint-container">
        <mat-form-field class="form-field" *ngIf="!isDisabled('conciseOfRequest')">
            <mat-label>Concise of Request</mat-label>
            <textarea matInput formControlName="conciseOfRequest">
            </textarea>
        </mat-form-field>
        <div class="npc-help">
            <button mat-icon-button (click)="openHelp('conciseOfRequest')"><mat-icon>help_outline</mat-icon></button>
        </div>
    </div>
    <rich-text-editor #situation [metadata]="getRichTextMetadata('situation')" *ngIf="model && !isDisabled('situation')"  title="Situation *" 
        [help]="getSituationHelp()" [npcId]="npcId" [sectionId]="sectionId" fieldId="situation" [isReadOnly]="isReadOnly('situation')"
        (saveStatus)="onRichTextFieldSaveStatus($event, 'situation')" (getStatus)="onRichTextFieldGetStatus($event, 'situation')"
        [isDisplayed]="true" [isRequired]="true">
    </rich-text-editor>
</form>