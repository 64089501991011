<h2 mat-dialog-title class="title">Create JIRA implementation tasks for request '{{productName}}' ({{productNumber}})</h2>
<div class="info-container" *ngIf="!error && !jiraTasks">
  <div>On confirmation, a JIRA task will be created for the implementation of this request, into the default assigned JIRA Project.</div>
  <div>You can optionally specify a list of JIRA Projects (the JIRA Project keys must be  filled in below), in order to create some linked subtasks within the other JIRA Projects.</div>
</div>
<div mat-dialog-content>
  <div class="form-container" *ngIf="!error && !jiraTasks">
    <mat-form-field [formGroup]="form" class="project-field">
      <mat-label>JIRA Project key for subtask</mat-label>
      <input matInput formControlName="project">
      <mat-error *ngIf="form.get('project')?.invalid">{{ form.get('project')?.errors?.['message'] }}</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="form.invalid" 
      (click)="addProject(form.get('project')!.value)" class="actions-button">
      Add
    </button>
  </div>
  <div class="form-element-full-width card-container">
    <mat-card *ngFor="let project of projects" fxLayoutWrap>
      <mat-card-header>
        <mat-card-title>{{ project }}</mat-card-title>
        <button mat-icon-button (click)="removeProject(project)">
          <mat-icon>delete</mat-icon>
      </button>
    </mat-card-header>
    </mat-card>
  </div>
  <div *ngIf="error" class="action-error">
    {{error}}
  </div>
</div>
<div *ngIf="jiraTasks" class="info-container">
  <h3><B>
    <span *ngIf="jiraTasks.subTasks.length > 0">JIRA implementation tasks were successfully created:
    </span>
    <span *ngIf="jiraTasks.subTasks.length == 0">JIRA implementation task was successfully created:
    </span>
  </B></h3>
  <ul>
    <li>Main task:
      <a href="https://alpiqdigital.atlassian.net/browse/{{jiraTasks.holderTask}}" target="_blank">
        {{jiraTasks.holderTask}}
      </a>
    </li>
    <li *ngFor="let subTask of jiraTasks.subTasks">Subtask: 
      <a href="https://alpiqdigital.atlassian.net/browse/{{subTask}}" target="_blank">
        {{subTask}}
      </a>
  </ul>
</div>
<div *ngIf="statusMessage" class="info-container"><B>{{statusMessage}}</B></div>
<div mat-dialog-actions class="actions-button-container">
  <button mat-raised-button color="primary" (click)="confirm()" class="actions-button" *ngIf="!error && !jiraTasks && !isSaving">OK</button>
  <button mat-raised-button color="primary" mat-dialog-close (click)="cancel()" *ngIf="!error && !jiraTasks && !isSaving" class="actions-button">Cancel</button>
  <button mat-raised-button color="primary" (click)="close()" *ngIf="(error || jiraTasks) && !isSaving" class="actions-button">Close</button>
  <div *ngIf="isSaving" class="npc-request-spinner">
    <mat-spinner [diameter]="24"></mat-spinner>
  </div>
</div>