import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RichTextContent, RichTextContentMetadataExtended, RichTextContentStatus, 
  GetPresignedUrlResponse, GetLatestVersionResponse, RichTextContentDownload } from './rich-text-editor.types';

@Injectable({
  providedIn: 'root'
})
export class RichTextEditorService {
  private apiUrl = `${environment.apiBaseUrl}/npc-rich-text`;
  private httpBypassProxy: HttpClient;

  constructor(private handler: HttpBackend, private http: HttpClient) {
    this.httpBypassProxy = new HttpClient(handler);
   }

  private getCacheKey(npcId: string, sectionId: string, fieldId?: string) {
    if (fieldId === undefined) {
      return `rich_text_${npcId}_${sectionId}`;
    } else {
      return `rich_text_${npcId}_${sectionId}_${fieldId}`;
    }
  }

  public downloadRichText(metadata: RichTextContentMetadataExtended): Observable<RichTextContentDownload> {
    const cacheKey = this.getCacheKey(metadata.npcId, metadata.sectionId, metadata.fieldId);
    const cachedContent = localStorage.getItem(cacheKey);
    if (cachedContent) {
      return of({ metadata, htmlContent: cachedContent, error: undefined });
    }
    if (metadata.versionId === undefined) {
      // section content is not yet initialized, returning empty content
      return of({ metadata, htmlContent: '', error: undefined });
    } else {
      const url = `${this.apiUrl}?npc_id=${metadata.npcId}&section_id=${metadata.sectionId}&field_id=${metadata.fieldId}&version_id=${metadata.versionId}`;
      return this.http.get<{ presigned_url: string }>(url).pipe(
        switchMap(response => {
          const downloadUrl = response.presigned_url;
          return this.httpBypassProxy.get(downloadUrl, { responseType: 'text' }).pipe(
            tap(htmlContent => {
              const expirationTime = 60 * 60 * 1000; // 1 hour
              localStorage.setItem(cacheKey, htmlContent);
              localStorage.setItem(`${cacheKey}_expiration`, (new Date().getTime() + expirationTime).toString());
            }),
            map(htmlContent => ({ metadata, htmlContent, error: undefined })),
            catchError(error => {
              console.error(`Failed to download rich text content for section ${metadata.sectionId}: `, error);
              return of({ metadata, htmlContent: '', error });
            })
          );
        }),
        catchError(error => {
          console.error(`Failed to get download URL for section ${metadata.sectionId}: `, error);
          return of({ metadata, htmlContent: '', error });
        })
      );
    }
  }

  public uploadRichText(richTextContent: RichTextContent): Observable<RichTextContentStatus> {
    const metadata: RichTextContentMetadataExtended = richTextContent.metadata;
    const npcId: string = metadata.npcId;
    const sectionId: string = metadata.sectionId;
    const fieldId: string = metadata.fieldId;
    const cacheKey = this.getCacheKey(npcId, sectionId, fieldId);
    const url = `${this.apiUrl}?npc_id=${npcId}&section_id=${sectionId}&field_id=${fieldId}`;
    return this.http.put<GetPresignedUrlResponse>(url, {}).pipe(
      switchMap((urlResp: GetPresignedUrlResponse) => {
        const uploadUrl: string = urlResp.presigned_url;
        return this.httpBypassProxy.put(uploadUrl, richTextContent.htmlContent, 
          {}).pipe(
          switchMap(s3UploadResp => {
            return this.http.get<GetLatestVersionResponse>(`${this.apiUrl}/latest-version?npc_id=${npcId}&section_id=${sectionId}&field_id=${fieldId}`).pipe(
              tap(latestVersionResponse => {
                const expirationTime = 60 * 60 * 1000; // 1 hour
                localStorage.setItem(cacheKey, richTextContent.htmlContent as string);
                localStorage.setItem(`${cacheKey}_expiration`, (new Date().getTime() + expirationTime).toString());
              }),
              map(latestVersionResponse => {
                const putResponseMetadata: RichTextContentMetadataExtended = {npcId, sectionId, fieldId, versionId: latestVersionResponse.version_id};
                const putResponse: RichTextContentStatus = {metadata: putResponseMetadata, status: true, error: undefined};
                return putResponse;
              }),
              catchError(error => {
                console.error(`Failed to get latest version of the uploaded rich text content for section ${metadata.sectionId}:`, error);
                const putResponse: RichTextContentStatus = {metadata, status: false, error};
                return of(putResponse);
              })
            )
          }),
          catchError(error => {
            console.error(`Failed to upload rich text content for section ${metadata.sectionId}:`, error);
            const putResponse: RichTextContentStatus = {metadata, status: false, error};
            return of(putResponse);
          })        
        );
      }),
      catchError(error => {
        console.error(`Failed to get URL for uploading rich text content for section ${metadata.sectionId}:`, error);
        const putResponse: RichTextContentStatus = {metadata, status: false, error};
        return of(putResponse);
      }) 
    );
  }

}
