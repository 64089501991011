import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MIFIDClassification } from '../model/enum/mifid-classification';
import { NTMBClassification } from '../model/enum/ntmb-classification';
import { TmbClassification } from '../model/enum/tmb-classification';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { MatDialog } from '@angular/material/dialog';
import { Classification, ClassificationValue } from '../model/classifications';

export interface ClassificationOption {
  label: string;
  value: string;
}

function otherDescriptionValidator(form: FormGroup, type: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const needRequire = form.get(type)?.value.includes(TmbClassification.E_OTHER);
    const noValue = needRequire ? !(control.value) || control.value.length === 0 : false;
    return noValue ? {required: control.value} : null;
  };
}

@Component({
  selector: 'app-classifications',
  templateUrl: './classifications.component.html',
  styleUrls: ['./classifications.component.css']
})
export class ClassificationsComponent extends NpcRequestSectionBase implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  public tmbs: ClassificationOption[] = this.getEnumAsArray(TmbClassification);
  public ntmbs: ClassificationOption[] = this.getEnumAsArray(NTMBClassification);
  public mifids: ClassificationOption[] = this.getEnumAsArray(MIFIDClassification);
  public TMBOtherSelected = false;
  public NTMBOtherSelected = false;
  public mifidOptionValues = ['yes', 'no'];
  public mifidOptionValuesExtended = ['yes', 'no', 'N/A'];

  private getEnumAsArray(enumObj: any): ClassificationOption[] {
    return Object.keys(enumObj).map(key => ({ label: enumObj[key], value: key }));
  }    

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    const mifid: FormGroup = new FormGroup({});
    this.mifids.forEach((mifidOption: ClassificationOption) => {
      if (!this.isDisabled('MIFID')) {
        let subControl: FormControl | undefined = undefined;
        if (this.isReadOnly('MIFID')) {
          subControl = new FormControl({value: null, disabled: true}, []);
        } else {
          subControl = new FormControl(null, []);
        }
        mifid.addControl(mifidOption.value, subControl);
      }
    });

    this.fields = {
      TMB: new FormControl([]),
      NTMB: new FormControl([]),
      MIFID: mifid,
      TMBOther: new FormControl(undefined),
      NTMBOther: new FormControl(undefined),
    };
    const form: FormGroup = new FormGroup(this.fields);
    form.get('TMBOther')?.addValidators(otherDescriptionValidator(form, 'TMB'));
    form.get('NTMBOther')?.addValidators(otherDescriptionValidator(form, 'NTMB'));

    this.form = form;
    if (this.form.get('TMB') !== null) {
      this.subscriptions.push(
        this.form.get('TMB')!.valueChanges.subscribe(value => {
          const hasOther = value.some((item: string) => item.endsWith('_OTHER'));
          this.TMBOtherSelected = hasOther;
        })
      );
    }
    if (this.form.get('NTMB') !== null) {
      this.subscriptions.push(
        this.form.get('NTMB')!.valueChanges.subscribe(value => {
          const hasOther = value.some((item: string) => item.endsWith('_OTHER'));
          this.NTMBOtherSelected = hasOther;
        })
      );
    }
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  protected override getUpdatedModel(): any {
    const model: Classification[] = [];
    for (const type of ['TMB', 'NTMB', 'MIFID']) {
      model.push(this.getModelFromForm(type));
    }
    return model;
  }

  private getModelFromForm(type: string): Classification {
    let classificationValues: string[][] = [];
    if (type === 'TMB') {
      classificationValues = Object.entries(TmbClassification);
    } else if (type === 'NTMB') {
      classificationValues = Object.entries(NTMBClassification);
    } else if (type === 'MIFID') {
      classificationValues = Object.entries(MIFIDClassification);
    }
    const values: ClassificationValue[] = classificationValues.map(([id, label], index) => (
      { 
        id, 
        description: id.endsWith('_OTHER') ? this.form.get(`${type}Other`)?.value || label : label,
        value: type === 'MIFID' ? 
          this.form.get(`MIFID.${id}`)?.value :
          this.form.get(type)?.value.includes(id) ? "yes" : "no"
      }
    ));
    return { type, values };
  }

  protected override setFormFromModel(): void {
    if (this.form !== undefined) {
      if (this.model) { 
        const formValue = {
          TMB: this.getClassificationValueFromModel('TMB'),
          NTMB: this.getClassificationValueFromModel('NTMB'),
          MIFID: this.getClassificationValueFromModel('MIFID'),
          TMBOther: this.getOtherDescriptionFromModel('TMB'),
          NTMBOther: this.getOtherDescriptionFromModel('NTMB'),
        };
        this.form.patchValue(formValue);
        this.form.markAllAsTouched();
      }
    }
  }

  private getClassificationValueFromModel(type: string): any {
    // Get the values from the model
    if (type === 'MIFID') {
      const mifidValues: {[key: string]: string} = {};
      this.mifids.forEach((mifidOption: ClassificationOption, index: number) => {
        mifidValues[mifidOption.value] = this.model?.find((classification: Classification) => classification.type === 'MIFID')?.values
          .find((classificationValue: ClassificationValue) => classificationValue.id === mifidOption.value)
          .value || null;
      });
      return mifidValues;
    }
    return (this.model as Classification[]).find((classification: Classification) => classification.type === type)?.values
        .filter(value => value.value === "yes")
        .map(value => value.id) 
      ?? [];
  }

  private getOtherDescriptionFromModel(type: string): string | undefined {
    const classificationValue: ClassificationValue | undefined =  (this.model as Classification[])
      .find((classification: Classification) => classification.type === type)?.values
        .find((value: ClassificationValue) => value.id.endsWith("_OTHER"));
    return classificationValue?.description || undefined;
  }

  public isMifidOptionSelected(key: string, value: string): boolean {
    return this.form.get('MIFID')?.value[key] === value;
  }

}
