import { Component, ViewChild, AfterViewInit, OnInit, QueryList, ViewChildren, AfterContentChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { StatusChangeDialogComponent } from './workflow/status-change/status-change-dialog.component';
import { FormControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';


import { Classification } from './model/classifications';
import { ConciseRequest } from './model/concise-request';
import { GenericInfo } from './model/generic-info';
import { JIRATaskInfoQualified, NpcMetadata, NpcRequest, NpcRequestExtended, NpcRequestNew, NpcRequestTechnicalChange } from './model/npc-request';
import { ProductDescription } from './model/product-description';
import { Risks } from './model/risks';
import { SpecificRemarks } from './model/specific-remarks';
import { OperationalResponsibilities } from './model/operational-responsibilities';
import { DealEntryInstructions } from './model/deal-entry-instructions';
import { Valuation } from './model/valuation';

import { ConciseRequestComponent } from './concise-request/concise-request.component';
import { NpcRequestSectionBase } from './npc-request-section.base';
import { RiskComponent } from './risk/risk.component';
import { ClassificationsComponent } from './classifications/classifications.component';
import { SpecificRemarksComponent } from './specific-remarks/specific-remarks.component';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { emptyRequest } from './npc-request-empty';
import { emptyRequestTechnicalChange } from './technical-change-empty';
import { ValuationComponent } from './valuation/valuation.component';
import { DealEntryInstructionsComponent } from './deal-entry-instructions/deal-entry-instructions.component';
import { OperationalResponsibilitiesComponent } from './operational-responsibilities/operational-responsibilities.component';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { RequestType, ListsPerRequestType, NpcSectionResponse, NpcSectionError, NpcPermissions } from './npc-request-types';
import { ActivatedRoute } from '@angular/router';
import { NpcRequestService } from './npc-request.service';
import { getApiErrorMessage } from '../utils/api-error';
import { RichTextContentStatus } from '../rich-text-editor/rich-text-editor.types';
import { Status, StatusTransition, WorkflowActions, getAllTransitions, getApprovalActions, getTransitions } from './workflow/actions';
import { getStatusLabel } from './workflow/actions';
import { AttachmentMetadata } from '../attachment/attachment.types';
import { NpcAttachmentsComponent } from './attachments/npc-attachments.component';
import { AuthService } from '../authentication/auth.service';
import { ProductDescriptionTechnicalChangeComponent } from './product-description-technical-change/product-description-technical-change.component';
import { ProductListService } from '../product-catalogue/product-list.service';
import { ProductUi } from '../product-catalogue/product';
import { ProductViewerDialogComponent } from '../product-catalogue/product-viewer-dialog/product-viewer-dialog.component';

interface NpcSection {
  id: string;
  componentChildView?: NpcRequestSectionBase;
}

@Component({
  selector: 'app-npc-request',
  templateUrl: './npc-request.component.html',
  styleUrls: ['./npc-request.component.css']
})
export class NpcRequestComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  npcRequest?: NpcRequest;
  public isNew: boolean = true;
  private sectionsSaveResponsesNb: number = 0;
  public errors: NpcSectionError[] = [];
  public isSaving: boolean = false;
  public saveFinished: boolean = false;
  public npcId: string = uuidv4();
  private TIMEOUT_SAVE_SECONDS = 60;
  public requestType: RequestType = RequestType.NEW_REQUEST;
  private disabledSections: ListsPerRequestType = {
    [RequestType.NEW_REQUEST]: [],
    [RequestType.EXTENSION]: [],
    [RequestType.TECH_CHANGE]: ['risks', 'classifications']
  }; 
  public readOnlyMode: boolean = false;
  public infoPanelOpenState: boolean = false;
  public transitionsPanelOpenState: boolean = false;
  public approvalsPanelOpenState: boolean = false;
  public jiraTasksPanelOpenState: boolean = false;
  public sharepointPanelOpenState: boolean = false;
  public productsPanelOpenState: boolean = false;
  public requestMetadata?: NpcMetadata = undefined;
  private richTextContentsGetStatus: RichTextContentStatus[][] = [];
  public transitions: StatusTransition[] = [];
  public adminActions: string[] = [];
  public selectedOptionControl: FormControl = new FormControl();
  public selectedAdminOptionControl: FormControl = new FormControl();
  public jiraTaskInfos: {[key: string]: JIRATaskInfoQualified} = {};
  private OPTION_NON_STANDARD_STATUS_CHANGE: string = 'Non-standard status changes';
  private subscriptions: Subscription[] = [];
  private submit: boolean = false;
  public canEdit: boolean = false;
  public isAdmin: boolean = false;
  // set default all-allowed edit permissions, until specified otherwise
  public npcEditPermissions: NpcPermissions = {};
  public sourceProductId?: string = undefined;

  @ViewChild(ConciseRequestComponent) conciseRequestComponent!: ConciseRequestComponent;
  @ViewChild(RiskComponent) risksComponent!: RiskComponent;
  @ViewChild(ClassificationsComponent) classificationsComponent!: ClassificationsComponent;
  @ViewChild(SpecificRemarksComponent) specificRemarksComponent!: SpecificRemarksComponent;
  @ViewChild(ProductDescriptionComponent) productDescriptionComponent!: ProductDescriptionComponent;
  @ViewChild(ProductDescriptionTechnicalChangeComponent) productDescriptionTechnicalChangeComponent!: ProductDescriptionTechnicalChangeComponent;
  @ViewChild(GeneralInfoComponent) genericInfoComponent!: GeneralInfoComponent;
  @ViewChild(ValuationComponent) valuationComponent!: ValuationComponent;
  @ViewChild(OperationalResponsibilitiesComponent) operationalResponsibilitiesComponent!: OperationalResponsibilitiesComponent;
  @ViewChild(DealEntryInstructionsComponent) dealEntryInstructionsComponent!: DealEntryInstructionsComponent;
  @ViewChild(NpcAttachmentsComponent) attachmentsComponent!: NpcAttachmentsComponent;

  @ViewChildren(ConciseRequestComponent) conciseRequestComponentChildren?: QueryList<ConciseRequestComponent>;
  @ViewChildren(RiskComponent) risksComponentChildren?: QueryList<RiskComponent>;
  @ViewChildren(ClassificationsComponent) classificationsComponentChildren?: QueryList<ClassificationsComponent>;
  @ViewChildren(SpecificRemarksComponent) specificRemarksComponentChildren?: QueryList<SpecificRemarksComponent>;
  @ViewChildren(ProductDescriptionComponent) productDescriptionComponentChildren?: QueryList<ProductDescriptionComponent>;
  @ViewChildren(ProductDescriptionTechnicalChangeComponent) productDescriptionTechnicalChangeComponentChildren?: 
    QueryList<ProductDescriptionTechnicalChangeComponent>;
  @ViewChildren(GeneralInfoComponent) genericInfoComponentChildren?: QueryList<GeneralInfoComponent>;
  @ViewChildren(ValuationComponent) valuationComponentChildren?: QueryList<ValuationComponent>;
  @ViewChildren(OperationalResponsibilitiesComponent) operationalResponsibilitiesComponentChildren?: QueryList<OperationalResponsibilitiesComponent>;
  @ViewChildren(DealEntryInstructionsComponent) dealEntryInstructionsComponentChildren?: QueryList<DealEntryInstructionsComponent>;
  @ViewChildren(NpcAttachmentsComponent) attachmentsComponentChildren?: QueryList<NpcAttachmentsComponent>;

  private sections: NpcSection[] = [];
  
  constructor(protected dialog: MatDialog, private route: ActivatedRoute, private npcRequestService: NpcRequestService,
    private router: Router, private location: Location, private cdref: ChangeDetectorRef,
    private authService: AuthService, private productService: ProductListService) {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params) {
          if (params['readOnlyMode'] !== undefined) {
            this.readOnlyMode = params['readOnlyMode'] === 'true';
          }
          if (params['sourceProductId']) {
            this.sourceProductId = params['sourceProductId'];
          }
        
          if (params['action']) {
            switch(params['action']) {
              case 'edit':
                this.isNew = false;
                break;
              case 'view':
                this.isNew = false;
                this.readOnlyMode = true;
                break;
              case 'new':
                this.isNew = true;
                this.readOnlyMode = false;
                break;
              default:
                this.isNew = true;
                break;
            }
          }

          if (params['requestType']) {
            if (Object.values(RequestType).includes(params['requestType'] as unknown as RequestType)) {
              this.requestType = params['requestType'] as unknown as RequestType;
            } else {
              console.error(`Unknown request type: ${params['requestType']}`);
            }
          } else {
            this.requestType = RequestType.NEW_REQUEST;
          }
          if (!this.isNew) {
            if (!params['npcId']) {
              this.displayGeneralErrorDialog("No parameter 'npcId' provided");
            } else {
              this.npcId = params['npcId'];
              this.subscriptions.push(
                this.npcRequestService.getNpcRequest(this.npcId!).subscribe({
                  next: (npcRequests: NpcRequestExtended[]) => {
                    if (!Array.isArray(npcRequests) || npcRequests.length !== 1) {
                      this.displayGeneralErrorDialog(`Could not retrieve NPC request with id '${this.npcId}'`);
                      return;
                    }
                    const npcRequest: NpcRequestExtended = npcRequests[0];
                    const { metadata, ...request } = npcRequest;
                    this.requestMetadata = metadata;
                    this.npcRequest = request as NpcRequest;

                    if (this.requestMetadata?.status) {
                    // check if user can edit this request depending on request status and user roles
                      this.subscriptions.push(
                        this.authService.canEditNpc(this.requestMetadata?.status).subscribe(canEdit => {
                          this.canEdit = canEdit;
                        })
                      );

                      // check edit permissions within the NPC document
                      if(!this.readOnlyMode) {
                        this.subscriptions.push (
                          this.authService.getNpcEditPermissions(this.requestMetadata?.status).subscribe(npcEditPermissions => {
                            this.npcEditPermissions = npcEditPermissions;
                          })
                        )
                      }
                    }

                    this.setWorkflowActions();

                    // Get JIRA Tasks info, only if JIRA implementation task is already registered
                    if (this.requestMetadata?.task?.holderTask) {
                      this.jiraTaskInfos = {};
                      this.subscriptions.push(
                        this.npcRequestService.getJiraTaskInfo(this.npcId!).subscribe({
                          next: (jiraTaskInfos: JIRATaskInfoQualified[]) => {
                            if (Array.isArray(jiraTaskInfos)) {
                              jiraTaskInfos.forEach((jiraTaskInfo: JIRATaskInfoQualified) => {
                                this.jiraTaskInfos[jiraTaskInfo.id] = jiraTaskInfo;
                              });
                            }
                          },
                          error: (error: any) => {
                            console.error(`Error retrieving JIRA tasks info: ${error.message}`);
                          }
                        })
                      );
                    }
                  },
                  error: (error: any) => {
                    console.error(`Error retrieving NPC request: ${error.message}`);
                    this.displayGeneralErrorDialog(`Error retrieving NPC request: ${error.message}`);
                  }
                })
              );
            }
          } else {
            // retrieve permissions for new request
            this.subscriptions.push (
              this.authService.getNpcEditPermissions(undefined).subscribe(npcEditPermissions => {
                this.npcEditPermissions = npcEditPermissions;
              })
            );

            if (this.requestType === RequestType.NEW_REQUEST) {
              const newRequest: NpcRequestNew = emptyRequest as NpcRequestNew;
              this.npcRequest = newRequest;
            } else {
              // we need to retrieve the source product from the API
              this.subscriptions.push(
                this.productService.getProduct(this.sourceProductId!).subscribe({
                  next: (product: ProductUi) => {
                    if (product === undefined) {
                      this.displayGeneralErrorDialog(`Could not retrieve source product with id '${this.sourceProductId}'`);
                      return;
                    }
                    if (this.requestType === RequestType.EXTENSION) {
                      const newExtension: NpcRequestNew = emptyRequest as NpcRequestNew;
                      newExtension['productDescription']['productGroup'] = product['productGroup'];
                      newExtension['productDescription']['product'] = product['product'];
                      newExtension['productDescription']['classification'] = product['classification'];
                      newExtension['productDescription']['instrument'] = [product['instrument']];
                      newExtension['productDescription']['commodity'] = product['commodity'];
                      newExtension['productDescription']['tradedVia'] = product['tradedVia'] ? [product['tradedVia']]: [];
                      newExtension['productDescription']['platform'] = product['platform'] ? [product['platform']]: [];
                      this.npcRequest = newExtension;
                    } else {
                      // technical change
                      const newTechnicalChange: NpcRequestTechnicalChange = emptyRequestTechnicalChange as NpcRequestTechnicalChange;
                      this.npcRequest = newTechnicalChange;
                    }
                  },
                  error: (error: any) => {
                    console.error(`Error retrieving source product: ${error.message}`);
                    this.displayGeneralErrorDialog(`Error while retrieving source product: ${error.message}`);
                  }
                })
              );
            }
          }

          // Check if user can edit this request and if workflow actions are allowed
          this.subscriptions.push(
            this.authService.isAdmin().subscribe(isAdmin => {
              this.isAdmin = isAdmin;
            })
          );
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.isNew) {
      this.initComponent(this.conciseRequestComponent, 'conciseOfRequest');
      this.initComponent(this.specificRemarksComponent, 'specificRemarks');
      this.initComponent(this.productDescriptionComponent, 'productDescription');
      this.initComponent(this.genericInfoComponent, 'genericInfo');
      this.initComponent(this.valuationComponent, 'valuation');
      this.initComponent(this.operationalResponsibilitiesComponent, 'operationalResponsibilities');
      this.initComponent(this.dealEntryInstructionsComponent, 'dealEntryInstructions');
      this.initComponent(this.attachmentsComponent, 'attachments');
      if (this.isTechnicalChange()) {
        this.initComponent(this.productDescriptionTechnicalChangeComponent, 'productDescription');
      } else {
        this.initComponent(this.productDescriptionComponent, 'productDescription');
        this.initComponent(this.risksComponent, 'risks');
        this.initComponent(this.classificationsComponent, 'classifications');
      }
    } else {
      this.initSection(this.conciseRequestComponentChildren, 'conciseOfRequest');
      this.initSection(this.specificRemarksComponentChildren, 'specificRemarks');
      this.initSection(this.genericInfoComponentChildren, 'genericInfo');
      this.initSection(this.valuationComponentChildren, 'valuation');
      this.initSection(this.operationalResponsibilitiesComponentChildren, 'operationalResponsibilities');
      this.initSection(this.dealEntryInstructionsComponentChildren, 'dealEntryInstructions');
      this.initSection(this.attachmentsComponentChildren, 'attachments');
      if (this.isTechnicalChange()) {
        this.initSection(this.productDescriptionTechnicalChangeComponentChildren, 'productDescription');
      } else {
        this.initSection(this.productDescriptionComponentChildren, 'productDescription');
        this.initSection(this.risksComponentChildren, 'risks');
        this.initSection(this.classificationsComponentChildren, 'classifications');
      }
    }
  }

  private initSection(compsList: QueryList<NpcRequestSectionBase> | undefined, sectionId: string): void {
    if (compsList !== undefined) {
      this.subscriptions.push(
        compsList.changes.subscribe((comps: QueryList<NpcRequestSectionBase>) => {
          if (comps.length === 0) {
            console.log(`No component found for section ${sectionId}`);
            return;
          }
          const comp: NpcRequestSectionBase = comps.first;
          this.initComponent(comp, sectionId);
        })
      );
    }
  }

  private initComponent(comp: NpcRequestSectionBase, sectionId: string): void {
    if (comp === undefined) {
      console.log(`Component for section ${sectionId} is undefined`);
    } else if (this.isSectionEnabled(comp.sectionId)) {
      this.sections.push({ id: sectionId, componentChildView: comp });
      comp.getRichTextContents();
    }
  }

  public updateSection(resp: NpcSectionResponse, sectionId: string): void {
    if (this.npcRequest) {
      switch (sectionId) {
        case 'genericInfo':
          this.npcRequest.genericInfo = resp.model as GenericInfo;
          break;
        case 'conciseOfRequest':
          this.npcRequest.conciseOfRequest = resp.model as ConciseRequest;
          break;
        case 'productDescription':
          this.npcRequest.productDescription = resp.model as ProductDescription;
          break;
        case 'risks':
          (this.npcRequest as NpcRequestNew).risks = resp.model as Risks;
          break;
        case 'classifications':
          (this.npcRequest as NpcRequestNew).classifications = resp.model as Classification[];
          break;
        case 'specificRemarks':
          this.npcRequest.specificRemarks = resp.model as SpecificRemarks;
          break;
        case 'dealEntryInstructions':
          this.npcRequest.dealEntryInstructions = resp.model as DealEntryInstructions;
          break;
        case 'operationalResponsibilities':
          this.npcRequest.operationalResponsibilities = resp.model as OperationalResponsibilities;
          break;
        case 'valuation':
          this.npcRequest.valuation = resp.model as Valuation;
          break;
        case 'attachments':
          this.npcRequest.attachments = resp.model as AttachmentMetadata[];
          break;
        default:
          break;
      }
      this.finalizeSave(resp.errors);
    }
  }
  
  private validate(): void {
    this.errors = [];
    this.sections.forEach((section: NpcSection) => {
      console.log(`Validating section ${section.id}`);
      const component = section.componentChildView;
      if (component && this.isSectionEnabled(section.id)) {
        const sectionErrors: NpcSectionError[] = component.validate();
        if (sectionErrors.length > 0) {
          this.errors = this.errors.concat(sectionErrors);
        }
      }
      console.log(`Finished validating section ${section.id}`);
    });
  }

  public save(submit: boolean): void {
    this.submit = submit;
    console.log(`Nb sections: ${this.sections.length}`);
    this.isSaving = true;
    this.validate();
    if (this.errors.length > 0) {
      this.isSaving = false;
      if (this.errors.length > 0) {
        this.displayValidationErrorsDialog(this.errors);
      }
      return;
    }
    this.sectionsSaveResponsesNb = 0;
    this.sections.forEach(section => {
      const component = section.componentChildView;
      if (component && this.isSectionEnabled(section.id)) {
        console.log(`Saving section ${section.id}`);
        component.save();
      } else {
        if (!this.isSectionEnabled(section.id)) {
          console.log(`Warning: cannot save section ${section.id}, as it is disabled`);
        } else {
          console.log(`Warning: cannot save section ${section.id}, as it has no initialized component`);
        }
      }
    });
    
    // Set a timeout mechanism in case save process hangs
    setTimeout(() => {
      if (this.isSaving) {
        this.isSaving = false;
        this.displayGeneralErrorDialog('The save process has timed out because of an unknown error');
      }
    }, 1000 * this.TIMEOUT_SAVE_SECONDS);
  }

  private finalizeSave(respErrors: NpcSectionError[]): void {
    if (respErrors.length > 0) {
      this.errors = this.errors.concat(respErrors);
    }
    this.sectionsSaveResponsesNb++;
    console.log(`Received ${this.sectionsSaveResponsesNb} save responses`);
    if (this.sectionsSaveResponsesNb === this.sections.filter(section => this.isSectionEnabled(section.id)).length) {
      this.isSaving = false;
      this.saveFinished = true;

      // Display errors or success dialog
      if (this.errors.length > 0) {
        this.displayValidationErrorsDialog(this.errors);
      } else {
        console.log('No errors, saving into the API...');

        if (this.isNew) {
          this.subscriptions.push(
            this.npcRequestService.saveNpcRequest(this.npcRequest as NpcRequest, this.requestType, this.npcId, this.sourceProductId).subscribe({
              next: (npcMetadata: NpcMetadata) => {
                this.handleSaveResult(npcMetadata);
              },
              error: (error: any) => {
                this.displayGeneralErrorDialog(error, 'Error while saving NpcRequest');
                console.error(`Error while saving NpcRequest: ${JSON.stringify(error, null, 2)}`);
              }
            })
          );
        } else {
          this.subscriptions.push(
            this.npcRequestService.updateNpcRequest(this.npcId!, this.npcRequest as NpcRequest).subscribe({
              next: (npcMetadata: NpcMetadata) => {
                this.handleSaveResult(npcMetadata);
              },
              error: (error: any) => {
                console.log(`Error while updating NpcRequest: ${error.message}`);
                this.displayGeneralErrorDialog(error, 'Error while updating NpcRequest');
                console.error(error, `Error while updating NpcRequest: ${JSON.stringify(error, null, 2)}`);
              }
            })
          );
        }
      }
    } else {
      console.log(`Number of sections saved: ${this.sectionsSaveResponsesNb}`);
    }
  }

  private handleSaveResult(npcMetadata: NpcMetadata): void {
    this.requestMetadata = npcMetadata;
    if (this.submit && (this.isNew || this.requestMetadata?.status === Status.DRAFT_STATUS)) {
      this.changeStatusToNew();
    } else {
      this.displaySaveSuccessDialog(npcMetadata);
    }
  }

  private changeStatusToNew(): void {
    this.subscriptions.push(
      this.npcRequestService.changeStatus(this.npcId, Status.NEW_STATUS, false).subscribe({
        next: (npcMetadata: NpcMetadata) => {
          console.log("Status changed successfully to 'New'");
          this.requestMetadata = npcMetadata;
          this.displaySaveSuccessDialog(npcMetadata, true);
        },
        error: (error: any) => {
          console.log(`Error while changing status from 'Draft' to 'New': ${error.message}`);
          this.displayGeneralErrorDialog(error, "Error while changing status from 'Draft' to 'New'");
          console.error(error, `Error while changing status from 'Draft' to 'New': ${JSON.stringify(error, null, 2)}`);
        }
      })
    );
  }

  private displaySaveSuccessDialog(metadata: NpcMetadata, isSubmitted: boolean = false): void {
    let action: string = this.isNew ? 'registered as draft' : 'updated';
    if (isSubmitted) {
      action = 'submitted';
    } 
    const dialogRef = this.dialog.open(DialogHelpComponent, { data: { 
      title: `Your ${this.requestType} has been successfully ${action}!` 
    } });

    dialogRef.componentInstance.htmlContent = `<div><ul>
      <li>npcId: ${metadata['npcId']}</li>
      <li>Product Number: ${metadata['productNumber']}</li>
      <li>Status: ${isSubmitted ? 'New' : getStatusLabel(metadata['status'])}</li>
      </ul></div>`;
    if (this.isNew || isSubmitted) {
      dialogRef.afterClosed().subscribe(_ => {
        this.router.navigate(['/npc-request'], { queryParams: { action: 'view', npcId: metadata.npcId } });
      });
    }
  }

  private displayGeneralErrorDialog(error: any, title: string = 'An error occurred!'): void {
    const errorMessage: string = getApiErrorMessage(error);
    const dialogRef = this.dialog.open(DialogHelpComponent, { data: { title } });
    dialogRef.componentInstance.htmlContent = `<div>${errorMessage}</div>`;
  }

  private displayValidationErrorsDialog(errors: NpcSectionError[], title: string = 'Validation errors!'): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, { data: { title } });
    const errorsBySection = errors.reduce((acc: {[key: string]: any}, error: NpcSectionError) => {
      if (!acc[error.sectionId]) {
        acc[error.sectionId] = [];
      }
      acc[error.sectionId].push(error);
      return acc;
    }, {});
    let htmlContent: string = '';
    for (const sectionId in errorsBySection) {
      if (errorsBySection.hasOwnProperty(sectionId)) {
        htmlContent += `<li>${convertToReadableText(sectionId)}:<ul>`;
        for (const error of errorsBySection[sectionId]) {
          htmlContent += `<li>${convertToReadableText(error.fieldId)}: ${error.error}</li>`;
        }
        htmlContent += `</ul></li>`;
      }
    }
    const htmlContentFull: string = '<div>' + htmlContent + '</div>';
    dialogRef.componentInstance.htmlContent = `<ul>${htmlContentFull}</ul>`;
  } 

  public isSectionEnabled(sectionId: string): boolean {
    return !this.disabledSections[this.requestType]?.includes(sectionId) || true;
  }

  public updateRichTextContentGetStatus(status: RichTextContentStatus[]): void {
    this.richTextContentsGetStatus.push(status);
    this.finalizeGetRichTextContents();
  }

  public finalizeGetRichTextContents(): void {
    if (this.richTextContentsGetStatus.length === this.sections.filter(section => this.isSectionEnabled(section.id)).length) {
      const richTextGetErrors: NpcSectionError[] = this.richTextContentsGetStatus
        .flat()
        .filter((status: RichTextContentStatus) => status.error !== undefined)
        .map((status: RichTextContentStatus) => {
          return {
            sectionId: status.metadata.sectionId,
            fieldId: status.metadata.fieldId,
            error: getApiErrorMessage(status.error)
          };
      });
      if (richTextGetErrors.length > 0) {
        this.displayValidationErrorsDialog(richTextGetErrors, 'Error while retrieving rich text contents');
      }
    }
  }

  public cancel() {
    this.router.navigate(['/npc-list']);
  }

  public goToEditMode(): void {
    const queryParams = { action: 'edit', npcId: this.npcId, status: this.requestMetadata?.status };
    const urlTree = this.router.createUrlTree([], { queryParams });
    const url = this.location.prepareExternalUrl(urlTree.toString());
    window.location.href = url;
  }

  private setWorkflowActions(): void {
    if (this.isNew) {
      this.transitions = [];
      this.adminActions = [];
      return;
    }
    const status: string = this.requestMetadata?.status || '';
    this.transitions = getTransitions(status);
    this.adminActions = [this.OPTION_NON_STANDARD_STATUS_CHANGE, WorkflowActions.CREATE_PRODUCT].concat(getApprovalActions())
      .filter((action: string) => !this.transitions.some((transition: StatusTransition) => transition.action.toString() === action));
    if (this.requestMetadata !== undefined && (!this.requestMetadata.task || !this.requestMetadata.task.holderTask)) {
      this.adminActions.push(WorkflowActions.CREATE_IMPLEMENTATION);
    }
  }

  public onActionSelected(selectedTransition?: StatusTransition) {
    if (selectedTransition) {
      if (selectedTransition.approvalType) {
        this.displayRequestApprovalDialog(selectedTransition.action, selectedTransition.approvalType);
      } else if (selectedTransition.action == WorkflowActions.CREATE_IMPLEMENTATION){
        this.displayCreateImplementationDialog();
      } else if (selectedTransition.action == WorkflowActions.CHECK_PRODUCT) {
        this.goToCheckProduct();
      } else if (selectedTransition.action == WorkflowActions.CREATE_PRODUCT) {
        this.goToCreateProduct();
      } else {
        this.displayStatusChangeDialog(selectedTransition.to, false);
      }
      this.selectedOptionControl.reset();
    }
  }

  public onAdminActionSelected(action?: string) {
    if (action) {
      if (action === this.OPTION_NON_STANDARD_STATUS_CHANGE) {
        this.displayStatusChangeDialog(undefined, true);
      } else if (action === WorkflowActions.CREATE_IMPLEMENTATION) {
        this.displayCreateImplementationDialog();
      } else if (getApprovalActions().includes(action)) {
        const approvalType: string | undefined = getAllTransitions()
          .find((transition: StatusTransition) => transition.action === action)?.approvalType;
        if (approvalType) {
          this.displayRequestApprovalDialog(action, approvalType);
        }
      } else if (action === WorkflowActions.CHECK_PRODUCT) {
        this.goToCheckProduct();
      } else if (action == WorkflowActions.CREATE_PRODUCT) {
        this.goToCreateProduct();
      } else {
        console.error(`Unknown admin action selected: ${action}`);
        return;
      }
      this.selectedAdminOptionControl.reset();
    }
  }

  private displayStatusChangeDialog(toStatus: string | undefined, isAdmin: boolean): void {
    if (!this.requestMetadata?.status) {
      return; // it should never happen
    }
    const subscription = this.dialog.open(StatusChangeDialogComponent, 
      { data: { 
        'npcId': this.npcId, 
        'productNumber': this.requestMetadata?.productNumber, 
        'fromStatus': this.requestMetadata?.status,
        'toStatus': toStatus,
        'isAdmin': isAdmin
      } }
    )
    .afterClosed()
    .subscribe((shouldReload: boolean) => {
      subscription.unsubscribe();
        if (shouldReload) {
          window.location.reload();
        }
    });
  }

  private displayCreateImplementationDialog(): void {
    const queryParams: any = {
      'npcId': this.npcId,
      'productNumber': this.requestMetadata?.productNumber,
      'productName': this.requestMetadata?.productName
    }
    if (this.requestMetadata?.task && this.requestMetadata.task.holderTask) {
      queryParams['holderTask'] = this.requestMetadata.task.holderTask;
      queryParams['subTasks'] = JSON.stringify(this.requestMetadata.task.subTasks || []);
    }
    this.router.navigate(['/create-implementation'], { queryParams });
  }

  private displayRequestApprovalDialog(action: string, approvalType: string): void {
    this.router.navigate(['/request-approval'], 
      { queryParams: {
        'npcId': this.npcId,
        'productNumber': this.requestMetadata?.productNumber,
        'productName': this.requestMetadata?.productName,
        action,
        approvalType
      } }
    );
  }

  private goToCheckProduct(): void {
    this.router.navigate(['/check-product'], { queryParams: { npcId: this.npcId }, state: this.npcRequest });
  }

  private goToCreateProduct(): void {
    this.router.navigate(['/check-product'], { queryParams: { npcId: this.npcId, action: 'create' }, state: this.npcRequest });
  }

  public getStatusLabel(status: Status | string): string {
    return getStatusLabel(status);
  }

  public isTechnicalChange(): boolean {
    return this.requestType === RequestType.TECH_CHANGE;
  }

  public isExtension(): boolean {
    return this.requestType === RequestType.EXTENSION;
  }

  public getNpcRequestNew(): NpcRequestNew | undefined {
    if (this.requestType === RequestType.TECH_CHANGE) {
      return undefined;
    } else {
      return this.npcRequest as NpcRequestNew;
    }
  }

  public expandProductView(event: Event, productId: string){
    event.preventDefault();
    const subscription = this.dialog.open(ProductViewerDialogComponent, 
      { 
        data: productId, 
        width: '70%', 
      }
    )
    .afterClosed()
    .subscribe((shouldReload: boolean) => {
      subscription.unsubscribe();
        if (shouldReload) {
          window.location.reload();
        }
    });
  }
}

function convertToReadableText(text: string): string {
  // Insert spaces before capital letters
  text = text.replace(/([A-Z])/g, ' $1');

  // Capitalize first letter of the string
  text = text.charAt(0).toUpperCase() + text.slice(1);

  return text;
}
