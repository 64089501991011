<h2 mat-dialog-title class="title">Update JIRA implementation Subtasks for request '{{productName}}' ({{productNumber}})</h2>
<div class="info-container">
  <div>You have the ability to add some additional JIRA implementation subtasks. These subtasks will be linked with the main JIRA task.</div>
  <div>Specify below a list of JIRA existing task IDs, which must be linked as subtasks.</div>
</div>
<div mat-dialog-content>
  <div class="form-container">
    <mat-form-field [formGroup]="form" class="taskId-field">
      <mat-label>JIRA Task ID for the subtask</mat-label>
      <input matInput formControlName="taskId">
      <mat-error *ngIf="form.get('taskId')?.invalid">{{ form.get('taskId')?.errors?.['message'] }}</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="form.invalid" 
      (click)="addProject(form.get('taskId')!.value)" class="actions-button">
      Add
    </button>
  </div>
  <div class="form-element-full-width card-container">
    <mat-card *ngFor="let taskId of taskIds" fxLayoutWrap>
      <mat-card-header>
        <mat-card-title>{{ taskId }}</mat-card-title>
        <button mat-icon-button (click)="removeProject(taskId)">
          <mat-icon>delete</mat-icon>
      </button>
    </mat-card-header>
    </mat-card>
  </div>
  <div *ngIf="error" class="error-container">
    Request approval could not be sent because an error occurred: {{error}}
  </div>
</div>
<div *ngIf="jiraTasks && !error" class="info-container">
  <h3><B>
    <span *ngIf="jiraTasks.subTasks.length > 0">JIRA subtasks were successfully created
    </span>
  </B></h3>
  <ul>
    <li>Main task:
      <a href="https://alpiqdigital.atlassian.net/browse/{{jiraTasks.holderTask}}" target="_blank">
        {{jiraTasks.holderTask}}
      </a>
    </li>
    <li *ngFor="let subTask of jiraTasks.subTasks">Subtask: 
      <a href="https://alpiqdigital.atlassian.net/browse/{{subTask}}" target="_blank">
        {{subTask}}
      </a>
  </ul>
</div>
<div *ngIf="statusMessage" class="info-container"><B>{{statusMessage}}</B></div>
<div mat-dialog-actions class="actions-button-container">
  <button mat-raised-button color="primary" (click)="confirm()" class="actions-button" *ngIf="taskIds.length > 0 && !error && !jiraTasks && !isSaving">OK</button>
  <button mat-raised-button color="primary" mat-dialog-close (click)="cancel()" *ngIf="!error && !jiraTasks && !isSaving" class="actions-button">Cancel</button>
  <button mat-raised-button color="primary" (click)="close()" *ngIf="(error || jiraTasks) && !isSaving" class="actions-button">Close</button>
  <div *ngIf="isSaving" class="npc-request-spinner">
    <mat-spinner [diameter]="24"></mat-spinner>
  </div>
</div>