import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { MatDialog } from '@angular/material/dialog';
import { RichTextEditorComponent } from 'src/app/rich-text-editor/rich-text-editor.component';

@Component({
  selector: 'app-deal-entry-instructions',
  templateUrl: './deal-entry-instructions.component.html',
  styleUrls: ['./deal-entry-instructions.component.css']
})
export class DealEntryInstructionsComponent extends NpcRequestSectionBase implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('instructions') instructionsEditorComponent!: RichTextEditorComponent;

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    this.fields = {
      murexInstruments : new FormControl(null, []),
      dealTicketPrefix : new FormControl(null, [])
    };
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    const richTextFields = [
      {
          fieldId: 'instructions', 
          component: this.instructionsEditorComponent, 
          isMandatory: false
      }
    ];
    this.richTextFields = richTextFields;
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  protected override getFormValue(): any {
    // Transform murexInstruments from string to array
    const murexInstrumentsValue = this.form.get('murexInstruments')?.value;
    const murexInstrumentsArray = murexInstrumentsValue ? 
      murexInstrumentsValue.split(',').map((value: string) => value.trim()) :
      [];
    const formValue = { ...this.form.value, murexInstruments: murexInstrumentsArray };
    return formValue;
  }

  protected override getValueFromModel(fieldName: string): any {
    const value: any = super.getValueFromModel(fieldName);
    if (fieldName !== 'murexInstruments') {
      return value;
    }
    // Transform murexInstruments from array to string
    return Array.isArray(value) ? value.join(', '): [];
  }

}