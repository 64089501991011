import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { CookieService } from 'ngx-cookie-service';
import { Observable, map, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Status, getStatusBeforeWaitMgtApproval, getStatusesBeforeImplementationNotDraft } from '../npc-request/workflow/actions';
import { UserRole } from './user-roles.types';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NpcPermissions, NpcSectionNames } from '../npc-request/npc-request-types';

const COOKIE_KEY = 'alpiqio_auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  private readonly apiUrl = `${environment.apiBaseUrl}/users/roles`;
  private readonly storageKeyRoles = 'user_roles';
  private readonly storageKeyRolesExpiration = 15 * 60 * 1000; // 15 minutes in milliseconds
  private isRoleAssumed: boolean = false;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  public logout(): void {
    this.cookieService.delete(COOKIE_KEY);
  }

  public setToken(token: string): void {
    this.cookieService.set(COOKIE_KEY, token);
  }

  public getToken(): string | null {
    const token: string | null = this.cookieService.get(COOKIE_KEY);
    if (token && !this.isTokenExpired(token)) {
      return token;
    }
    return null;
  }

  public hasValidToken(): boolean {
    return !!this.getToken();
  }

  private isTokenExpired(token: string): boolean {
    if (!token) return true;
    const decoded = jwtDecode<JwtPayload>(token);
    const expiry = decoded.exp;
    if (!expiry || Date.now() >= expiry * 1000) {
      this.logout();
      return true;
    }
    return false;
  }

  public redirectToAuth() {
    const redirectUrl = encodeURIComponent(`${window.location.origin}/login`);
    let ssoUrl = `${environment.onePassportSsoUrl}?nocookie&redirect=${redirectUrl}`;
    window.location.href = ssoUrl;
  }

  getUserRoles(): Observable<string[]> {
    const assumedRole: string | null = localStorage.getItem('assume_role');
    if (assumedRole) {
      this.isRoleAssumed = true;
      return of([assumedRole]);
    } else {
      const cachedRoles: string[] | null = this.getCachedRoles();
      if (Array.isArray(cachedRoles)) {
        return of(this.getActualRoles(cachedRoles));
      } else {
        return this.httpClient.get<UserRole[]>(this.apiUrl).pipe(
          map((userRoles) => {
            const ownedRoles: string[] = userRoles
              .filter((userRole: UserRole) => userRole.hasRole)
              .map((userRole: UserRole) => userRole.role);
            this.setCachedRoles(ownedRoles);
            return this.getActualRoles(ownedRoles);
          })
        );
      }
    }
  }

  private getActualRoles(ownedRoles: string[]): string[] {
    const assumedRole: string | null = localStorage.getItem('assume_role');
    if (assumedRole) {
      this.isRoleAssumed = true;
      return [assumedRole];
    } else {
      this.isRoleAssumed = false;
      return ownedRoles;
    }
  }

  private getCachedRoles(): string[] | null {
    const cachedValue: string | null = localStorage.getItem(this.storageKeyRoles);
    if (cachedValue) {
      const { value, expiration } = JSON.parse(cachedValue);
      if (expiration > Date.now()) {
        return value;
      } else {
        localStorage.removeItem(this.storageKeyRoles);
      }
    }
    return null;
  }

  private setCachedRoles(value: string[]): void {
    const expiration = Date.now() + this.storageKeyRolesExpiration;
    const serializedValue = JSON.stringify({ value, expiration });
    localStorage.setItem(this.storageKeyRoles, serializedValue);
  }

  public hasSomeRole(expectedRoles: string[], withoutAssume: boolean = false): Observable<boolean> {
    return this.getUserRoles().pipe(
      map((actualRoles: string[]) => {
        return expectedRoles.some((expectedRole: string) => actualRoles.includes(expectedRole)
          || (withoutAssume && expectedRole === 'admin' && this.isRoleAssumed));
      })
    );
  }

  public isAdmin(): Observable<boolean> {
    return this.hasSomeRole(['admin']);
  }

  public isActualAdmin(): Observable<boolean> {
    return this.hasSomeRole(['admin'], true);
  }

  public canEditNpc(status: string): Observable<boolean> {
    return this.getActivableRolesForEditNpc(status).pipe(
      map((roles: string[]) => roles.length > 0)
    );
  }

  public getValidRolesForEditNpc(status?: string): string[] {
    if (!status) {
      return ['admin', 'user']; // only admin and user can create a new NPC
    }
    const roles: string[] = ['admin'];
    if (getStatusBeforeWaitMgtApproval().includes(status as Status)) {
      roles.push('user');
    }
    if (status !== Status.DRAFT_STATUS) {
      roles.push('risk-manage-user');
    }
    if (getStatusesBeforeImplementationNotDraft().includes(status as Status)) {
      roles.push('compliance-office-user');
      roles.push('middle-office-user');
      roles.push('finance-user');
    }
    return roles;
  }

  public getActivableRolesForEditNpc(status?: string): Observable<string[]> {
    return this.getUserRoles().pipe(
      map((userRoles: string[]) => {
        const validRoles: string[] = this.getValidRolesForEditNpc(status);
        return validRoles.filter((role: string) => userRoles.includes(role));
      })
    );
  }

  public isAdminOrUser(): Observable<boolean> {
    return this.getUserRoles().pipe(
      map((roles: string[]) => roles.includes('admin') || roles.includes('user'))
    );
  }

  public isAtLeastBasicUser(): Observable<boolean> {
    return this.getUserRoles().pipe(
      map((roles: string[]) => roles.length > 0)
    );
  }

  public canCreateNpc(): Observable<boolean> {
    //modifying the role until we let everyone create an NPC
    return this.isAdmin();
  }

  public canViewNpc(): Observable<boolean> {
    return this.getUserRoles().pipe(
      map((roles: string[]) => roles.includes('admin') || roles.includes('user') || roles.includes('middle-office-user') || 
      roles.includes('finance-user') || roles.includes('risk-manage-user') || roles.includes('compliance-office-user'))
    );
  }

  public canViewProducts(): Observable<boolean> {
    return this.isAtLeastBasicUser();
  }

  public canModifyRisks(): Observable<boolean> {
    return this.hasSomeRole(['admin', 'user', 'risk-manage-user']);
  }

  public getNpcEditPermissions(status?: string): Observable<NpcPermissions> {
    return this.getActivableRolesForEditNpc(status).pipe(
      map((roles: string[]) => {
        const permissions: NpcPermissions = {};
        NpcSectionNames.forEach((sectionName: string) => {
          permissions[sectionName] = [];
        });
        if (roles.includes('admin') || roles.includes('user') || roles.includes('middle-office-user') || roles.includes('finance-user')) {
          // no role specific restriction within the NPC documents -> allowing all
          NpcSectionNames.forEach((sectionName: string) => {
            permissions[sectionName] = ['all'];
          });
        } else if (roles.includes('risk-manage-user')) {
          permissions['risks'] = ['all'];
        } else if (roles.includes('compliance-office-user')) {
          if(!permissions['classifications'].includes('all') && !permissions['classifications'].includes('MIFID')) {
            permissions['classifications'].push('MIFID');
          }
        }
        return permissions;
      })
    );
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    const queryParams = route.queryParamMap;
    const urlSegments = route.url.map((segment) => segment.path);
    if (urlSegments.length === 0) {
      return of(true);
    }
    const resource: string = urlSegments[0];
    if (resource === 'npc-request') {
      const action: string | null = queryParams.get('action');
      if (action === 'edit') {
        return this.canEditNpc(queryParams.get('status') as string);
      } else if (action === 'new') {
        return this.isAdminOrUser();
      } else if (action === 'view') {
        return this.canViewNpc();
      } else {
        return of(false); // unknown action
      }
    } else if (resource === 'npc-list') {
      return this.canViewNpc();
    } else if (['rich-text-demo', 'attachment-demo', 'request-approval', 
      'create-implementation', 'update-implementation', 'check-product'].includes(resource)) {
      return this.isAdmin();
    } else if (['products', 'product'].includes(resource)) {
      return this.canViewProducts();
    } else if (resource === 'users') {
      return this.isActualAdmin();
    } else {
      return of(true); // no restrictions
    }
  }
}