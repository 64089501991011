import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { RichTextContentMetadata, RichTextContentStatus, RichTextField } from "../rich-text-editor/rich-text-editor.types";
import { Directive, EventEmitter, Inject, Injectable, Input, Output } from "@angular/core";
import { AttachmentField, AttachmentMetadata, SaveAttachmentsResponse } from "../attachment/attachment.types";
import { MatDialog } from "@angular/material/dialog";
import { DialogHelpComponent } from "../dialog-help/dialog-help.component";
import { Help, HelpPerRequestType, NpcPermissions } from "./npc-request-types";
import { ListsPerRequestType, NpcSectionResponse, NpcSectionError, RequestType } from "./npc-request-types";
import { getApiErrorMessage } from "../utils/api-error";
import { Subscription } from "rxjs";

@Directive()
@Injectable()
export abstract class NpcRequestSectionBase {
    @Input() model?: any;
    @Input() npcId!: string;
    @Input() sectionId!: string;
    @Input() readOnlyMode: boolean = false;
    @Input() requestType!: RequestType;
    @Input() isNew: boolean = false;
    @Input() editPermissions?: NpcPermissions;
    @Output() modelUpdates = new EventEmitter<NpcSectionResponse>();
    @Output() richTextGetStatus = new EventEmitter<RichTextContentStatus[]>();

    private _richTextFields: RichTextField[] = [];
    public _attachmentsField?: AttachmentField;
    public ATTACHMENTS_KEY: string = 'attachments';
    private _form?: FormGroup;
    private specificFields: string[] = [];
    protected help: Help[] = [];
    protected helpPerRequestType: HelpPerRequestType[] = [];
    protected readonlyFields?: ListsPerRequestType;
    protected disabledFields?: ListsPerRequestType;
    private actualReadonlyFields: string[] = [];
    private _richTextSaveErrors: RichTextContentStatus[] = [];
    private _richTextFieldGetStatus: RichTextContentStatus[] = [];
    protected subscriptions: Subscription[] = [];
    protected fields: { [key: string]: AbstractControl } = {};
    private formValidationErrors: NpcSectionError[] = [];

    constructor(protected dialog: MatDialog, @Inject('ReadonlyFields') readonlyFields?: ListsPerRequestType, 
        @Inject('DisabledFields') disabledFields?: ListsPerRequestType) {
        this.readonlyFields = readonlyFields;
        this.disabledFields = disabledFields;
    }

    get richTextFields(): RichTextField[] {
        return this._richTextFields;
    }

    set richTextFields(value: RichTextField[]) {
        this._richTextFields = value.filter((field: RichTextField) => field.fieldId === undefined || !this.isDisabled(field.fieldId));
        this.specificFields = this._richTextFields
            .filter((section: RichTextField) => section.fieldId !== undefined)
            .map((section: RichTextField) => section.fieldId as string);
    }

    public addSpecificField(fieldId: string): void {
        this.specificFields.push(fieldId);
    }

    set attachmentsField(value: AttachmentField) {
        this._attachmentsField = value;
    }

    get form(): FormGroup {
        return this._form || new FormGroup({});
    }

    set form(value: FormGroup) {
        this._form = value;
    }

    public getRichTextMetadata(fieldId: string): RichTextContentMetadata {
        if (!this.model || !this.model.hasOwnProperty(fieldId)) {
            return { versionId: undefined };
        }
        return this.model[fieldId];
    }

    public getAttachmentMetadata(): AttachmentMetadata[] {
        if (!this.model || (this.sectionId !== this.ATTACHMENTS_KEY && !this.model.hasOwnProperty(this.ATTACHMENTS_KEY))) {
            return [];
        }
        let modelData = this.sectionId === this.ATTACHMENTS_KEY ? this.model : this.model[this.ATTACHMENTS_KEY];
        const attachmentMetadata: AttachmentMetadata[] = modelData
            .map((attachment: AttachmentMetadata) => {
                return {
                    ...attachment,
                    isUploaded: true
                };
            });
        return attachmentMetadata;
    }

    public validate(): NpcSectionError[] {
        console.log(`Validating ${this.sectionId}`);
        this.setFormValidationErrors();
        return this.formValidationErrors;
    }

    public save(): void {
        console.log(`Saving ${this.sectionId}`);
        if (!this.form || this.formValidationErrors.length === 0) {
            this.reinitializeSaveResults();
            this._richTextFields.forEach((field: RichTextField) => {
                field.component.saveContent();
            });
            if (this._attachmentsField) {
                this._attachmentsField.component.saveAttachments();
            }
            if (this._richTextFields.length === 0 && !this._attachmentsField) {
                this.saveCompletion();
            }
        } else {
            this.modelUpdates.emit({
                sectionId: this.sectionId,
                model: undefined,
                errors: this.formValidationErrors
            });
            console.log(`The form ${this.sectionId} is not valid`);
        }
    }

    public onRichTextFieldSaveStatus(resp: RichTextContentStatus, fieldId?: string): void {
        const richTextField = this._richTextFields.find((field: RichTextField) => field.fieldId === fieldId);
        if (richTextField) {
            richTextField.saveResult = resp;
        } else {
            console.log(`Failed to find rich text field '${fieldId}' for section '${this.sectionId}'`);
        }
        if (resp.status === false) {
            this._richTextSaveErrors.push(resp);
            console.log(`Failed to save rich text field '${fieldId}' for section '${this.sectionId}'`);
        }
        this.saveCompletion();
    }

    public getRichTextContents(): void {
        this._richTextFieldGetStatus = [];
        if (this._richTextFields.length === 0) {
            this.getCompletion();
        }
        this._richTextFields.forEach((field: RichTextField) => {
            field.component.getContent(field.defaultTemplatePath);
        });
    }

    public onRichTextFieldGetStatus(resp: RichTextContentStatus, fieldId?: string): void {
        const richTextField = this._richTextFields.find((field: RichTextField) => field.fieldId === fieldId);
        if (richTextField) {
            this._richTextFieldGetStatus.push(resp);
            if (resp.error) {
                console.error(`Failed to get rich text field '${fieldId}' for section '${this.sectionId}': ${resp.error}`);
            }
        }
        this.getCompletion();
    }

    private getCompletion(): void {
        if (this._richTextFields.length === this._richTextFieldGetStatus.length) {
            this.richTextGetStatus.emit(this._richTextFieldGetStatus);
        }
    }

    public handleSaveAttachmentsResponse(response: SaveAttachmentsResponse): void {
        if (this._attachmentsField) {
            this._attachmentsField.saveResult = response;
        }
        this.saveCompletion();
    }

    private reinitializeSaveResults(): void {
        this._richTextSaveErrors = [];
        this._richTextFields.forEach((field: RichTextField) => {
            field.saveResult = undefined;
        });
        if (this._attachmentsField) {
            this._attachmentsField.saveResult = undefined;
        }
    }

    private allRichTextFieldsSaved(): boolean {
        return this._richTextFields.every((field: RichTextField) => field.saveResult !== undefined);
    }

    private allAttachmentFieldsSaved(): boolean {
        return this._attachmentsField ? this._attachmentsField.saveResult !== undefined : true;
    }

    private saveCompletion(): void {
        if (this.allRichTextFieldsSaved() && this.allAttachmentFieldsSaved()) {
            console.log(`Save completed for ${this.sectionId}`);
            this.modelUpdates.emit({
                sectionId: this.sectionId,
                model: this.getUpdatedModel(),
                errors: this.getRichTextFieldsErrors()
            });
        }
    }

    protected getUpdatedModel(): any {
        let newModel: any = {};
        if (this.form) {
            newModel = this.getFormValue();
        }
        this._richTextFields.forEach((field: RichTextField) => {
            if (field.saveResult?.status === true) {
                const newMetadata: RichTextContentMetadata = {
                    versionId: field.saveResult.metadata['versionId']    
                };
                if (field.fieldId) {
                    (newModel as any)[field.fieldId as string] = newMetadata;
                } else {
                    newModel = newMetadata;
                }
            }
        });
        if (this._attachmentsField && this._attachmentsField.saveResult?.isSuccessful === true) {
            const attachments = JSON.parse(JSON.stringify(this._attachmentsField.saveResult.attachments));
            for (const attachment of attachments) {
                delete attachment.isUploaded;
            }
            if (this.sectionId === this.ATTACHMENTS_KEY) {
                newModel = attachments;
            } else {
                newModel[this.ATTACHMENTS_KEY] = attachments;
            }
        }
        this.model = newModel;
        return this.model;
    }

    protected getFormValue(): any {
        return this.form.getRawValue(); 
    }

    protected setFormFromModel(): void {
        if (this.form !== undefined) {
            if (this.model) { 
                const formValue : any= {};
                for (const fieldName in this.model) {
                    if (!this.specificFields.includes(fieldName) && fieldName !== this.ATTACHMENTS_KEY) {
                        const value = this.getValueFromModel(fieldName);
                        formValue[fieldName] = value;
                    }
                }
                this.form.patchValue(formValue);
                this.form.markAllAsTouched();
            }
        }
    }

    protected getValueFromModel(fieldName: string): any {
        if (this.model && this.model.hasOwnProperty(fieldName)) {
            return (this.model as any)[fieldName];
        }
        return undefined;
    }

    private collectErrors(form: FormGroup, sectionId: string, parentField: string | undefined, errors: any[]): void {
        Object.keys(form.controls).forEach((field: string) => {
            const fullPathField: string = parentField ? `${parentField} / ${field}` : field;
            const control: AbstractControl = form.get(field) as AbstractControl;
            if (control instanceof FormGroup) {
                // recursive call for nested form groups
                this.collectErrors(control, sectionId, fullPathField, errors);
            } else {
                if (control.errors) {
                    Object.keys(control.errors).forEach(keyError => {
                        errors.push({
                            sectionId,
                            fieldId: fullPathField,
                            error: keyError
                        });
                    });
                }
            }
        });
    }

    private setFormValidationErrors(): void {
        this.formValidationErrors = [];
        if (this.form) {
            // we must temporarily enable the possible disabled fields otherwise they will be skipped from the validation
            this.activateReadOnlyFields(this.fields);

            this.collectErrors(this.form, this.sectionId, undefined, this.formValidationErrors);

            // we must re-activate the disabled fields
            this.applyReadOnlyFields(this.fields);
        }
        this._richTextFields.forEach((field: RichTextField) => {
            if (field.isMandatory) {
                const content: string | undefined = field.component?.editorData;
                if (!content || content.trim() === '') {
                    this.formValidationErrors.push({
                        sectionId: this.sectionId,
                        fieldId: field.fieldId,
                        error: 'field is mandatory'
                    });
                }
            }
        });
    }

    private getRichTextFieldsErrors(): NpcSectionError[] {
        const errors: NpcSectionError[] = [];
        this._richTextSaveErrors.map((error: RichTextContentStatus) => {
            const npcError: NpcSectionError = {
                sectionId: this.sectionId,
                fieldId: error.metadata.fieldId as string,
                error: `Failed to save rich text field (${getApiErrorMessage(error)})`
            };
        });
        return errors;
    }

    public openHelp(field: string): void {
        const relatedHelp: Help | undefined = this.help.find((help) => help.field === field);
        let title: string | undefined = undefined;
        let msg: string | undefined = undefined;
        if (relatedHelp) {
            msg = relatedHelp.help;
            title = relatedHelp.title;
        } else {
            const relatedHelpPerRequestType: HelpPerRequestType | undefined = this.helpPerRequestType.find((help) => help.field === field);
            if (relatedHelpPerRequestType) {
                msg = relatedHelpPerRequestType.help[this.requestType];
                title = relatedHelpPerRequestType.title;
            }
        }
        if (msg !== undefined){
            const dialogRef = this.dialog.open(DialogHelpComponent, { data: { title } });
            dialogRef.componentInstance.htmlContent = msg;
        }
    }

    public isReadOnly(fieldId: string): boolean {
        if (this.readOnlyMode) {
            return true;
        }
        if (this.actualReadonlyFields) {
            return this.actualReadonlyFields.includes(fieldId);
        }
        return false;
    }

    public isDisabled(fieldId: string): boolean {
        if (this.disabledFields) {
            return this.disabledFields[this.requestType].includes(fieldId);
        }
        return false;
    }

    private applyReadOnlyFields(fields: any, isReadOnly?: boolean): void {
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const field: AbstractControl = fields[key];
                const isReadOnlyFinal: boolean = isReadOnly !== undefined ? isReadOnly : this.isReadOnly(key);
                if (field instanceof FormControl) {
                    if (isReadOnlyFinal && field.enabled) {
                        field.disable({ emitEvent: false });
                        field.updateValueAndValidity({ emitEvent: false });
                        field.markAsUntouched();
                    }
                    if (!isReadOnlyFinal && field.disabled) {
                        field.enable({ emitEvent: false });
                        field.updateValueAndValidity({ emitEvent: false });
                    }
                } else if (field instanceof FormGroup) {
                    this.applyReadOnlyFields(field.controls, isReadOnlyFinal);
                }
            }
        }
    }

    private activateReadOnlyFields(fields: any, isReadOnly?: boolean): void {
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const field: AbstractControl = fields[key];
                const isReadOnlyFinal: boolean = isReadOnly !== undefined ? isReadOnly : this.isReadOnly(key);
                if (field instanceof FormControl) {
                    if (isReadOnlyFinal && field.disabled) {
                        field.enable({ emitEvent: false });
                        field.updateValueAndValidity({ emitEvent: false });
                        field.markAsUntouched();
                    }
                    if (!isReadOnlyFinal && field.enabled) {
                        field.disable({ emitEvent: false });
                        field.updateValueAndValidity({ emitEvent: false });
                    }
                } else if (field instanceof FormGroup) {
                    this.activateReadOnlyFields(field.controls, isReadOnlyFinal);
                }
            }
        }
    }

    private setFieldAsReadOnly(field: string, whitelist: string[]): void {
        if (this.readonlyFields && this.readonlyFields[this.requestType].includes(field)) {
            if (!this.actualReadonlyFields.includes(field)) {
                this.actualReadonlyFields.push(field);
            }
            return;
        }
        if (whitelist.includes('all') || whitelist.includes(field)) {
            if (this.actualReadonlyFields.includes(field)) {
                const index: number = this.actualReadonlyFields.indexOf(field);
                if (index !== -1) {
                    this.actualReadonlyFields.splice(index, 1);
                }
            }
        } else if (!this.actualReadonlyFields.includes(field)) {
            this.actualReadonlyFields.push(field);
        }
    }

    protected applyEditPermissions(): void {
        if (!this.isNew || this.requestType !== RequestType.NEW_REQUEST) {
            const whitelist: string[] = this.editPermissions && this.editPermissions.hasOwnProperty(this.sectionId) ? 
                this.editPermissions[this.sectionId] : [];
            Object.keys(this.fields).forEach((field: string) => {
                this.setFieldAsReadOnly(field, whitelist);
            });
            this.applyReadOnlyFields(this.fields);
            this.richTextFields.forEach((field: RichTextField) => {
                this.setFieldAsReadOnly(field.fieldId as string, whitelist);
            });
            if (this._attachmentsField) {
                this.setFieldAsReadOnly(this.ATTACHMENTS_KEY, whitelist);
            }
        }
    } 

    public isTechChange(): boolean {
        return this.requestType === RequestType.TECH_CHANGE;
    }

    protected unsubscribe(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            try {
              subscription.unsubscribe();
            } catch (error) {
              console.error(error);
            }
        });
    }

    public isNewRequest(): boolean {
        return this.requestType === RequestType.NEW_REQUEST;
    }

    public isTechnicalChange(): boolean {
        return this.requestType === RequestType.TECH_CHANGE;
    }

    public isExtension(): boolean {
        return this.requestType === RequestType.EXTENSION;
    }

}