import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './users-types';

const ADMIN_ROLE = 'npc_admin'; // TODO set as environment variable
const QUALIFIED_USER_CACHE_KEY = 'qualified_user';
const DEFAULT_CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 1 day in milliseconds

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiUrl = `${environment.apiBaseUrl}/users`;

  constructor(private http: HttpClient) { }

  listUsers(): Observable<User[]> {
    const url = this.apiUrl;
    return this.http.get<User[]>(url);
  }

  getUsersByEmail(emails: string[]): Observable<User[]> {
    return this.listUsers().pipe(
      map((users: User[]) => users.filter(user => emails.includes(user.email)))
    );
  }

  getUser(): Observable<User | undefined> {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      return of(undefined);
    }

    const publicClaim = JSON.parse(new TextDecoder().decode(new Uint8Array(
      Array.from(window.atob(authToken.split('.')[1]), c => c.charCodeAt(0))
    )));
    const email = publicClaim.userid;

    if (!email) {
      return of(undefined);
    }

    const cachedUser = JSON.parse(localStorage.getItem(QUALIFIED_USER_CACHE_KEY) || 'null');
    if (cachedUser && cachedUser.email === email) {
      return of(cachedUser);
    }

    const url: string = `${this.apiUrl}/${email}`;
    return this.http.get<User>(url)
      .pipe(
        map((user: User) => {
          localStorage.setItem(QUALIFIED_USER_CACHE_KEY, JSON.stringify(user));
          setTimeout(() => {
            localStorage.removeItem(QUALIFIED_USER_CACHE_KEY);
          }, DEFAULT_CACHE_EXPIRATION);
          return user;
        })
      );
  }

}