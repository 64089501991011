<mat-form-field [ngClass]="{'form-element-full-width': !basicStyling, 'form-element-basic': basicStyling}">
    <mat-label [ngClass]="{'users-invalid': !isValid}">{{title}}</mat-label>
    <mat-select [formControl]="selectedUsersControl" (selectionChange)="addUser($event.value)" 
      class="user-selector" [compareWith]="compareUsers">
      <mat-option *ngFor="let user of usersOptions" [value]="user">
        {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
      </mat-option>
    </mat-select>

  <div *ngIf="!singleSelection"
    [ngClass]="{'card-container-center': centeredDisplay, 'card-container-left': !centeredDisplay}">
    <mat-card *ngFor="let user of selectedUsers" (click)="removeUser(user)" fxLayoutWrap>
      <mat-card-header *ngIf="displayTitle">
        <mat-card-title>{{ user.firstName }} {{ user.lastName }}</mat-card-title>
        <button mat-icon-button (click)="removeUser(user)" *ngIf="!isReadOnly">
          <mat-icon>delete</mat-icon>
      </button>
      </mat-card-header>
      <mat-card-content>
        <span>{{ user.email }}</span>
        <button mat-icon-button (click)="removeUser(user)" *ngIf="!displayTitle && !isReadOnly">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</mat-form-field>
