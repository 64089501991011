export const Commodity: string[] = [
    'Power',
    'Natural Gas',
    'LNG',
    'Oil',
    'CO2',
    'Coal',
    'Cash',
    'Biogas',
    'Biomethane',
    'Cross-Commodity'
];