export const Profile: string[] = [
    "Not applicable",
    "Profile",
    "Base",
    "Paid-as-consumed",
    "Paid-as-nominated",
    "Take-or-pay",
    "Paid-as-produced",
    "Supply-or-pay",
    "Peak",
    "Off peak"
];