<form [formGroup]="form">
     <mat-form-field *ngIf="!isDisabled('generalApproach')" class="form-element-full-width">
        <mat-label>General approach, i.e. Mark-to-Market or Mark-to-Model</mat-label>
        <textarea matInput formControlName="generalApproach" placeholder="General approach">
        </textarea>
    </mat-form-field>
    <br>
     <mat-form-field *ngIf="!isDisabled('adjustments')" class="form-element-full-width">
        <mat-label>Valuation adjustments (e.g. missing time values) and their procedure (how to calculate, book and release them)
        </mat-label>
        <textarea matInput formControlName="adjustments" placeholder="Valuation adjustments">
        </textarea>
    </mat-form-field>
    <br>
     <mat-form-field *ngIf="!isDisabled('impactOnReporting')" class="form-element-full-width">
        <mat-label>Impact on desk and Value Chain management reporting and required adaptions</mat-label>
        <textarea matInput formControlName="impactOnReporting" placeholder="Impact on desk and D&C management">
        </textarea>
    </mat-form-field>
    <br>
     <mat-form-field *ngIf="!isDisabled('impactOnFXManagement')" class="form-element-full-width">
        <mat-label>Impact on FX management including cash pooling and required changes</mat-label>
        <textarea matInput formControlName="impactOnFXManagement" placeholder="Impact on FX management">
        </textarea>
    </mat-form-field>
    <br>
</form>