import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { NpcRequestService } from '../npc-request/npc-request.service';
import { NpcMetadata } from '../npc-request/model/npc-request';
import { Subscription } from 'rxjs';
import { AuthService } from '../authentication/auth.service';

export interface NPCRequest
{
    title?: string,
    details?: NPCRequestDetail
}

export interface NPCRequestDetail{
  approvalStatus?: string,
  approvalDate?: any,
  extra?: string,
}

@Component({
  selector: 'app-npc-request-list',
  templateUrl: './npc-request-list.component.html',
  styleUrls: ['./npc-request-list.component.css']
})
export class NpcRequestListComponent implements OnInit, OnDestroy {

  constructor(private npcRequestService: NpcRequestService, private router: Router, private authService: AuthService) { }
  tableDataSource = new MatTableDataSource<NpcMetadata>();
  subscriptions: Subscription[] = [];
  public canCreate: boolean = false;

  ngOnInit(): void {
    this.subscriptions.push(
      this.npcRequestService.listNpcRequests().subscribe({
        next: (npcRequests: NpcMetadata[]) => {
          this.tableDataSource.data = this.sortData(this.filterData(npcRequests));
        },
        error: (err: any) => console.log(err) // TODO: display error message
      })
    );

    // Check if user can create new NPC request
    this.subscriptions.push(
      this.authService.canCreateNpc().subscribe(canCreate => {
        this.canCreate = canCreate;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private filterData(data: NpcMetadata[]): NpcMetadata[] {
    // TODO: can be removed when DEV DB will be cleant up
    return data.filter((npc: NpcMetadata) => npc.productNumber);
  }

  private sortData(data: NpcMetadata[]): NpcMetadata[] {
    return data.sort((n1: NpcMetadata, n2: NpcMetadata) => {
      if (n1.productNumber > n2.productNumber) {
        return 1;
      }
    if (n1.productNumber < n2.productNumber) {
        return -1;
    }
    return 0;
    });
  }

  public viewRequest(npcRequest: NpcMetadata): void {
    this.router.navigate(['/npc-request'],
      { queryParams: { action: 'view', npcId: npcRequest.npcId, requestType: npcRequest.npcType } });
  }
}
