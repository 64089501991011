import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NpcRequestComponent } from './npc-request/npc-request.component';
import { ProductCatalogueComponent } from './product-catalogue/product-catalogue.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { NpcRequestListComponent } from './npc-request-list/npc-request-list.component';
import { ColumnConfigDialogComponent } from './column-config-dialog/column-config-dialog.component';
import { HtmlContentComponent } from './html-content/html-content.component';
import { AuthInterceptor } from './authentication/auth.interceptor';
import { LoginComponent } from './login/login.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { RichTextPageDemoComponent } from './rich-text-editor/rich-text-page-demo.component';
import { SafeHtmlPipe } from './rich-text-editor/safe-html';
import { AttachmentComponent } from './attachment/attachment.component';
import { AttachmentPageDemoComponent } from './attachment/attachment-page-demo.component';
import { GeneralInfoComponent } from './npc-request/general-info/general-info.component';
import { ConciseRequestComponent } from './npc-request/concise-request/concise-request.component';
import { ProductDescriptionComponent } from './npc-request/product-description/product-description.component';
import { ProductDescriptionTechnicalChangeComponent } 
  from './npc-request/product-description-technical-change/product-description-technical-change.component';
import { RiskComponent } from './npc-request/risk/risk.component';
import { ClassificationsComponent } from './npc-request/classifications/classifications.component';
import { OperationalResponsibilitiesComponent } from './npc-request/operational-responsibilities/operational-responsibilities.component';
import { ValuationComponent } from './npc-request/valuation/valuation.component';
import { DealEntryInstructionsComponent } from './npc-request/deal-entry-instructions/deal-entry-instructions.component';
import { SpecificRemarksComponent } from './npc-request/specific-remarks/specific-remarks.component';
import { DialogHelpComponent } from './dialog-help/dialog-help.component';
import { ProductTreeFilterComponent } from './product-catalogue/product-tree-filter/product-tree-filter.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ResponsibilitySelectorComponent } from './npc-request/operational-responsibilities/responsibility-selector/responsibility-selector.component';
import { StatusChangeDialogComponent } from './npc-request/workflow/status-change/status-change-dialog.component';
import { RequestApprovalDialogComponent } from './npc-request/workflow/request-approval/request-approval-dialog.component';
import { UserSelectorComponent } from './users/selector/users-selector.component';
import { CreateImplementationDialogComponent } from './npc-request/workflow/create-implementation/create-implementation-dialog.component';
import { UpdateImplementationDialogComponent } from './npc-request/workflow/update-implementation/update-implementation-dialog.component';
import { UsersListComponent } from './users/list/users-list.component';
import { ProductCheckerComponent } from './npc-request/workflow/check-product/check-product.component'
import { ProductViewerComponent } from './product-catalogue/product-viewer/product-viewer.component'
import { ProductDetailComponent } from './product-catalogue/product-detail/product-detail.component'
import { NpcAttachmentsComponent } from './npc-request/attachments/npc-attachments.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { ProductViewerDialogComponent } from './product-catalogue/product-viewer-dialog/product-viewer-dialog.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

@NgModule({
  declarations: [
    AppComponent,
    RichTextEditorComponent,
    RichTextPageDemoComponent,
    NpcRequestComponent,
    ProductCatalogueComponent,
    ProductViewComponent,
    NpcRequestListComponent,
    ColumnConfigDialogComponent,
    HtmlContentComponent,
    LoginComponent,
    SafeHtmlPipe,
    AttachmentComponent,
    AttachmentPageDemoComponent,
    GeneralInfoComponent,
    ConciseRequestComponent,
    ProductDescriptionComponent,
    RiskComponent,
    ClassificationsComponent,
    OperationalResponsibilitiesComponent,
    ValuationComponent,
    DealEntryInstructionsComponent,
    SpecificRemarksComponent,
    DialogHelpComponent,
    ProductTreeFilterComponent,
    ResponsibilitySelectorComponent,
    StatusChangeDialogComponent,
    RequestApprovalDialogComponent,
    UserSelectorComponent,
    CreateImplementationDialogComponent,
    UpdateImplementationDialogComponent,
    UsersListComponent,
    ProductCheckerComponent,
    ProductViewerComponent,
    NpcAttachmentsComponent,
    ProductDetailComponent,
    ProductDescriptionTechnicalChangeComponent,
    ProductViewerDialogComponent,
    SplashScreenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatDialogModule,
    MatSliderModule,
    MatSelectModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatToolbarModule,
    MatTreeModule,
    MatIconModule,
    MatChipsModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    CKEditorModule,
    MatGridListModule,
    MatProgressBarModule,
    DragDropModule,
    OverlayModule
  ],
  entryComponents: [DialogHelpComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
