import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RichTextEditorService } from './rich-text-editor.service';
import { RichTextContent, RichTextContentDownload, RichTextContentMetadata, RichTextContentMetadataExtended, 
  RichTextContentStatus } from './rich-text-editor.types';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { Subscription } from 'rxjs';

const Editor = require('ckeditor5-custom-build/build/ckeditor');

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.css']
})
export class RichTextEditorComponent implements OnDestroy {
  @Input() metadata!: RichTextContentMetadata;
  @Input() title!: string;
  @Input() isDisplayed = true;
  @Input() isRequired = false;
  @Input() help!: string;
  @Input() npcId!: string;
  @Input() sectionId!: string;
  @Input() fieldId!: string;
  @Input() isReadOnly: boolean = false;
  @Output() saveStatus = new EventEmitter<RichTextContentStatus>();
  @Output() getStatus = new EventEmitter<RichTextContentStatus>();

  public Editor: any = Editor;
  public editorData: string = '';
  private isContentModified = false;
  public isEditorReadOnly = true;
  public isValid: boolean = false;
  public subscriptions: Subscription[] = [];

  constructor(private richTextEditorService: RichTextEditorService, public dialog: MatDialog,
    private http: HttpClient) { }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public saveContent() {
    if (this.isContentModified === false) {
      const noSaveResponse: RichTextContentStatus = {
        metadata: {
          npcId: this.npcId,
          sectionId: this.sectionId,
          fieldId: this.fieldId,
          versionId: this.metadata.versionId
        },
        status: true,
        error: undefined
      };
      this.saveStatus.emit(noSaveResponse);
    } else {
      const richTextContent: RichTextContent = {
        metadata: {
          npcId: this.npcId,
          sectionId: this.sectionId,
          fieldId: this.fieldId,
          versionId: undefined
        },
        htmlContent: this.editorData
      };
      this.subscriptions.push(
        this.richTextEditorService.uploadRichText(richTextContent).subscribe(
          (response: RichTextContentStatus) => {
            if (response.status === true) {
              this.isContentModified = false;
            }
            this.saveStatus.emit(response);
          }
        )
      );
    }
  }

  public getContent(defaultTemplatePath?: string): void {
    if (this.metadata) {
      const metadata: RichTextContentMetadataExtended = {
        npcId: this.npcId,
        sectionId: this.sectionId,
        fieldId: this.fieldId,
        versionId: this.metadata.versionId
      }
      if (this.metadata.versionId) {
        this.subscriptions.push(
          this.richTextEditorService.downloadRichText(metadata).subscribe(
            (response: RichTextContentDownload) => {
              this.editorData = response.htmlContent;
              this.getStatus.emit({ metadata: response.metadata, status: response.error ? false : true, error: response.error });
              this.setIsValid();
            }
          )
        );
      } else {
        if (defaultTemplatePath) {
          console.log(`Setting default template for rich text editor '${this.fieldId}'`);
          this.http.get(defaultTemplatePath, { responseType: 'text' }).subscribe({
            next: data => {
              this.editorData = data;
            },
            error: error => {
              console.log(`Error while downloading local rich text template for field ${this.fieldId}`, error);
            }
        });
        }
        this.getStatus.emit({ metadata: metadata, status: true, error: undefined });
        this.setIsValid();
      }
    } else {
      console.log(`No metadata provided for rich text editor '${this.fieldId}'`);
    }
  }

  private setIsValid(): void {
    this.isValid = this.isRequired ? (this.editorData ? this.editorData.trim().length > 0 : false) : true;
  }

  public onChange(): void {
    if (this.isContentModified === false) {
      this.isContentModified = true;
    }
    this.setIsValid();
  }

  public goToReadOnlyMode(): void {
    this.isEditorReadOnly = true;
  }

  public goToEditMode(): void {
    this.isEditorReadOnly = false;
  }

  public openHelp(): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, { data: { title: this.title } });
    dialogRef.componentInstance.htmlContent = this.help;
  }
}

