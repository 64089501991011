import { Component, OnDestroy, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ProductListService } from './product-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';
import { tap, debounceTime, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import Fuse from 'fuse.js';

import { ColumnConfigDialogComponent } from '../column-config-dialog/column-config-dialog.component';
import { ProductUi, ProductsDownload } from './product';
import { ProductViewerDialogComponent } from './product-viewer-dialog/product-viewer-dialog.component';
import { NpcLinksService } from './npc-links.service';

export interface Filter {
  value: string;
}

@Component({
  selector: 'app-product-catalogue',
  templateUrl: './product-catalogue.component.html',
  styleUrls: ['./product-catalogue.component.css'],
})
export class ProductCatalogueComponent implements OnInit, OnDestroy {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  public searchForm: FormGroup = new FormGroup({
    search: new FormControl(''),
  });
  
  allProducts?: ProductUi[] = undefined;
  treeFilterResult: Map<string, Set<string>> = new Map;
  productGroups: Filter[] = [];
  selectedProductGroup?: string;
  products: Filter[] = [];
  selectedProduct?:Filter;
  commodities: Filter[] = [];
  selectedCommodity?:string;
  platforms: Filter[] = [];
  selectedPlatform?:Filter;
  markets: Filter[] = [];
  selectedMarket?:string;
  legalEntities: Filter[] = [];
  selectedLegalEntity?:Filter;
  selectedValueChain?:Filter;
  instruments: Filter[] = [];
  selectedInstrument?:Filter;
  settlements: Filter[] = [];
  selectedSettlement?:Filter;
  profiles: Filter[] = [];
  selectedProfile?:Filter;
  currencies: Filter[] = [];
  selectedCurrency?:Filter;
  units: Filter[] = [];
  selectedUnit?:Filter;
  tradedVia: Filter[] = [];
  mandates: Filter[] = [];
  selectedMandates?:Filter;
  selectedTradedVia?:Filter;
  isOpen = false
  isRefreshing = false;
  isLoading = true;
  isExporting = false;
  clearFilters?: Boolean;
  private subscriptions: Subscription[] = [];

  tableDataSource = new MatTableDataSource<ProductUi>();

  displayedColumns: string[] = ['productGroup', 'product', 'platform', 'commodity', 'settlement',
    'market', 'marketDetail', 'tenor', 'profile', 'currency', 'legalEntity', 'npcId'];

  displayedFilters: string[] = ['productGroup', 'product', 'commodity', 'platform', 'market', 'legalEntity', 
    'instrument', 'settlement', 'profile', 'currency', 'tradedVia', 'unit', 'mandates'];

  SEARCH_OPTIONS = {
    // isCaseSensitive: false,
    includeScore: true,
    // shouldSort: true,
    includeMatches: true,
    // findAllMatches: false,
    minMatchCharLength: 4,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    useExtendedSearch: true,
    ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: [
      'productGroup',
      'product',
      'instrument',
      'tradedVia',
      'platform',
      'commodity',
      'settlement',
      'market',
      'marketDetail',
      'tenor',
      'profile',
      'currency',
      'unit',
      'legalEntity',
      'optionMargingType',
      'exchangeProductCode',
      'exchangeProductName',
      'usedMxInstrument',
      'classification',
      'productId',
      'marketAccess',
      'buySell',
      'npcId',
      'status',
      'validity',
      'comment',
      'mandates'
    ]
  };

  constructor(private productListService: ProductListService, private npcLinks:NpcLinksService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.init();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private init(): void {
    this.subscriptions.push(
      this.productListService.getProducts()
        .pipe(
          //debounceTime(500),
          tap((download: ProductsDownload) => {
            const productsLength = (download.products === null) ? 0 : download.products.length
          })
        )
        .subscribe((download: ProductsDownload) => {
          this.allProducts = download.products;
          this.isLoading = false;
          this.isRefreshing = download.isOutdated;
          this.tableDataSource.data = download.products;

          this.filter();
          this.initFilters();
        })
    );
  }

  initFilters(): void {
    if (!this.allProducts) {
      return;
    }
    this.displayedFilters.forEach(filterName => {
      let options: string[] = [];
      if (filterName === 'mandates') {
        // product['mandates'] is an array of strings
        options = this.allProducts!.map((product: ProductUi) => product[filterName]).flat();
      } else {
        options = this.allProducts!.map((product: ProductUi) => product[filterName] as string);
      }
      const sortedOptions: string[] = Array.from(new Set(options)).sort();
      const filters: Filter[] = sortedOptions.map((item: string) => {
        return { value: item };
      });
      switch (filterName) {
        case 'productGroup':
          this.productGroups = filters;
          break;
        case 'product':
          this.products = filters;
          break;
        case 'commodity':
          this.commodities = filters;
          break;
        case 'platform':
          this.platforms = filters;
          break;
        case 'market':
          this.markets = filters;
          break;
        case 'legalEntity':
          this.legalEntities = filters;
          break;
        case 'instrument':
          this.instruments = filters;
          break;
        case 'settlement':
          this.settlements = filters;
          break;
        case 'profile':
          this.profiles = filters;
          break;
        case 'currency':
          this.currencies = filters;
          break;
        case 'unit':
          this.units = filters;
          break;
        case 'tradedVia':
          this.tradedVia = filters;
          break;
        case 'mandates':
          this.mandates = filters;
          break;
        default:
          break;
      }
    });
  }

  filterChanged(value:any): void {
    this.filter();
  }

  textSearch(event:any): void {
    this.filter();
  }

  treeFilterChanged(event: Map<string,Set<string>>): void {
    this.treeFilterResult = event;

    this.selectedProductGroup = undefined, 
    this.selectedCommodity = undefined,
    this.selectedMarket = undefined

    this.filter();
  }

  private getFilteredData(filterOptions: any): ProductUi[] {
    if (!this.allProducts) {
      return [];
    }
    let filteredProducts: ProductUi[] = this.allProducts
      .filter(item => {
        let include = true;
        if (filterOptions.treeFilterResult) {
          filterOptions.treeFilterResult.forEach((value: Set<string>, key: string) => {
            if (Array.isArray(item[key])) {
              const itemValues = item[key] as string[];
              if (value.size > 0 && itemValues.findIndex(itemValue => value.has(itemValue)) === -1) {
                include = false;
              }
            } else
            if (value.size > 0 && !value.has(item[key] as string)) {
              include = false;
            }
          })
        }
        for (const key in filterOptions) {
          if (key === 'search' || key === 'treeFilterResult') {
            continue;
          }
          if (filterOptions.hasOwnProperty(key)) {
            const value = filterOptions[key];
            if (value) {
              if (key === 'mandates') {
                // product['mandates'] is an array of strings
                const mandates = item[key] as string[] | undefined;
                if (Array.isArray(mandates) && mandates.findIndex(mandate => mandate === value) === -1) {
                  include = false;
                  break;
                }
              } else {
                if (item[key] !== value) {
                  include = false;
                  break;
                }
              }
            }
          }
        }
        return include;
      });

    if (filterOptions.search) {
      const productsToSearch: ProductUi[] = JSON.parse(JSON.stringify(filteredProducts));
      const fuse = new Fuse(productsToSearch, this.SEARCH_OPTIONS);
      filteredProducts = fuse.search(filterOptions.search).map(item => item['item']) as ProductUi[];
    }
    return filteredProducts;
  }

  filter(): void {
    const filteredOptions = {
      productGroup : this.selectedProductGroup,
      product: this.selectedProduct,
      commodity: this.selectedCommodity,
      platform: this.selectedPlatform,
      market: this.selectedMarket,
      legalEntity: this.selectedLegalEntity,
      instrument: this.selectedInstrument,
      settlement: this.selectedSettlement,
      profile: this.selectedProfile,
      currency: this.selectedCurrency,
      unit: this.selectedUnit,
      tradedVia: this.selectedTradedVia,
      mandates: this.selectedMandates,
      search: this.searchForm.get('search')?.value,
      treeFilterResult: this.treeFilterResult
    }

    this.tableDataSource.data = this.getFilteredData(filteredOptions);
  }

  hasFilter():boolean {
    const filteredOptions = {
      productGroup : this.selectedProductGroup,
      product: this.selectedProduct,
      commodity: this.selectedCommodity,
      platform: this.selectedPlatform,
      market: this.selectedMarket,
      legalEntity: this.selectedLegalEntity,
      instrument: this.selectedInstrument,
      settlement: this.selectedSettlement,
      profile: this.selectedProfile,
      currency: this.selectedCurrency,
      unit: this.selectedUnit,
      tradedVia: this.selectedTradedVia,
      search: this.searchForm.get('search')?.value,
      treeFilterResult: this.treeFilterResult,
      mandates: this.selectedMandates
    }

    return Object.values(filteredOptions).some(value => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      if (value instanceof Map){
        return value.size > 0;
      }
      return value != null; // Checks for both null and undefined
    });

  }

  cleanAllFilters() {
    this.selectedProductGroup = undefined,
    this.selectedProduct = undefined,
    this.selectedCommodity = undefined,
    this.selectedPlatform = undefined,
    this.selectedMarket = undefined,
    this.selectedLegalEntity = undefined,
    this.selectedInstrument = undefined,
    this.selectedSettlement = undefined,
    this.selectedProfile = undefined,
    this.selectedCurrency = undefined,
    this.selectedUnit = undefined,
    this.selectedTradedVia = undefined,
    this.treeFilterResult = new Map
    this.searchForm.get('search')?.setValue("")
    this.selectedMandates = undefined

    this.clearFilters = new Boolean(true); // triggers event clear filters in the child component 
  }

  expandProductView(row: any){
    this.dialog.open(ProductViewerDialogComponent, 
      { 
        data: row as ProductUi, 
        width: '70%', 
      }
    );
  }

  columns = [
    { label: 'productGroup', name: "Product Group", visible: new FormControl(true) },
    { label: 'product', name: "Product", visible: new FormControl(true) },
    { label: 'instrument', name: "Instrument", visible: new FormControl(false) },
    { label: 'tradedVia', name: "Traded Via", visible: new FormControl(true) },
    { label: 'platform', name: "Platform", visible: new FormControl(true) },
    { label: 'commodity', name: "Commodity", visible: new FormControl(true) },
    { label: 'settlement', name: "Settlement", visible: new FormControl(true) },
    { label: 'market', name: "Market", visible: new FormControl(true) },
    { label: 'marketDetail', name: "Market Detail", visible: new FormControl(true) },
    { label: 'tenor', name: "Tenor", visible: new FormControl(true) },
    { label: 'profile', name: "Profile", visible: new FormControl(true) },
    { label: 'currency', name: "Currency", visible: new FormControl(true) },
    { label: 'unit', name: "Unit", visible: new FormControl(true) },
    { label: 'legalEntity', name: "Legal entity", visible: new FormControl(true) },
    { label: 'optionMargingType', name: "Option Margin Type", visible: new FormControl(false) },
    { label: 'exchangeProductCode', name: "Exchange Product Code", visible: new FormControl(false) },
    { label: 'exchangeProductName', name: "Exchange Product Name", visible: new FormControl(false) },
    { label: 'usedMxInstrument', name: "Used MX Instrument", visible: new FormControl(false) },
    { label: 'classification', name: "Classification", visible: new FormControl(false) },
    { label: 'productId', name: "Product UID", visible: new FormControl(false) },
    { label: 'marketAccess', name: "Market Access", visible: new FormControl(false) },
    { label: 'buySell', name: "Buy/Sell", visible: new FormControl(false) },
    { label: 'npcId', name: "NPC", visible: new FormControl(true) },
    { label: 'status', name: "Status", visible: new FormControl(false) },
    { label: 'validityStartDate', name: "Validity Start Date", visible: new FormControl(false) },
    { label: 'validityEndDate', name: "Validity End Date", visible: new FormControl(false) },
    { label: 'comment', name: "Comment", visible: new FormControl(false) }

  ];

  openColumnConfigDialog(): void {
    const dialogRef = this.dialog.open(ColumnConfigDialogComponent, {
      width: '500px',
      data: { columns: this.columns }
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.columns = result;
          this.initDisplayedColumns();
          localStorage.setItem("productCatalogue.columns", JSON.stringify(this.displayedColumns))
        }
      })
    );
  }

  initDisplayedColumns(){
    this.displayedColumns = this.columns
          .filter(column => column.visible.value)
          .map(column => column.label);
  }

  get selectedProductGroups(): string[]{
      return Array.from(this.treeFilterResult?.get('productGroup') || []);
  }

  set selectedProductGroups(values: string[]) {
      if (this.treeFilterResult) {
          this.treeFilterResult.set('productGroup', new Set(values));
      }
  }

  get selectedProducts(): string[]{
    return Array.from(this.treeFilterResult?.get('product') || []);
  }

  set selectedProducts(values: string[]) {
      if (this.treeFilterResult) {
          this.treeFilterResult.set('product', new Set(values));
      }
  }

  get selectedMarkets(): string[]{
    return Array.from(this.treeFilterResult?.get('market') || []);
  }

  set selectedMarkets(values: string[]) {
      if (this.treeFilterResult) {
          this.treeFilterResult.set('market', new Set(values));
      }
  }
  
  get selectedCommodities(): string[] {
    return Array.from(this.treeFilterResult?.get('commodity') || []);
  }

  set selectedCommodities(values: string[]) {
      if (this.treeFilterResult) {
          this.treeFilterResult.set('commodity', new Set(values));
      }
  }

  extractLinks(npcIds: string):any[]{
    if (npcIds) {
      return this.npcLinks.getLinks(npcIds)
    }
    return []
  }

  public excelExport(): void {
    this.isExporting = true;
    this.productListService.excelExport().subscribe((url: string) => {
      this.isExporting = false;
      const link = document.createElement('a');
      link.href = url;  // Assign the response (download URL) to href
      const currDate: string = new Date().toISOString().split('T')[0];
      link.download = `alpiq_product_catalogue_${currDate}.xlsx`;
      link.click();
    });
  }

}
