import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface HelpDialogData {
  title: string;
}

@Component({
  selector: 'dialog-help',
  templateUrl: 'dialog-help.component.html',
})
export class DialogHelpComponent {
  @Input() content!: string;
  @Input() title!: string;
  public htmlContent!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: HelpDialogData, public dialogRef: MatDialogRef<DialogHelpComponent>) { }

  public close() {
    this.dialogRef.close();
  }
  
}