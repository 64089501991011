import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '../authentication/auth.service';

const TOKEN_PARAM = 'alpiqio_auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private route:ActivatedRoute, private authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const hasTokenParam: boolean = paramMap.has(TOKEN_PARAM);
      if (hasTokenParam) {
        const tokenParam: string = paramMap.get(TOKEN_PARAM) as string;
        this.authService.setToken(tokenParam); // setting Token as cookie
      }

      if (this.authService.hasValidToken()) {
        const url: string | null = localStorage.getItem('requested_url');
        if (url) {
          window.location.href = url;
        }
      }
    });
  }

}
