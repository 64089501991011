<form [formGroup]="form">
    <mat-form-field *ngIf="!isDisabled('generalRemarks')" class="general-remarks">
        <mat-label>General remarks</mat-label>
        <textarea matInput formControlName="generalRemarks" placeholder="Description" class="general-remarks-text">
        </textarea>
    </mat-form-field>
    <div formGroupName="marketRisk"*ngIf="!isDisabled('marketRisk')">
        <div>
            <mat-checkbox color="primary" formControlName="isPresent" (change)="changeRisk($event, 'marketRisk')"
            matTooltip="Price and volume risk">
                Market risks
            </mat-checkbox>
        </div>
        <mat-form-field class="form-element-full-width" *ngIf="isEnabled('marketRisk')">
            <textarea matInput formControlName="mitigation" placeholder="Description/Mitigation"></textarea>
        </mat-form-field>
    </div>
    <br>
    <div formGroupName="liquidityRisk" *ngIf="!isDisabled('liquidityRisk')">
        <div>
            <mat-checkbox color="primary" formControlName="isPresent" (change)="changeRisk($event, 'liquidityRisk')"
                matTooltip="e.g. inability to meet payment obligations, not being able to sell/buys asset quickly enough etc.">
                Liquidity risks
            </mat-checkbox>
        </div>
        <mat-form-field class="form-element-full-width" *ngIf="isEnabled('liquidityRisk')">
            <textarea matInput formControlName="mitigation" placeholder="Description/Mitigation"></textarea>
        </mat-form-field>
    </div>
    <br>
    <div formGroupName="creditRisk" *ngIf="!isDisabled('creditRisk')">
        <div>
            <mat-checkbox color="primary" formControlName="isPresent" (change)="changeRisk($event, 'creditRisk')"
                matTooltip="e.g. credit history, capacity to repay, capital, collateral etc.">
                Credit risks
            </mat-checkbox>
        </div>
        <mat-form-field class="form-element-full-width" *ngIf="isEnabled('creditRisk')">
            <textarea matInput formControlName="mitigation" placeholder="Description/Mitigation"></textarea>
        </mat-form-field>
    </div>
    <br>
    <div formGroupName="riskRequirementForProductSetup" *ngIf="!isDisabled('riskRequirementForProductSetup')">
        <div>
            <mat-checkbox color="primary" formControlName="isPresent" (change)="changeRisk($event, 'riskRequirementForProductSetup')"
                matTooltip="e.g. complex features affecting multiple areas of existing product(s) etc.">
                Risk requirements for product setup
            </mat-checkbox>
        </div>
        <mat-form-field class="form-element-full-width" *ngIf="isEnabled('riskRequirementForProductSetup')">
            <textarea matInput formControlName="mitigation" placeholder="Description/Mitigation"></textarea>
        </mat-form-field>
    </div>
    <br>
    <div formGroupName="otherRisks" *ngIf="!isDisabled('otherRisks')">
        <div>
            <mat-checkbox color="primary" formControlName="isPresent" (change)="changeRisk($event, 'otherRisks')"
                matTooltip="Operational and strategy risks (e.g. process, system, legal and compliance risks, reputational risk etc.)">
                Other risks
            </mat-checkbox>
        </div>
        <mat-form-field class="form-element-full-width" *ngIf="isEnabled('otherRisks')">
            <textarea matInput formControlName="mitigation" placeholder="Description/Mitigation"></textarea>
        </mat-form-field>
    </div>
</form>