import { Market } from "../market";

export const Markets: Market[] = [
    { market: 'API2 Rotterdam', marketDetail: [ 'API2 Rotterdam' ] },
    {
      market: 'API4 Richards Bay',
      marketDetail: [ 'API4 Richards Bay' ]
    },
    { market: 'API5 Newcastle', marketDetail: [ 'API5 Newcastle' ] },
    { market: 'Austria', marketDetail: [ 'Austria' ] },
    { market: 'Belgium', marketDetail: [ 'Belgium' ] },
    { market: 'Bosnia', marketDetail: [ 'Bosnia' ] },
    { market: 'Brent', marketDetail: [ 'Brent' ] },
    { market: 'Bulgaria', marketDetail: [ 'Bulgaria' ] },
    { market: 'CEGH', marketDetail: [ 'CEGH' ] },
    { market: 'Croatia', marketDetail: [ 'Croatia' ] },
    {
      market: 'Cross-Border',
      marketDetail: [
        "DE-AT",
        "AL<->GR",
        "AL<->ME",
        "AL<->XK",
        "AT<->CZ",
        "AT<->DE",
        "AT<->HU",
        "AT<->IT",
        "BA<->HR",
        "BA<->ME",
        "BA<->RS",
        "BG<->GR",
        "BG<->MK",
        "BG<->RO",
        "BG<->RS",
        "BG<->TR",
        "CH<->AT",
        "CH<->DE",
        "CH<->IT",
        "DK1<->DK2",
        "DK1<->NL",
        "EE -> LV",
        "ES<->PT",
        "FR<->BE",
        "FR<->CH",
        "FR<->DE",
        "FR<->ES",
        "FR<->IT",
        "GR<->MK",
        "GR<->TR",
        "HR<->RS",
        "HR<->SI",
        "HU<->RS",
        "HU<->UA",
        "IT<->GR",
        "IT<->ME",
        "IT<->SI",
        "ME<->RS",
        "ME<->XK",
        "MK<->RS",
        "MK<->XK",
        "NL<->BE",
        "NL<->DE",
        "RO<->SR",
        "RO<->UA",
        "AT<->SI",
        "CH<->FR",
        "CZ<->DE",
        "CZ<->PL",
        "CZ<->SK",
        "DE<->PL",
        "HR<->HU",
        "HU<->RO",
        "HU<->SI",
        "HU<->SK",
        "SK<->PL",
        "SK<->UA"
      ]
    },
    {
      market: 'Cross Pricezone',
      marketDetail: [
        "CALA <-> PUN",
        "CNOR <-> PUN",
        "CSUD <-> PUN",
        "NORD <-> PUN",
        "SARD <-> PUN",
        "SICI <-> PUN",
        "SUD <-> PUN"
      ]
    },
    { market: 'CZ VTP', marketDetail: [ 'CZ VTP' ] },
    { market: 'Czech', marketDetail: [ 'Czech' ] },
    { market: 'Denmark', marketDetail: [ 'DK1', 'DK2' ] },
    { market: 'Estonia', marketDetail: [ 'Estonia' ] },
    { market: 'EU', marketDetail: [ 'ETS' ] },
    { market: 'Finland', marketDetail: [ 'FI' ] },
    { market: 'France', marketDetail: [ 'France' ] },
    { market: 'Germany', marketDetail: [ 'Germany' ] },
    { market: 'Greece', marketDetail: [ 'Greece' ] },
    { market: 'Henry Hub', marketDetail: [ 'Henry Hub' ] },
    { market: 'Hungary', marketDetail: [ 'Hungary' ] },
    { market: 'Italy', marketDetail: [
        "CALA",
        "Italy",
        "NORD",
        "SUD",
        "PUN",
        "CNORD",
        "CSUD",
        "SARD",
        "SICI"
      ] 
    },
    { market: 'JKM', marketDetail: [ 'JKM' ] },
    { market: 'Latvia', marketDetail: [ 'Latvia' ] },
    { market: 'Lituania', marketDetail: [ 'Lituania' ] },
    { market: 'Montenegro', marketDetail: [ 'Montenegro' ] },
    { market: 'NBP', marketDetail: [ 'NBP' ] },
    { market: 'Netherlands', marketDetail: [ 'Netherlands' ] },
    { market: 'Nordics', marketDetail: [ 
      "Nordics",
      "SYS-DK1",
      "SYS-DK2",
      "SYS-FI",
      "SYS-NO1",
      "SYS-NO2",
      "SYS-NO3",
      "SYS-NO4",
      "SYS-NO5",
      "SYS-SE1",
      "SYS-SE2",
      "SYS-SE3",
      "SYS-SE4"
      ] 
    },
    { market: 'North Macedonia', marketDetail: [ 'North Macedonia' ] },
    {
      market: 'Norway',
      marketDetail: [ 'NO1', 'NO2', 'NO3', 'NO4', 'NO5' ]
    },
    { market: 'PEG', marketDetail: [ 'PEG' ] },
    { market: 'PL VTP', marketDetail: [ 'PL VTP' ] },
    { market: 'Poland', marketDetail: [ 'Poland' ] },
    { market: 'PSV', marketDetail: [ 'PSV' ] },
    { market: 'PVB', marketDetail: [ 'PVB' ] },
    { market: 'Romania', marketDetail: [ 'Romania' ] },
    { market: 'Serbia', marketDetail: [ 'Serbia' ] },
    { market: 'SI VTP', marketDetail: [ 'SI VTP' ] },
    { market: 'SK VTP', marketDetail: [ 'SK VTP' ] },
    { market: 'Slovakia', marketDetail: [ 'Slovakia' ] },
    { market: 'Slovenia', marketDetail: [ 'Slovenia' ] },
    { market: 'Spain', marketDetail: [ 'Spain' ] },
    { market: 'Sweden', marketDetail: [ 'SE1', 'SE2', 'SE3', 'SE4' ] },
    { market: 'Switzerland', marketDetail: [ 'Switzerland' ] },
    { market: 'SYS', marketDetail: [ 'SYS' ] },
    { market: 'THE', marketDetail: [ 'THE' ] },
    { market: 'TTF', marketDetail: [ 'TTF' ] },
    { market: 'UA VTP', marketDetail: [ 'UA VTP' ] },
    { market: 'United Kingdom', marketDetail: [ 'United Kingdom' ] },
    { market: 'ZTP', marketDetail: [ 'ZTP' ] }
];

