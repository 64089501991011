const emptyRequest: any = {
  "genericInfo": {
    "productName": null,
    "productOwner": null,
    "legalEntities": [],
    "markets": [],
    "applicantName": null,
    "coordinatedBy": []
  },
  "conciseOfRequest": {
    "conciseOfRequest": null,
    "situation": {}
  },
  "productDescription": {
    "productGroup": null,
    "product": null,
    "classification": null,
    "direction": null,
    "instrument": [],
    "optionMarginType": [],
    "commodity": null,
    "tradedVia": [],
    "platform": [],
    "settlement": [],
    "tenor": [],
    "profile": [],
    "currency": [],
    "unit": [],
    "exchangeProductCode": [],
    "exchangeProductName": [],
    "mxInstrument": "",
    "underlyingMxIndexes": [],
    "mdcIds": [],
    "productUid": "",
    "marketAccess": [],
    "usedPortfolios": "",
    "productDefinition": {},
    "marketPotential": {},
    "attachments": []
  },
  "valuation": {
    "generalApproach": null,
    "adjustments": null,
    "impactOnReporting": null,
    "impactOnFXManagement": null
  },
  "dealEntryInstructions": {
    "dealTicketPrefix": null,
    "murexInstruments": [],
    "instructions": {}
  },
  "specificRemarks": {},
  "risks": {
    "generalRemarks": null,
    "marketRisk": {
      "isPresent": false,
      "mitigation": []
    },
    "liquidityRisk": {
      "isPresent": false,
      "mitigation": []
    },
    "creditRisk": {
      "isPresent": false,
      "mitigation": []
    },
    "riskRequirementForProductSetup": {
      "isPresent": false,
      "mitigation": []
    },
    "otherRisks": {
      "isPresent": false,
      "mitigation": []
    }
  },
  "classifications": [
	  {
		"type": "TMB",
		"values": [
		  {
			"id": "A_NET_CASH",
			"description": "Practice of settling net in cash / intention to settle net in cash (no physical delivery)",
			"value": null
		  },
		  {
			"id": "B_FIN_INST",
			"description": "Practice of settling by another financial instrument / intention to settle by another financial instrument (no physical delivery)",
			"value": null
		  },
		  {
			"id": "C_ARBITRAG",
			"description": "Practice of generating a profit from short-term fluctuations in price (arbitrage)",
			"value": null
		  },
		  {
			"id": "D_WRITT_OPT",
			"description": "Written options which can be settled net in cash/through another financial instrument",
			"value": null
		  },
		  {
			"id": "E_OTHER",
			"description": "Other",
			"value": null
		  }
		]
	  },
	  {
		"type": "NTMB",
		"values": [
		  {
			"id": "A_OWN_USE",
			"description": "Own use/own production: intention to hold product until maturity for purpose of physical delivery",
			"value": null
		  },
		  {
			"id": "B_SPOT_MKT",
			"description": "Position on spot market (maturity < 1 month)",
			"value": null
		  },
		  {
			"id": "C_OTHER",
			"description": "Other",
			"value": null
		  }
		]
	  },
	  {
		"type": "MIFID",
		"values": [
		  {
			"id": "A_MIFID_REV",
			"description": "Is the product under review a MiFID-relevant financial instrument?",
			"value": null
		  },
		  {
			"id": "B_THIRD_PARTY",
			"description": "Is the counterparty to the transaction a third party customer (i.e. not intragroup)?",
			"value": null
		  },
		  {
			"id": "C_B2B_MIFID",
			"description": "In case Alpiq is hedging itself on the market (e.g. a market access service), is the back-to-back transaction MiFID-relevant?",
			"value": null
		  },
		  {
			"id": "D_MATCHED_PR",
			"description": "In case of back-to-back trading, does the overall transaction meet the criteria of matched principle trading?",
			"value": null
		  }
		]
	  }
	],
  "operationalResponsibilities": {
    "followStandardProcess": null,
    "confirmationNeeded": null,
    "newDealTicketPrefixNeeded": null,
    "remitReportNeeded": null,
    "roboReportNeeded": null,
    "responsibilities": []
  },
	"attachments": []
};
export {emptyRequest};