import { Component, OnInit } from "@angular/core";

@Component({
  selector: "splash-screen",
  templateUrl: "./splash-screen.component.html",
  styleUrls: ["./splash-screen.component.css"]
})
export class SplashScreenComponent implements OnInit {
    
  protected showSplash: boolean = true;
  protected startFadeOut: boolean = false;


  ngOnInit() {
    setTimeout(() => {
      this.startFadeOut = true;

      // Wait for animation to complete before removing the splash screen
      setTimeout(() => {
        this.showSplash = false;
      }, 1000); // This should match the duration of the fade-out animation
    }, 3000); // Time before starting the fade-out
  }


}