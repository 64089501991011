import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { NpcRequestService } from "../../npc-request.service";
import { Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { getApiErrorMessage } from '../../../utils/api-error';
import { JIRATasks } from "../../model/npc-request";
import { Subscription } from "rxjs";

export function projectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value && value.length >= 2 && /^[A-Z0-9]+$/.test(value)) {
      return null;
    } else {
      return { 'invalidProject': { message: 'JIRA Project key must be at least 4 characters long and in uppercase' } };
    }
  };
}

@Component({
  selector: 'create-implementation-dialog',
  templateUrl: 'create-implementation-dialog.component.html',
  styleUrls: ['./create-implementation-dialog.component.css']
})
export class CreateImplementationDialogComponent implements OnInit, OnDestroy {
  public npcId?: string;
  public productNumber?: string;
  public productName?: string;
  public error?: string;
  public projects: string[] = [];
  public jiraTasks?: JIRATasks;
  public form: FormGroup;
  public statusMessage?: string;
  public isSaving: boolean = false;
  private subscriptions: Subscription[] = [];
 
  constructor(private npcRequestService: NpcRequestService, private router: Router, private route: ActivatedRoute) {
    this.form = new FormGroup({
      project: new FormControl(null, [Validators.required, projectValidator()])
    }); 
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params => {
        this.npcId = params['npcId'];
        this.productNumber = params['productNumber'];
        this.productName = params['productName'];
        const holderTask: string | undefined = params['holderTask'];
        let subTasks: string[] = [];
        if (params['subTasks']) {
          subTasks = JSON.parse(params['subTasks']) || [];
        }
        if (holderTask) {
          // JIRA implementation is already defined
          this.jiraTasks = { holderTask, subTasks };
          this.changeStatusOnSuccess();
        }
      }))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public removeProject(project: string): void {
    // Remove the project from the list of projects
    const index = this.projects.indexOf(project);
    if (index >= 0) {
      this.projects.splice(index, 1);
    }
  }

  public addProject(project: string) {
    if (this.form.valid) {
      this.projects.push(project);
      this.form.reset();
    }
  }

  private reroute(): void {
    this.router.navigate(['/npc-request'],
      { queryParams: { action: 'view', npcId: this.npcId } });
  }

  public cancel() {
    this.reroute();
  }

  private changeStatusOnSuccess(): void {
    // change status to "estimation_collection"
    this.subscriptions.push(
      this.npcRequestService.changeStatus(this.npcId!, 'estimation_collection').subscribe({
        next: () => {
          console.log("Status changed successfully to estimation_collection");
          this.statusMessage = "The status of the request has been changed to 'Estimation Collection'";
          this.isSaving = false;
        },
        error: (error: any) => {
          console.error("Status change failed", error);
          this.error = getApiErrorMessage(error);
          this.isSaving = false;
        }
      })
    );
  }

  public confirm() {
    this.isSaving = true;
    this.subscriptions.push(
      this.npcRequestService.createJiraImplementationTask(this.npcId!, this.projects).subscribe({
        next: (jiraTasks: JIRATasks) => {
          console.log(`JIRA Implementation was successfully created: ${JSON.stringify(jiraTasks)}`);
          this.jiraTasks = jiraTasks;
          this.changeStatusOnSuccess();
        },
        error: (error: any) => {
          console.error("Failed to create JIRA implementation tasks", error);
          this.error = getApiErrorMessage(error);
          this.isSaving = false;
        }
      })
    );
  }

  public close() {
    this.reroute();
  }

}