import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

/**
 * This pipe is used to display HTML content in the rich text editor. If you don't use this pipe, 
 * the HTML inner content of rich text in read-only mode would not have any inner styling applied, because of Angular encapsulation.
 */

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}

  transform(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}