import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { AttachmentComponent } from 'src/app/attachment/attachment.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-npc-attachments',
  templateUrl: './npc-attachments.component.html',
  styleUrls: ['./npc-attachments.component.css']
})
export class NpcAttachmentsComponent extends NpcRequestSectionBase implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('attachments') attachmentComponent!: AttachmentComponent;

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    this.fields = {};
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  ngAfterViewInit(): void {
    this.attachmentsField = { component: this.attachmentComponent }
    this.applyEditPermissions();
  }

}
