import { Injectable, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NpcLinksService {

  constructor(private sanitizer: DomSanitizer) { }

  getLinks(identifier: string): any {
    let links : any[] = [];
    let identifiers = identifier.replace(/,/g, ';').split(';').map(id => id.trim());

    identifiers.forEach(id => {
      let found = this.HISTORICAL_LINKS.find(item => item.name === id);
      if (found && found.paths.length > 0) {
        // Assuming each 'name' has a single URL for now
        let link = {
          name: id,
          url: this.sanitizer.sanitize(SecurityContext.URL, found.paths[0])
        };
        links.push(link);
      }
    });

    return links;
  }


  HISTORICAL_LINKS =[
      {
          "name": "2018_35",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_35%20ICE%20Endex/NPC-Request%20Alpiq%20ICE%20Endex%2020181221.pdf"
          ]
      },
      {
          "name": "2018_35b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_35%20ICE%20Endex/NPC-Request%20Alpiq%20ICE%20Endex%20Phase%20II%20%2020190307.pdf"
          ]
      },
      {
          "name": "2018_35c",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_35%20ICE%20Endex/NPC-Request%20Alpiq%20ICE%20Endex%20Phase%20III%20%2020190409.pdf"
          ]
      },
      {
          "name": "2021_41",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/NPC%20-%20Withdrawn/2021/2021_41%20ICE%20Dutch%20TTF%20Gas%201st%20Line%20Futures/2021_41%20Ext_ICE%20Dutch%20TTF%20Gas%201st%20line%20futures_FINAL.pdf"
          ]
      },
      {
          "name": "2020_14",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_14%20ICE%20Henry%20Hub%20financial/NPC-Request%20Alpiq%20ICE%20Henry%20Hub%2020210321.pdf"
          ]
      },
      {
          "name": "2022_16",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_16%20Extension%20ICE%20Endex%20French%20PEG%20daily%20Natural%20Gas%20Futures/2022_16_Extension%20NG%20Futures,%20daily,PEG_FINAL.pdf"
          ]
      },
      {
          "name": "2022_17",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_17%20Extension%20ICE%20Endex%20French%20PEG%20Natural%20Gas%20Futures/2022_17_Extension%20NG%20Futures,%20PEG_Final.pdf"
          ]
      },
      {
          "name": "2022_22",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_22%20Extension%20ICE%20Endex%20German%20THE%20natural%20gas%20daily%20futures/2022_22%20Extension%20ICE%20Endex%20German%20THE%20natural%20gas%20daily%20futures_FINAL.pdf"
          ]
      },
      {
          "name": "2015_18",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_18%20GAS%20UK%20NBP/NPC-Request%20GAS%20UK%20NBP%2020151210.pdf"
          ]
      },
      {
          "name": "2022_03",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_03%20ICE%20Endex%20Austrian%20CEGH%20VTP%20Gas%20Futures/2022_03%20ICE%20Endex%20Austrian%20CEGH%20VTP%20Gas%20Futures_final.pdf"
          ]
      },
      {
          "name": "2021_06",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_06%20Newcastle%20Coal%20Futures/NPC-Request%20Newcastle%20Coal%20Futures%2020210527.pdf"
          ]
      },
      {
          "name": "2021_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_12%20API4/2021_12%20Argus%20McCloskey%20API4%20Richards%20Bay%20Coal%20Futures%20-%20Financial.pdf"
          ]
      },
      {
          "name": "2016_20",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_20%20Coal%20in%20CEE/20160523_NPC_Coal.pdf"
          ]
      },
      {
          "name": "2021_36",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2021/2021_36%20MEFF%20power%20Futures%20-%20financial%20Settlement/2021_36%20MEFF%20Power%20Products%20-%20financial%20Futures_Final.pdf"
          ]
      },
      {
          "name": "2022_19",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_19%20Extension%20MEFF%20-%20Swaps/2022_19%20Extension_MEFF%20Swaps_FINAL.pdf"
          ]
      },
      {
          "name": "2018_22",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_22%20EEX%20Austria%20power%20futures/NPC-Request%20EEXAustria%20power%20futures%2020180724.pdf"
          ]
      },
      {
          "name": "2015_19",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_19%20EEX%20Italy/EEX%20Italy%20power%20futures%20products%20%2020150601.pdf"
          ]
      },
      {
          "name": "2016_27",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_27%20EEX%20Italien%20extension%20peak%20daily%20%26%20weekend/NPC-Request%20EEX%20Italy%20power%20futures%20%E2%80%93%20extension%20daily%20%26%20weekend%20peak%2020160811.pdf"
          ]
      },
      {
          "name": "2015_09",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_09%20Power%20spain%20reactivation%20-%20Phase%203%20%20Futures/NPC-Request%20Power%20spain%20reactivation%20-%20Phase%203%20-%20Futures%20(EEX)%20%2020150601.pdf"
          ]
      },
      {
          "name": "2019_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_12%20EXAA%20DE%20AT%20spread/NPC-Request%20Alpiq%20EXAA%20DE%20AT%20spread%20201900506.pdf"
          ]
      },
      {
          "name": "2017_21",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_21%20EEX%20Germany%20power%20futures-%20-%20extension%20weeks,%20days%20and%20weekends/NPC-Request%20EEXGermany%20power%20futures%20-%20extension%20week%20day%20and%20weekend%2020170809.pdf"
          ]
      },
      {
          "name": "2017_09",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_09%20EEX%20Germany%20Futures/NPC-Request%20EEXGermany%20power%20futures%2020170519.pdf"
          ]
      },
      {
          "name": "2016_29",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_29%20EEX%20France%20extension%20peak%20daily%20%26%20weekend/NPC-Request%20EEX%20France%20power%20futures%20%E2%80%93%20extension%20daily%20%26%20weekend%20peak%2020160816.pdf"
          ]
      },
      {
          "name": "2015_23",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_23%20EEX%20France/EEX%20France%20power%20futures%20products%20%2020150630.pdf"
          ]
      },
      {
          "name": "2018_40",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_40%20EEX%20Netherland/NPC-Request%20Alpiq%20EEX%20Dutch%20%20financial%2020181213.pdf"
          ]
      },
      {
          "name": "2023_16",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2023/2023_16%20Ext_%20EEX%20Swiss%20Peak%20Futures/2023_16%20Ext_%20EEX%20Swiss%20Peak%20Futures_FINAL_Approved.pdf"
          ]
      },
      {
          "name": "2017_04",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_04%20EEX%20Swiss%20extension%20day%20and%20weekend%20base/NPC-Request%20EEX%20Swiss%20power%20futures%20-%20extension%20day%20and%20weekend%20base%2020170210.pdf"
          ]
      },
      {
          "name": "2016_30",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_30%20EEX%20Swiss%20extension%20base%20week/NPC-Request%20EEX%20Swiss%20power%20futures%20%E2%80%93%20extension%20week%20base%2020160816.pdf"
          ]
      },
      {
          "name": "2015_27",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_27%20EEX%20Swiss%20Futures/NPC-Request%20EEX%20Swiss%20power%20futures%20products%20%2020151006.pdf"
          ]
      },
      {
          "name": "2020_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_15%20EEX%20nordic%20futures/NPC-Request%20Alpiq%20Nordic%20futures%2020200528.pdf"
          ]
      },
      {
          "name": "2018_39",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_39%20EEX%20Belgien/NPC-Request%20Alpiq%20EEX%20Belgium%20%20financial%2020181213.pdf"
          ]
      },
      {
          "name": "2015_34",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_34%20PEGAS-EEX%20Gas%20Futures/NPC-Request%20PEGAS-EEX%20Gas%20Futures%2020151027.pdf"
          ]
      },
      {
          "name": "2018_05",
          "paths": [
              
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_05%20PVB%20Physically%20delivered%20listed%20Futures/NPC-Request%20PVB%20futures%20OMIP%2020180321.pdf"
          ]
      },
      {   
          "name": "2018_05b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_05%20PVB%20Physically%20delivered%20listed%20Futures/NPC-Request%20PVB%20futures%20Mibgas%2020180504.pdf"
          ]
      },
      {
          "name": "2015_25",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_25%20Omip/NPC-Request%20OMIP%20power%20futures%20product%20%2020151013.pdf"
          ]
      },
      {
          "name": "2016_45",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_45%20OMIP%20peak%20power%20futures/NPC-Request%20OMIP%20peak%20power%20futures%20201601010.pdf"
          ]
      },
      {
          "name": "2016_46",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_46%20Nordic%20Futures%20on%20Nasdaq/NPC%20Nordic%20Futures%20on%20Nasdaq%2020161117%20-%20Phase1.pdf"
          ]
      },
      {
          "name": "2016_46b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_46%20Nordic%20Futures%20on%20Nasdaq/NPC%20Nordic%20Futures%20on%20Nasdaq%2020161124%20-%20Phase2.pdf"
          ]
      },
      {
          "name": "2021_44",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2021/2021_44%20Nordic%20Power%20-%20Extension%20to%20new%20Pricearea%20NO2%20and%20NO5/2021_44%20Nordic%20Power%20-%20Extension%20new%20Pricearea%20NO2%20and%20NO5_FINAL.pdf"
          ]
      },
      {
          "name": "2015_14",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/2015_14%20Nordpool%20reactivation%20Phase%202/NPC-Request%20Alpiq%20Nordic%20Phase%202%20(ELCERT%20-%20extension)%2020150930.pdf"
          ]
      },
      {
          "name": "2015_14b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/2015_14%20Nordpool%20reactivation%20Phase%202/NPC-Request%20Alpiq%20Nordic%20Phase%202%20(EPAD%20-%20extension)%2020151009.pdf"
          ]
      },
      {
          "name": "2015_14c",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/2015_14%20Nordpool%20reactivation%20Phase%202/NPC-Request%20Alpiq%20Nordic%20Phase%202%20(EPAD%20-%20further%20extension%20SE1,SE2,NO3)%2020151020.pdf"
          ]
      },
      {
          "name": "2015_14d",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/2015_14%20Nordpool%20reactivation%20Phase%202/NPC-Request%20Alpiq%20Nordic%20Phase%202%20(OTC%20option)%2020151130.pdf"
          ]
      },
      {
          "name": "2016_40",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_40%20ICE%20CO2/NPC-Request%20Alpiq%20ICE%20CO2%2020161220.pdf"
          ]
      },
      {
          "name": "2021_28",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_28%20EEX%20CO2%20Future/2021_28%20EEX%20CO2%20Future.pdf"
          ]
      },
      {
          "name": "2017_19",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_19%20Nasdaq%20listed%20option%20with%20future%20underlying/NPC-Request%20Alpiq%20Nasdaq%20listed%20option%20with%20future%20underlying%2020171002.pdf"
          ]
      },
      {
          "name": "2016_01b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_01b%20Gas%20Options%20%E2%80%93%20Phase%202%20%E2%80%93%20extension%20%20ICE%20NPB/NPC-Request%20-Gas%20options%20%E2%80%93%20Phase%202%20%E2%80%93%20extension%20ICE%20UK%20Natural%20Gas%20Futures%2020160824.pdf"
          ]
      },
      {
          "name": "2019_05",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_05%20Brent%20Crude%20American-style%20Options/NPC%20requestBrent%20Crude%20American-style%20Options%2020190807.pdf"
          ]
      },
      {
          "name": "2021_22",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_22%20ICE%20TTF%20Gas%20Options%20Future%20Style/2021_22%20ICE%20Dutch%20TTF%20Gas%20Options%20Future%20Style.pdf"
          ]
      },
      {
          "name": "2018_32",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_32%20ICE%20EUA%20Standard%20Options/NPC%20request%20EUA%20ICE%20Stand%20Options%2020190214.pdf"
          ]
      },
      {
          "name": "2019_04",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_04%20API2%20Coal%20Options/NPC%20request%20API2%20Coal%20Options%2020190709.pdf"
          ]
      },
      {
          "name": "2018_38",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_38%20EEX%20Phelix-DE%20Options/NPC%20request%20EEX%20Phelix-DE%20Options%2020190507.pdf"
          ]
      },
      {
          "name": "2023_01",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2023/2023_01%20Extension%20EEX%20Options/2023_01%20Extension%20EEX%20Options_FINAL_Approved.pdf"
          ]
      },
      {
          "name": "2014_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2014/Fertig/2014_15%20RES/NPC-Request%20%20RES%2020141212.pdf"
          ]
      },
      {
          "name": "2017_08",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_08%20TTF%20Day-Ahead%20Heren%20Index%20Financial%20Swap/NPC-Request%20TTF%20Day-Ahead%20Heren%20Index%20Financial%20Swap%20201700411.pdf"
          ]
      },
      {
          "name": "2017_08b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_08%20RES%20France/NPC-Request%20%20RES%20France%2020171010.pdf"
          ]
      },
      {
          "name": "2019_32",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2019/2019_32%20Capacity%20market%20Italy/2019_32%20Capacity%20Market%20Italy_FINAL_signed_UpdInstr.pdf"
          ]
      },
      {
          "name": "2017_33",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_33%20JKM%20swap/NPC-Request%20Alpiq%20JKM%20swap.pdf"
          ]
      },
      {
          "name": "2012_17",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/2012_17%20Nordic%20Financial%20Products/20131015%20-Nordic%20Financial%20Products.pdf"
          ]
      },
      {
          "name": "2012_18",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/Fertige%20NPC%27s/2012_18%20Swing%20Option/20121204%20-%20NPC%20-%20Swing%20Option.pdf"
          ]
      },
      {
          "name": "2016_28",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_28%20VPP%20Spain%20%26%20Italy/NPC-Request%20VPP%20Italy%20%20Spain%2020160817.pdf"
          ]
      },
      {
          "name": "2012_16",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/Fertige%20NPC%27s/2012_16%20Virtual%20Hydro%20Plant/20121204%20-%20NPC%20-%20Virtual%20Hydro%20Plant.pdf"
          ]
      },
      {
          "name": "2015_20",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_20%20Extension%20Phase%204%20-%20non%20standard%20expiry%20date/NPC-Request%20Standard%20options%20-%20Phase%204%20-%20non%20standard%20expiry%20date%20%2020160317.pdf"
          ]
      },
      {
          "name": "2015_20b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_20%20Options%20extension/NPC-Request%20Standard%20options%20-%20Phase%203%20(physical%20-%20Swiss,%20Germany,%20French)%2020151023.pdf"
          ]
      },
      {
          "name": "12_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/Fertige%20NPC%27s/2012_15%20GoO%20Gas%20and%20Nuclear/20130326%20-%20NPC%20-%20GoO%20Gas%20and%20Nuclear.pdf"
          ]
      },
      {
          "name": "2019_14",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_14%20Multi-click%20power%20contracts%20CH/NPC-Request%20multi-click%20contract%20Power%20CH%2020190514.pdf"
          ]
      },
      {
          "name": "2011_08",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2011/Fertige%20NPC%27s/2011_08%20Green%20Certificates/NPC%20Request/20120229%20-%20NPC%20-%20Green%20Certificates.pdf"
          ]
      },
      {
          "name": "2017_16",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_16%20Biogas%20Zertifikate/NPC-Request%20Alpiq%20Biogas%20Zertifikate%2020171206.pdf"
          ]
      },
      {
          "name": "2017_03",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_03%20SRL-Pooling/NPC-Request%20Alpiq%20SRL-Pooling%20%2020170320.pdf"
          ]
      },
      {
          "name": "2018_21",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_21%20Continuous%20trading%20day-ahead%20%20intraday%20on%20OMIE%20for%20Spain/NPC-Request%20Alpiq%20Continuous%20day%20ahead%20and%20intraday%20trading%20Spain%20on%20OMIE%2020180613.pdf"
          ]
      },
      {
          "name": "2018_21b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_21%20Strip%20of%20Daily%20European%20OTC%20physical%20power%20Options/NPC-Request%20Alpiq%20DailyOptions_phys%2020181023.pdf"
          ]
      },
      {
          "name": "2020_16",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_16%20Asien%20option%20germany/NPC-Request%20-%20Germany%20asian%20options%20%2020201103.pdf"
          ]
      },
      {
          "name": "2021_03",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_03%20Guarantees%20of%20Origin%20unsubsidized%20RES%20Germany/NPC-Request%20GoO%20%20Germany%2020210209.pdf"
          ]
      },
      {
          "name": "2019_22",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_24%20LNG%20Products%20in%20MIBGas%20Derivatives/2019_22%20RES%20PPA%20Germany/NPC-Request%20Alpiq%20RES%20PPA%20Germany%2020190813.pdf"
          ]
      },
      {
          "name": "2019_40",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_40%20%20RES%20PPA%20Germany%20SellSide/NPC-Request%20Alpiq%20RES%20PPA%20Germany%20SellSide%2020200114.pdf"
          ]
      },
      {
          "name": "2019_31",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_31%20Full%20Supply%20Industrial%20Clients%20Germany/NPC-Request%20Alpiq_Full%20Supply%20Industrial%20Clients%20GER%2020191203.pdf"
          ]
      },
      {
          "name": "2017_26",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_26%20Fix-Floating%20financial%20Swap%20(France,%20Germany,%20Austria,%20Switzerland)/NPC-Request%20Fix-Floating%20financial%20Swap%20(France,%20Germany,%20Austria,%20Switzerland)%20%2020171207.pdf"
          ]
      },
      {
          "name": "2016_01",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_01%20OTC-Gas%20option%20-%20Phase%201/NPC-Request%20OTC-Gas%20options%20-%20Phase%201%2020160910.pdf"
          ]
      },
      {
          "name": "2016_01c",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_01%20OTC-Gas%20option%20-%20Phase%201/NPC-Request%20OTC-Gas%20options%20-%20Phase%201%2020160910.pdf"
          ]
      },
      {
          "name": "2016_01b",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_01b%20Gas%20Options%20%E2%80%93%20Phase%202%20%E2%80%93%20extension%20%20ICE%20NPB/NPC-Request%20-Gas%20options%20%E2%80%93%20Phase%202%20%E2%80%93%20extension%20ICE%20UK%20Natural%20Gas%20Futures%2020160824.pdf"
          ]
      },
      {
          "name": "2016_41",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_41%20Cross%20border%20auctions/NPC-Request%20Alpiq%20Cross%20border%20auctions%2020161213.pdf"
          ]
      },
      {
          "name": "13_12",
          "paths": [
              "https://alpiq0.sharepoint.com/:b:/s/msteams_54035b/EXkJU8SpywpLpUCFDr3mjYsB-K16LzGbJaomSDuz-84Evw?email=zoltan.godo%40alpiq.com&e=DOnJ4g"
          ]
      },
      {
          "name": "2017_24",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_24%20Virtual%20transport/NPC-Request%20GAS%20-%20Virtual%20Transport%2020170817.pdf"
          ]
      },
      {
          "name": "2015_08",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_08%20Virtual%20Gas%20Storage%20Contract%20NCG/NPC-Request%20Alpiq%20Virtual%20Gas%20Storage_20150414.pdf"
          ]
      },
      {
          "name": "12_02",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/Fertige%20NPC%27s/2012_02%20Gas%20Storage%20France/20130122%20-%20NPC%20-%20Gas%20Storage%20France.pdf"
          ]
      },
      {
          "name": "2018_06",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_06%20structured%20products/NPC-Request%20Alpiq%20structured%20products%2020180404.pdf"
          ]
      },
      {
          "name": "2017_11",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_11%20OTC-Gas%20option%20-%20Phase%20II%20-%20PSV/NPC-Request%20OTC-Gas%20options%20-%20Phase%202%2020170919.pdf"
          ]
      },
      {
          "name": "2021_16",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_16%20Average%20rate%20Option%20(Asian%20option)%20%E2%80%93%20OTC%20-%20France,%20Italy%20and%20Spain%20(gas)/scan_20210527104453.pdf"
          ]
      },
      {
          "name": "2023_04",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2023/2023_04%20Demand%20Active%20Response/2023_04%20Demand%20Active%20Response_FINAL_Approved.pdf"
          ]
      },
      {
          "name": "2021_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_15%20Average%20rate%20Option%20(Asian%20option)%20%E2%80%93%20OTC%20-%20France,%20Italy%20and%20Spain%20(electricity)/NPC-Request%20-%20(Electricity)%20Asian%20Options%20IT-ES-FR%202021_05_28.pdf"
          ]
      },
      {
          "name": "2015_01",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_01%20%20Power%20spain%20reactivation%20Phase%201%20OTC%20Forward%20(financial)/NPC-Request%20%20Power%20spain%20reactivation%20Phase%201%20OTC%20Forward%20(financial)%20%2020150218.pdf"
          ]
      },
      {
          "name": "2022_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_15%20Extension%20Gas%20forwards%20financial%20Spain/2022_15%20Gas%20Forwards%20financial%20Spain_Extension_FINAL.pdf"
          ]
      },
      {
          "name": "2021_38",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2021/2021_38%20Standard%20Options%20_Extension%20ESP%20and%20IT/2021_38%20NPC-Request%20Standard%20Options%20IT%20ESP%20Extension_FINAL.pdf"
          ]
      },
      {
          "name": "2023_13",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2023/2023_13%20Standard%20Options%20Financial%20Spain/2023_13_Standard_Options_Financial_Spain_FINAL_Approved.pdf"
          ]
      },
      {
          "name": "2016_35",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/2016_35%20Retail%20Spain/NPC-Request%20Retail%20Spain%2020170111.pdf"
          ]
      },
      {
          "name": "2015_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_15%20LNG%20regasification/NPC-Request%20LNG%20in%20tank%20in%20Spain%20%2020150618%20-%20FINAL.pdf"
          ]
      },
      {
          "name": "2022_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2022/2022_15%20Extension%20Gas%20forwards%20financial%20Spain/2022_15%20Gas%20Forwards%20financial%20Spain_Extension_FINAL.pdf"
          ]
      },
      {
          "name": "2019_11",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_11%20PPA%20LT%20Spain/NPC-Request%20Alpiq%20LT%20PPA%20Spain%20%2020190702.pdf"
          ]
      },
      {
          "name": "2016_06",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/2016_06%20Nordic%20Asian%20Option/forwardAndSpotModel.pdf"
          ]
      },
      {
          "name": "2018_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_12%20Average%20rate%20Option%20(Asian%20option)%20%E2%80%93%20OTC%20%E2%80%93%20extension%20for%20weekly%20maturity/NPC-Request%20-%20OTC%20Average%20rate%20(Asian)%20option%20-%20extension%2020180725.pdf"
          ]
      },
      {
          "name": "2016_06",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/2016_06%20Nordic%20Asian%20Option/forwardAndSpotModel.pdf"
          ]
      },
      {
          "name": "2020_18",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_18%20Nordic%20and%20Baltic%20bilateral%20physical%20products/NPC-Request%20Nordic%20and%20Baltic%20Bilateral%20physical%20products%2020210309.pdf"
          ]
      },
      {
          "name": "2017_14",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_14%20Nordic%20OTC-Forward%20-%20hourly%20profiles%20%26%20additional%20regions/NPC-Request%20Alpiq%20Nordic%20OTC-Forward%20-%20hourly%20profiles%20%20additional%20regions%2020171004.pdf"
          ]
      },
      {
          "name": "2016_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_12%20Nordic%20OTC-Financial%20Base%20%26%20Peak/NPC-Request%20-Nordic%20OTC%20financial%20base%20%20%26%20peak%2020160331.pdf"
          ]
      },
      {
          "name": "2021_25",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2021/2021_25%20Nordic%20Products%20-%20Extension%20of%20approved%20Products%20to%20local%20Currency/2021_25%20Nordic%20Power%20Extension%20to%20local%20Currencies_Final.pdf"
          ]
      },
      {
          "name": "2015_35",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_35%20Nordic%20Swing%20Option/NPC%20Nordic%20swing%2020151130.pdf"
          ]
      },
      {
          "name": "2020_19",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_19%20Full%20Supply%20of%20power%20in%20Nordic%20and%20Baltic%20region/NPC-Request%20Nordic%20and%20Baltic%20full%20supply%20products%20for%20physical%20market%2020210309.pdf"
          ]
      },
      {
          "name": "2016_11",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_11%20Nordic%20financial%20swing%20option%20process%20for%20daily%20nomination/Nordic%20financial%20swing%20option%20process%20for%20daily%20nomination%2020160607.pdf"
          ]
      },
      {
          "name": "2021_38",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2021/2021_38%20Standard%20Options%20_Extension%20ESP%20and%20IT/2021_38%20NPC-Request%20Standard%20Options%20IT%20ESP%20Extension_FINAL.pdf"
          ]
      },
      {
          "name": "2020_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_12%20Green%20Gas/NPC-Request%20Green%20Gas%2020200619.pdf"
          ]
      },
      {
          "name": "2014_13",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2014/Fertig/2014_13%20TEE/NPC-Request%20Alpiq%20TEE%20%2020141114.pdf"
          ]
      },
      {
          "name": "2016_36",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_36%20Retail%20Italy/NPC-Request%20Retail%20Italy%20201700213.pdf"
          ]
      },
      {
          "name": "2019_15",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_15%20RES%20Italy%203rd%20party%20offtake/NPC-Request%20RES%20Italy%203rd%20party%20offtake%2020190813.pdf"
          ]
      },
      {
          "name": "2020_06",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_06%20RES%20Italy%20%E2%80%93%20PPA%20Offtake%20contracts%20with%20pricing%20alternatives/NPC-Request%20RES%20Italy%203rd%20parties%20-%20Pricing%20alternatives%2020210216.pdf"
          ]
      },
      {
          "name": "2016_22",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_22%20LNG%20Storage%20Italy/NPC-Request%20LNG%20to%20Storage%20Italy%2020160706.pdf"
          ]
      },
      {
          "name": "2015_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_12%20Hourly%20structed%20GAS%20profiles/NPC-Request%20Hourly%20structured%20GAS%20profiles%2020150330.pdf"
          ]
      },
      {
          "name": "2015_12",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_12%20Hourly%20structed%20GAS%20profiles/NPC-Request%20Hourly%20structured%20GAS%20profiles%2020150330.pdf"
          ]
      },
      {
          "name": "2017_11",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_11%20OTC-Gas%20option%20-%20Phase%20II%20-%20PSV/NPC-Request%20OTC-Gas%20options%20-%20Phase%202%2020170919.pdf"
          ]
      },
      {
          "name": "2019_22",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_24%20LNG%20Products%20in%20MIBGas%20Derivatives/2019_22%20RES%20PPA%20Germany/NPC-Request%20Alpiq%20RES%20PPA%20Germany%2020190813.pdf"
          ]
      },
      {
          "name": "2018_18",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_18%20Financial%20weather%20indexed%20gas%20supply%20contract%20-%20extension/NPC-Request%20Alpiq%20Financial%20weather%20indexed%20gas%20supply%20contract%20-%20extension%2020180731.pdf"
          ]
      },
      {
          "name": "2017_29",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_29%20Weather-only%20indexed%20(financial)%20Instruments/NPC-Request%20Alpiq%20%20Weather-only%20indexed%20(financial)%20Instruments%2020171212.pdf"
          ]
      },
      {
          "name": "2019_20",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_20%20Weather-only%20indexed%20Instruments%20-%20for%20all%20purposes/NPC-Request%20Alpiq%20%20Weather-only%20indexed%20(financial)%20Instruments%2020191113.pdf"
          ]
      },
      {
          "name": "2017_01",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_01%20weather%20derivates/NPC-Request%20Alpiq%20weather%20indexed%20gas%20supply%20contract%20%2020170821.pdf"
          ]
      },
      {
          "name": "2019_40",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_40%20%20RES%20PPA%20Germany%20SellSide/NPC-Request%20Alpiq%20RES%20PPA%20Germany%20SellSide%2020200114.pdf"
          ]
      },
      {
          "name": "2017_07",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_07%20EEX_Greece/NPC-Request%20Alpiq%20EEX%20Greece%2020170620.pdf"
          ]
      },
      {
          "name": "2019_02",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_02%20CEE-%20EEX%20financial%20futures%20(Bulgaria,%20Slovenia%20and%20Serbia)/NPC-Request%20EEX%20financial%20futures%20(Bulgaria,%20Slovenia%20and%20Serbia)%2020190522.pdf"
          ]
      },
      {
          "name": "2018_27",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_27%20EEX%20Financial%20Short-Term%20Futures%20(CZ,%20HU%20and%20RO)/NPC-Request%20EEX%20financial%20short-term%20futures%20for%20CZ,%20HU%20and%20RO_2018_09_26.pdf"
          ]
      },
      {
          "name": "11_09",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2011/Fertige%20NPC%27s/2011_09%20Financial%20Futures%20PXE%20-%20CZ/20110223%20-%20NPC%20Financial%20Futures%20PXE.pdf"
          ]
      },
      {
          "name": "09_02",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2009/Fertig/09_02%20Forward%20trading%20on%20PolpX/20090825%20-%20NPC%20Forward%20Trading%20on%20PolPX_09_02.pdf?csf=1&web=1&e=aKCuCV"
          ]
      },
      {
          "name": "2015_29",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_29%20Guarantees%20of%20origin%20CEE/NPC-GoO_CEE.pdf"
          ]
      },
      {
          "name": "2017_35",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/2017_35%20GoO_CEE_HUN/NPC-GoO_CEE_HUN_20180206.pdf"
          ]
      },
      {
          "name": "2017_11",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_11%20OTC-Gas%20option%20-%20Phase%20II%20-%20PSV/NPC-Request%20OTC-Gas%20options%20-%20Phase%202%2020170919.pdf"
          ]
      },
      {
          "name": "2018_23",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_23%20Nordic%20elcert%20extension%20-%20exchange/NPC-Request%20Alpiq%20Nordic%20elcert%20extension%20-%20exchange%2020180912.pdf"
          ]
      },
      {
          "name": "14_07",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2014/Fertig/2014_07%20Modelling%20of%20location%20optionality%20(FR%20or%20CH)%20with%20the%20EDF%20contract/Modelling%20of%20location%20optionality%20(FR%20or%20CH)%20with%20the%20EDF%20contracts_20_08%2014_FINAL.pdf"
          ]
      },
      {
          "name": "2019_38",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_38%20stripe%20of%20daily%20gas%20options/NPC-Request%20Alpiq%20daily%20options%2020200729.pdf"
          ]
      },
      {
          "name": "2019_38",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_38%20stripe%20of%20daily%20gas%20options/NPC-Request%20Alpiq%20daily%20options%2020200729.pdf"
          ]
      },
      {
          "name": "2019_09",
          "paths": [
              "https://alpiq0.sharepoint.com/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2019/Fertig/2019_09%20EPEX%20CH-IT%20Intraday%20auctions,%20with%20implicit%20capacity%20allocation/NPC-Request%20Alpiq%20EPEX%20CH-IT%20Intraday%20auctions,%20with%20implicit%20capacity%20allocation%2020190416.pdf"
          ]
      },
      {
        "name": "2021_34",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_34%20Products%20for%20physical%20market%20Norway/NPC-Request%20Products%20for%20physical%20market%20Norway%2020210618_FINAL_MPS.pdf"
        ]
      },
      {
        "name": "2020_25",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2020/fertig/2020_25%20Italy%20new%20Market%20RR/NPC-Request%20Alpiq%20Italy%20new%20Market%20RR%2020210106.pdf"
        ]
      },
      {
        "name": "2018_17",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_17%20EEX%20financial%20futures%20for%20CZ,%20SK,%20HU,%20PL%20and%20RO/NPC-Request%20EEX%20financial%20futures%20for%20CZ,%20SK,%20HU,%20PL%20and%20RO%202018_05_17.pdf"
        ]
      },
      {
        "name": "08_15",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/Fertige%20NPC%27s/2012_10%20New%20Brent%20ICE%20Futures/20081015%20-%20NPC%20Memorandum%20ICE%20Brent%20Futures_08_15.pdf"
        ]
      },
      {
        "name": "2022_04",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/CEE%20%20Shared%20Documents/Approved%20Products/2022/2022_04%20EEX%20Power%20Futures%20Extension/EEX%20financial%20futures%20AT,IT,DE/2022_04_EEX_Power_Futures_Extension_AT_IT_DE_FINAL_Approved.pdf"
        ]
      },
      {
        "name": "2009_06",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2009/Fertig/09_06%20ex-post%20CfD%20BRNN-PUN/20090825%20-%20NPC%20Fin%20Transmission%20Rights%20IT%20BRNN%20PUN_2009_06.pdf"
        ]
      },
      {
        "name": "2023_03",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2023/2023_03%20Ancillary%20Services_mFRR_Nordics/2023_03_Ancillary_Services_mFRR_Nordics_FINAL_Approved.pdf"
        ]
      },
      {
        "name": "2021_29",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/Approved%20products/Final%20versions%20-%20approved/2021/EEX%20EUA%20Options/2021_29%20EEX%20EUA%20Options%202021_10_15.docx.pdf"
        ]
      },
      {
        "name": "2018_16",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_16%20HUDEX%20financial%20futures/NPC-Request%20Alpiq%20HUDEX%20financial%20futures%2020180517.pdf"
        ]
      },
      {
        "name": "2021_33",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC%20Documents/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_33%20EEX%20EGSI%20Financial%20Gas%20Futures/NPC-Request%20Alpiq%20EEX%20EGSI%20Financial%20Gas%20Futures%20%2020211005_2021_33.pdf"
        ]
      },
      {
        "name": "2014_14",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2014/Fertig/2014_14%20Crossboarder%20trading%20into%20BL%20and%20NL/NPC-Request%20Alpiq%20Benelux%2020141118.pdf"
        ]
      },
      {
        "name": "2016_42",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_42%20EPEX%20SPOT%20-%20day%20ahead%20%26%20intraday%20power%20auction/NPC-Request%20Alpiq%20EPEX%20SPOT%20-%20day%20ahead%20%20intraday%20power%20auction%20%2020161213.pdf"
        ]
      },
      {
        "name": "12_21",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2012/Fertige%20NPC%27s/2012_21%20EUA%20Spot%20at%20ICE/20131115%20-%20NPC%20EUA%20Spot%20Market%20Access%20at%20ICE_12_21.pdf"
        ]
      },
      {
        "name": "2015_30",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2015/Fertig/2015_30%20French%20Capacity/NPC%20Request%20French%20Capacity%20Market%20-%2020161110_2015_30.pdf"
        ]
      },
      {
        "name": "2018_35d",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_35%20ICE%20Endex/NPC-Request%20Alpiq%20ICE%20Endex%20Phase%20III%20-%20Daily%20TTF%2020190507_2018_35d.pdf"
        ]
      },
      {
        "name": "2017_31",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2017/Fertig/2017_31%20CEE%20old%20products%20without%20NPC%20request/NPC-Request%20Alpiq%20CEE%20products%20active%20before%20official%20NPC%20process%2020171116.pdf"
        ]
      },
      {
        "name": "2016_05",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2016/Fertig/2016_05%20New%20Exchanges%20CEE%20-%20Access%20to%20Serbian%20(SEEPEX)%20and%20Bulgarian%20(IBEX)%20power/NPC%20Request%20-%20New%20Exchanges%20CEE%20-%20Access%20to%20Serbian%20(SEEPEX)%20and%20Bulgarian%20(IBEX)%20power%2020160321.pdf"
        ]
      },
      {
        "name": "2018_20",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2018/Fertig/2018_20%20croatien/NPC-Request%20Alpiq%20Membership%20for%20the%20Croatian%20Power%20Exchange%20Ltd%20-%20CROPEX%202080613.pdf"
        ]
      },{
        "name": "2013_14",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2014/Fertig/2013_14%20Process%20Review%20Gas%20Retail%20France/NPC-Request%20Process%20Review%20Gas%20Retail%20France%2020141022.pdf"
        ]
      },
      {
        "name": "2021_18",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_18%20CEE%20Access%20to%20Nordpool%20intraday%20exchange%20in%20Poland/NPC-Request%20-%20access%20to%20Nordpool%20intraday%20exchange%20in%20Poland%2020210814%20final%20%20pt1.pdf"
        ]
      },
      {
        "name": "11_36",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2011/Fertige%20NPC%27s/2011_36%20CEGH/20120315%20-%20NPC%20Gas%20Trading%20at%20CEGH_11_36.pdf"
        ]
      },
      {
        "name": "2021_08",
        "paths": [
            "https://alpiq0.sharepoint.com/:b:/r/sites/msteams_54035b/NPC/1%20Dokumentationen%20NPC-Antr%C3%A4ge/2021/fertig/2021_08%20GR%20EnEx-%20Trading%20of%20DAM%20physical%20products%20by%20Alpiq%20Hellas%20(extension%20for%20new%20legal%20unit)/20210624%20GRENEX%20NPC%20DAM%20amendment_final.pdf"
        ]
      }
  ]
}
