import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { ValueChain } from '../model/enum/value-chain';
import { LegalEntities } from '../model/enum/legal-entity';
import { MatDialog } from '@angular/material/dialog';
import { ListsPerRequestType, RequestType } from '../npc-request-types';
import { User } from 'src/app/users/users-types';
import { UsersService } from 'src/app/users/users.service';
import { Mandates } from '../model/enum/mandates';

const DEFAULT_COORDINATOR: string = 'geraldine.waldecker@alpiq.com'

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css']
})
export class GeneralInfoComponent extends NpcRequestSectionBase implements OnInit, OnChanges {

  public LegalEntity = LegalEntities;
  public ValueChain = ValueChain;
  public coordinatedByUsers: string[] = [];
  public applicantNameUsers: string[] = [];
  public Mandates: string[] = Mandates;

  constructor(protected override dialog: MatDialog, private usersService: UsersService) {
    const readonlyFields: ListsPerRequestType = {
      [RequestType.NEW_REQUEST]: ['productNumber'],
      [RequestType.EXTENSION]: ['productNumber'],
      [RequestType.TECH_CHANGE]: ['productNumber'],
    };
    const disabledFields: ListsPerRequestType = {
      [RequestType.NEW_REQUEST]: ['technicalChangeTitle'],
      [RequestType.EXTENSION]: ['technicalChangeTitle'],
      [RequestType.TECH_CHANGE]: ['legalEntities', 'mandates'],
    };
    super(dialog, readonlyFields, disabledFields);
  }

  ngOnInit(): void {
    this.fields = {
      productNumber: new FormControl(null, []),
      productName: new FormControl(null, [Validators.required]),
      productOwner: new FormControl(null, [Validators.required]),
      applicantName: new FormControl(null, [Validators.required]),
      coordinatedBy: new FormControl(DEFAULT_COORDINATOR, [Validators.required]),
    };
    if (!this.isTechnicalChange()) {
      this.fields['legalEntities'] = new FormControl(null, [Validators.required]);
      this.fields['mandates'] = new FormControl(null, []);
    } else {
      this.fields['technicalChangeTitle'] = new FormControl(null, [Validators.required]);
    }
    const form: FormGroup = new FormGroup(this.fields);
    if (!this.isNew) {
      form.addControl('productNumber', new FormControl({value: null, disabled: true}, [Validators.required]));
    }
    this.form = form;
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  protected override getValueFromModel(fieldName: string): any {
    const value: any = super.getValueFromModel(fieldName);
    if (fieldName !== 'coordinatedBy' && fieldName !== 'applicantName') {
      return value;
    }
    if (fieldName === 'coordinatedBy') {
      let emails: string[] = [];
      if (this.isNew) {
        emails.push(DEFAULT_COORDINATOR);
      } else if (Array.isArray(value)) {
        emails = value;
      }
      this.coordinatedByUsers = emails;
    } else {
      this.applicantNameUsers = value ? [value] : [];
    }
  }

  public changeSelectedApplicant(applicants: User[]): void {
    if (!Array.isArray(applicants) || applicants.length === 0) {
      this.form.get('applicantName')?.setValue(null);
    } else {
      this.form.get('applicantName')?.setValue(applicants[0].email);
    }
  }

  public changeSelectedCoordinator(coordinators: User[]): void {
    if (!Array.isArray(coordinators)) {
      this.form.get('coordinatedBy')?.setValue([]);
    } else {
      this.form.get('coordinatedBy')?.setValue(coordinators.map((user: User) => user.email));
    }
  }

}
