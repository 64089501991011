import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentService } from './attachment.service';
import { Attachment, AttachmentMetadata, AttachmentMetadataExtended, SaveAttachmentsResponse } from './attachment.types';
import { Observable, Subscription, catchError, forkJoin, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'npc-attachments',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent {
  @Input() metadata: AttachmentMetadata[] = [];
  @Input() npcId!: string;
  @Input() sectionId!: string;
  @Input() isReadOnly = false;
  @Output() saveAttachmentsResponse = new EventEmitter<SaveAttachmentsResponse>();
  
  public addFileForm: FormGroup;
  public isDisplayed = true;
  public selectedFile?: File;
  public message?: string = undefined;
  private attachmentsToSave: Attachment[] = [];
  public isEdit = false;
  public metadataLive: AttachmentMetadata[] = [];
  private subscriptions: Subscription[] = [];

  private FILENAME_PLACEHOLDER = 'Select File';

  constructor(private attachmentService: AttachmentService, private fb: FormBuilder,) {
    this.addFileForm = this.fb.group({
      selectedFileName: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^[\w-]+\.[\w]+$/)]],
      selectedFileDescription: ['', [Validators.required, Validators.minLength(2)]]
    });
  }

  public ngOnInit(): void {
    this.metadataLive = JSON.parse(JSON.stringify(this.metadata)) as AttachmentMetadata[];
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private sanitizeFilename(filename: string): string {
    const extension: string = filename.split('.').pop() as string;
    const nameWithoutExtension = filename.replace(/\.[^/.]+$/, "");
    return `${this.sanitize(nameWithoutExtension)}.${this.sanitize(extension)}`;
  }

  private sanitize(str: string): string {
    return str.replace(/[^a-zA-Z0-9_-]/g, '_');
  }

  public get selectedFileName() {
    return this.addFileForm.get('selectedFileName')?.value;
  }

  public get selectedFileDescription() {
    return this.addFileForm.get('selectedFileDescription')?.value;
  }

  public set selectedFileName(value: string) {
    this.addFileForm.get('selectedFileName')?.setValue(value);
  }

  public set selectedFileDescription(value: string) {
    this.addFileForm.get('selectedFileDescription')?.setValue(value);
  }

  public getFormControl(field: string) {
    return this.addFileForm.controls[field];
  }

  public selectFile(event: any): void {
    this.message = undefined;
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.selectedFile = file;
      this.selectedFileName = this.sanitizeFilename(file.name);
      this.selectedFileDescription = '';
    } else {
      this.selectedFileName = this.FILENAME_PLACEHOLDER;
    }
  }

  public add(): void {
    this.message = undefined;
    if (!this.selectedFile && !this.isEdit) {
      this.message = 'Please select a file to upload';
      return;
    }
    if (this.addFileForm.invalid) {
      this.message = 'Please enter a valid file name and description';
      return;
    }
    if (this.isEdit && this.selectedFile === undefined) {
      const index = this.metadataLive.findIndex(a => a.fileName === this.selectedFileName);
      if (index > -1) {
        this.metadataLive[index].description = this.selectedFileDescription || '';
      }
    } else {
      const attachment: AttachmentMetadataExtended = {
        npcId: this.npcId,
        sectionId: this.sectionId,
        fileName: (this.selectedFileName as string),
        versionId: undefined,
        description: this.selectedFileDescription || '',
        isUploaded: false,
      };
      this.metadataLive.push(attachment);
      this.attachmentsToSave.push({ metadata: attachment, file: this.selectedFile as File });
    }
    this.isEdit = false;
    this.selectedFile = undefined;
    this.selectedFileName = "";
    this.selectedFileDescription = "";
    this.addFileForm.reset();
  }

  public delete(attachment: AttachmentMetadata): void {
    const index1 = this.metadataLive.findIndex(a => a.fileName === attachment.fileName);
    if (index1 > -1) {
      this.metadataLive.splice(index1, 1);
    }
    const index2 = this.attachmentsToSave.findIndex(a => a.metadata.fileName === attachment.fileName);
    if (index2 > -1) {
      this.attachmentsToSave.splice(index2, 1);
    }
  }

  public edit(attachment: AttachmentMetadata): void {
    this.selectedFileDescription = attachment.description;
    this.selectedFileName = attachment.fileName;
    this.isEdit = true;
  }

  public saveAttachments(): void {
    if (this.attachmentsToSave.length === 0) {
      const response: SaveAttachmentsResponse = { isSuccessful: true, error: undefined, attachments: this.metadataLive };
      this.saveAttachmentsResponse.emit(response);
    } else {
      const uploadRequests: Observable<AttachmentMetadataExtended>[] = this.attachmentsToSave.map(attachment => {
        return this.attachmentService.uploadAttachment(attachment).pipe(
          catchError(error => {
            console.error(`Failed to upload attachment for file '${attachment.metadata.fileName}':`, error);
            const errResponse: AttachmentMetadataExtended = {
              npcId: attachment.metadata.npcId,
              sectionId: attachment.metadata.sectionId,
              fileName: attachment.metadata.fileName,
              versionId: attachment.metadata.versionId,
              description: attachment.metadata.description,
              isUploaded: false
            };
            return of(errResponse);
          })
        );
      });
    
      this.subscriptions.push(
        forkJoin(uploadRequests)
          .subscribe({
            next: (results: AttachmentMetadataExtended[]) => {
              const successfulAttachments: AttachmentMetadataExtended[] = [];
              const failedAttachments: AttachmentMetadataExtended[] = [];
              results.forEach((result: AttachmentMetadataExtended) => {
                const index1 = this.metadataLive.findIndex(a => a.fileName === result.fileName);
                if (index1 > -1) {
                  this.metadataLive[index1] = {
                    fileName: result.fileName,
                    versionId: result.versionId,
                    description: result.description,
                    isUploaded: result.isUploaded
                  } // replace with updated metadata in attachments array
                }
                if (result.isUploaded) {
                  const index2 = this.attachmentsToSave.findIndex(a => a.metadata.fileName === result.fileName);
                  if (index2 > -1) {
                    this.attachmentsToSave.splice(index2, 1); // remove from attachmentsToSave array
                  }
                  successfulAttachments.push(result);
                } else {
                  failedAttachments.push(result);
                }
              });

              let isSuccessful: boolean;
              let error: string | undefined;
              if (failedAttachments.length > 0) {
                this.message = `Failed to save attachments: ${failedAttachments.map(a => a.fileName).join(', ')}`;
                isSuccessful = false;
                error = this.message;
              } else {
                isSuccessful = true;
                error = undefined;
              }
              const response: SaveAttachmentsResponse = { isSuccessful, error, attachments: this.metadataLive };
              this.saveAttachmentsResponse.emit(response);
            },
            error: (error) => {
              console.error('Failed to save attachments:', error);
              this.message = 'Failed to save attachments';
              const response: SaveAttachmentsResponse = { isSuccessful: false, error: this.message, attachments: this.metadataLive };
              this.saveAttachmentsResponse.emit(response);
            }
        })
      );
    }
  }

  public open(attachment: AttachmentMetadata): void {
    this.message = undefined;
    const attachmentExt: AttachmentMetadataExtended = { 
      ...attachment,
      npcId: this.npcId,
      sectionId: this.sectionId
    };
    this.subscriptions.push(
      this.attachmentService.downloadAttachment(attachmentExt).subscribe(
        (url: string | undefined) => {
          if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = attachment.fileName;
            link.click();
          }
        }
      )
    );
  }
}