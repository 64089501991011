
import { Component, OnInit, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { MatDialog } from '@angular/material/dialog';
import { OperationalResponsibility } from '../model/responsibility';

@Component({
  selector: 'app-operational-responsibilities',
  templateUrl: './operational-responsibilities.component.html',
  styleUrls: ['./operational-responsibilities.component.css']
})
export class OperationalResponsibilitiesComponent extends NpcRequestSectionBase implements OnInit, OnChanges, AfterViewInit {
  public responsibilityTasks: OperationalResponsibility[] = [];

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    this.fields = {
      followStandardProcess: new FormControl(null, []),
      confirmationNeeded: new FormControl(null, []),
      newDealTicketPrefixNeeded: new FormControl(null, []),
      remitReportNeeded: new FormControl(null, []),
      roboReportNeeded: new FormControl(null, []),
      responsibilities: new FormControl([], [])
    };
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
    this.responsibilityTasks = this.model?.responsibilities || [];
  }

  ngAfterViewInit(): void {
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  protected override getFormValue(): any {
    const formValue: any = {
      ...this.form.getRawValue(),
      responsibilities: this.responsibilityTasks
    };
    return formValue;
  }

  public updateResponsibilityTasks(newResposibilityTasks: OperationalResponsibility[]) {
    this.responsibilityTasks = newResposibilityTasks;
  }

}