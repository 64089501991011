import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { RichTextEditorComponent } from 'src/app/rich-text-editor/rich-text-editor.component';
import { RichTextField } from 'src/app/rich-text-editor/rich-text-editor.types';
import { AttachmentComponent } from 'src/app/attachment/attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { ListsPerRequestType, RequestType } from '../npc-request-types';

@Component({
  selector: 'app-product-description-technical-change',
  templateUrl: './product-description-technical-change.component.html',
  styleUrls: ['./product-description-technical-change.component.css']
})
export class ProductDescriptionTechnicalChangeComponent extends NpcRequestSectionBase implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('technicalChangeDefinition') technicalChangeDefinitionEditorComponent!: RichTextEditorComponent;
  @ViewChild('improvementPotential') improvementPotentialEditorComponent!: RichTextEditorComponent;
  @ViewChild('attachments') attachmentComponent!: AttachmentComponent;

  public technicalChangeDefinitionHelp = "<p>Which product(s) are being changed and how? Short description. Detailed description in the appendix if needed.</p><p>Price Feeds and Specs:</p><p>Link to Price Feeds and Specs:</p><p>Platforms & Exchanges:</p><p>Link to Product on Exchange (if applicable):</p><p>When do you want the technical change to be implemented?</p>";

  constructor(protected override dialog: MatDialog) {
    const readonlyFields: ListsPerRequestType = {
      [RequestType.NEW_REQUEST]: [],
      [RequestType.EXTENSION]: [],
      [RequestType.TECH_CHANGE]: [],
    };
    const disabledFields: ListsPerRequestType = {
      [RequestType.NEW_REQUEST]: [],
      [RequestType.EXTENSION]: [],
      [RequestType.TECH_CHANGE]: [],
    };
    super(dialog, readonlyFields, disabledFields);
  }

  ngOnInit(): void {
    
    this.fields = {
      usedPortfolios: new FormControl(null, []),
    };
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }
  
  ngOnDestroy(): void {
    this.unsubscribe();
  }

  ngAfterViewInit(): void {
    const richTextFields: RichTextField[] = [
      {
        fieldId: 'technicalChangeDefinition',
        component: this.technicalChangeDefinitionEditorComponent,
        isMandatory: true
      },
      {
        fieldId: 'improvementPotential',
        component: this.improvementPotentialEditorComponent,
        isMandatory: true
      }
    ];
    this.richTextFields = richTextFields;
    
    this.attachmentsField = { component: this.attachmentComponent };

    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }
}