<mat-expansion-panel  (opened)="isDisplayed = true" (closed)="isDisplayed = false" [expanded]="isDisplayed" style="flex: 1;">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span>Attachments:</span>
    </mat-panel-title>
    <span class="spacer"></span>
  </mat-expansion-panel-header>
  <div id="edit-form" *ngIf="!isReadOnly">
    <form [formGroup]="addFileForm" (ngSubmit)="add()">
      <mat-form-field *ngIf="selectedFile">
        <mat-label>File name:</mat-label>
        <input matInput formControlName="selectedFileName" readonly="isEdit"/>
        <mat-error *ngIf="addFileForm.get('selectedFileName')?.touched && getFormControl('selectedFileName').errors">
          Valid file names have an extension and must have only alphanumerical characters, or '_', '-'
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="selectedFile">
        <mat-label>Description:</mat-label>
        <input matInput formControlName="selectedFileDescription" />
        <mat-error *ngIf="addFileForm.get('selectedFileDescription')?.touched && getFormControl('selectedFileDescription').errors">
          Description is required
        </mat-error>
      </mat-form-field>
      <button mat-flat-button color="primary" type="submit" *ngIf="(selectedFile || isEdit) && addFileForm.valid">Save</button>
      <button mat-flat-button color="primary" type="button" (click)="fileInput.click()" style="margin-left: 10px;">Select File</button>
      <input hidden (change)="selectFile($event)" #fileInput type="file" id="file">
    </form>
  </div>
  <div class="attachments-container">
    <mat-card class="is-focusable" role="button" *ngFor="let attachment of metadataLive" (click)="open(attachment)">
      <mat-card-header>
        <mat-card-title>{{ attachment.fileName }}</mat-card-title>
        <mat-icon *ngIf="!attachment.isUploaded" matTooltip="Not uploaded">highlight_off</mat-icon>
        <mat-icon *ngIf="attachment.isUploaded" matTooltip="Uploaded">check_circle_outline</mat-icon>
      </mat-card-header>
      <mat-card-content>{{ attachment.description }}</mat-card-content>
      <mat-card-actions *ngIf="!isReadOnly">
        <button mat-flat-button color="primary" type="submit" (click)="edit(attachment)">Edit</button>
        <button mat-flat-button color="primary" type="submit" (click)="delete(attachment)">Delete</button>
      </mat-card-actions>
    </mat-card>  
  </div>

    <div *ngIf="message" class="message">{{ message }}</div>
  </mat-expansion-panel>
