import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { RichTextEditorComponent } from 'src/app/rich-text-editor/rich-text-editor.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-specific-remarks',
  templateUrl: './specific-remarks.component.html',
  styleUrls: ['./specific-remarks.component.css']
})
export class SpecificRemarksComponent extends NpcRequestSectionBase implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('specificRemarks') specificRemarksEditorComponent!: RichTextEditorComponent;
  public specificRemarksHelp = "<p>Are there any special conditions to be dealt with?</p><p>For example: first-of-a-kind implementation, characteristics unique to this product, specific dependencies or circumstances.</p>";

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    this.fields = {};
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    const richTextFields = [
      {
          fieldId: 'specificRemarks',
          component: this.specificRemarksEditorComponent, 
          isMandatory: false
      }
    ];
    this.richTextFields = richTextFields;
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

}
