export const ProductGroup: string[] = [
    "Capacities",
    "Gas Storage",
    "Swings",
    "Swaps",
    "Forwards",
    "Options",
    "Virtual Products",
    "Supply",
    "PPA",
    "Futures",
    "Spot",
    "Certificates",
    "Balancing Services"
];







