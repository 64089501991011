import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalRequest, ApprovalRequestOut, JIRATaskInfo, JIRATaskInfoQualified, JIRATasks, JiraTaskCreate, NpcRequest, NpcRequestExtended } from './model/npc-request';
import { NpcMetadata } from './model/npc-request';

@Injectable({
  providedIn: 'root'
})
export class NpcRequestService {
  private apiUrl = `${environment.apiBaseUrl}/npc`;

  constructor(private http: HttpClient) { }

  saveNpcRequest(npcRequest: NpcRequest, npcType: string, npcId: string, parentProductId?: string): Observable<NpcMetadata> {
    let url = `${this.apiUrl}?npcType=${npcType}&npcId=${npcId}`;
    if (parentProductId) {
      url += `&parentProductId=${parentProductId}`;
    }
    console.log(`Saving NPC request to ${url}`);
    return this.http.post<NpcMetadata>(url, npcRequest);
  }

  getNpcRequest(npcId: string): Observable<NpcRequestExtended[]> {
    const url = `${this.apiUrl}/${npcId}?latest=true`;
    console.log(`Getting NPC request from ${url}`);
    return this.http.get<NpcRequestExtended[]>(url);
  }

  updateNpcRequest(npcId: string, npcRequest: NpcRequest): Observable<NpcMetadata> {
    const url = `${this.apiUrl}/${npcId}`;
    console.log(`Updating NPC request to ${url}`);
    return this.http.put<NpcMetadata>(url, npcRequest);
  }

  listNpcRequests(): Observable<NpcMetadata[]> {
    const url = `${this.apiUrl}?only_metadata=true&latest=true`;
    console.log(`Listing NPC requests from ${url}`);
    return this.http.get<NpcMetadata[]>(url);
  }

  changeStatus(npcId: string, status: string, isAdmin: boolean = false): Observable<NpcMetadata> {
    let url = `${this.apiUrl}/${npcId}/status/${status}`;
    if (isAdmin) {
      url += '?force=true';
    }
    console.log(`Changing status of NPC request to ${status} at ${url}`);
    return this.http.put<NpcMetadata>(url, {});
  }

  createApprovalRequest(npcId: string, request: ApprovalRequest): Observable<any> {
    const req: ApprovalRequestOut = {
      ...request,
      weblink: `${environment.uiUrl}/npc-request?action=view&npcId=${npcId}`
    };
    const url = `${this.apiUrl}/${npcId}/approval`;
    console.log(`Requesting approval to ${url}`);
    return this.http.post<any>(url, req);
  }

  getJiraTaskInfo(npcId: string): Observable<JIRATaskInfoQualified[]> {
    const url = `${this.apiUrl}/${npcId}/implementation`;
    console.log(`Getting JIRA task info from ${url}`);
    return this.http.get<any>(url).pipe(
      map((tasks: JIRATaskInfo[]) => convertJiraEstimations(tasks))
    );
  }

  createJiraImplementationTask(npcId: string, subProjectsJiraKeys: string[]): Observable<JIRATasks> {
    const url = `${this.apiUrl}/${npcId}/implementation`;
    console.log(`Creating JIRA implementation task at ${url}`);
    const payload: JiraTaskCreate = {
      subProjects: subProjectsJiraKeys,
      weblink: `${environment.uiUrl}/npc-request?action=view&npcId=${npcId}`
    };
    return this.http.post<JIRATasks>(url, payload);
  }

  addJiraImplementationTasks(npcId: string, subTasksIds: string[]): Observable<JIRATasks> {
    const url = `${this.apiUrl}/${npcId}/implementation`;
    console.log(`Adding JIRA implementation tasks at ${url}`);
    return this.http.put<JIRATasks>(url, subTasksIds);
  }
}

function convertJiraEstimations(tasks: JIRATaskInfo[]): JIRATaskInfoQualified[] {
  return tasks.map((task: JIRATaskInfo) => {
    const estimation: string | undefined = task.estimation;
    let estimationQualified: string = 'not defined';
    if (estimation !== undefined && estimation !== null) {
      try {
        const estimationNumber: number = parseFloat(estimation);
        if (estimationNumber > 0) {
          const hours: number = estimationNumber / 3600;
          if (hours < 8) {
            const unit: string = hours >= 2 ? 'hours': 'hour';
            estimationQualified = `${hours.toFixed(0)} ${unit}`;
          } else {
            const days: number = hours / 8;
            const unit: string = days >= 2 ? 'days': 'day';
            estimationQualified = `${Number.isInteger(days) ? days.toFixed(0) : days.toFixed(1)} ${unit}`;
          }
        }
      } catch (error) {
        console.error(`Error converting estimation '${estimation}' to number`, error);
      }
    }
    return {
      ...task,
      estimationQualified
    };
  });
}