import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { NpcRequestService } from "../../npc-request.service";
import { Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { getApiErrorMessage } from '../../../utils/api-error';
import { JIRATasks } from "../../model/npc-request";
import { Subscription } from "rxjs";

export function taskIdValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const pattern = /^[A-Z0-9]{2,}-\d+$/;
    if (value && (pattern.test(value)) ) {
      return null;
    } else {
      return { 'invalidProject': { message: 'Invalid JIRA Task ID' } };
    }
  };
}

@Component({
  selector: 'update-implementation-dialog',
  templateUrl: 'update-implementation-dialog.component.html',
  styleUrls: ['./update-implementation-dialog.component.css']
})
export class UpdateImplementationDialogComponent implements OnInit, OnDestroy {
  public npcId?: string;
  public productNumber?: string;
  public productName?: string;
  public error?: string;
  public taskIds: string[] = [];
  public jiraTasks?: JIRATasks;
  public form: FormGroup;
  public statusMessage?: string;
  public isSaving: boolean = false;
  private subscriptions: Subscription[] = [];
 
  constructor(private npcRequestService: NpcRequestService, private router: Router, private route: ActivatedRoute) {
    this.form = new FormGroup({
      taskId: new FormControl(null, [Validators.required, taskIdValidator()])
    }); 
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params => {
        this.npcId = params['npcId'];
        this.productNumber = params['productNumber'];
        this.productName = params['productName'];
      }))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public removeProject(taskId: string): void {
    // Remove the taskId from the list of taskIds
    const index = this.taskIds.indexOf(taskId);
    if (index >= 0) {
      this.taskIds.splice(index, 1);
    }
  }

  public addProject(taskId: string) {
    if (this.form.valid) {
      this.taskIds.push(taskId);
      this.form.reset();
    }
  }

  private reroute(): void {
    this.router.navigate(['/npc-request'],
      { queryParams: { action: 'view', npcId: this.npcId } });
  }

  public cancel() {
    this.reroute();
  }

  public confirm() {
    this.isSaving = true;
    this.subscriptions.push(
      this.npcRequestService.addJiraImplementationTasks(this.npcId!, this.taskIds).subscribe({
        next: (jiraTasks: JIRATasks) => {
          this.jiraTasks = jiraTasks;
          this.isSaving = false;
        },
        error: (error: any) => {
          console.error("Failed to link JIRA implementation subtasks", error);
          this.error = getApiErrorMessage(error);
          this.isSaving = false;
        }
      })
    );
  }

  public close() {
    this.reroute();
  }

}