<h2 *ngIf="!isAdmin" mat-dialog-title>Change status confirmation</h2>
<h2 *ngIf="isAdmin" mat-dialog-title>Non-standard status change</h2>
<div class="status-selector" *ngIf="!error && isAdmin && !toStatus">
  <mat-form-field>
      <mat-label>Target status</mat-label>
      <mat-select (selectionChange)="onStatusSelected($event.value)" class="actions-selector">
          <mat-option *ngFor="let status of nonStandardTargetStatuses" [value]="status">{{getStatusLabel(status)}}</mat-option>
      </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-content>
  <div *ngIf="!error && toStatus" class="info">
    Do you confirm that you want to transition this request <B>'{{productNumber}}'</B> from status <B>'{{getStatusLabel(fromStatus)}}'</B>' to <B>'{{getStatusLabel(toStatus)}}'</B>?
  </div>
  <div *ngIf="!error && isAdmin && toStatus" style="color: red"  class="info">
    Please be aware that the selected status transition is not a standard one
  </div>
  <div *ngIf="error" style="color: red" class="info">
    Status could not be transitioned because an error occurred:</div>
  <div *ngIf="error" class="info">{{error}}</div>
</div>
<div mat-dialog-actions class="actions-button-container">
  <button mat-raised-button color="primary" (click)="confirm()" *ngIf="!error && toStatus && !isSaving">OK</button>
  <button mat-raised-button color="primary" mat-dialog-close (click)="cancel()" *ngIf="!error && !isSaving">Cancel</button>
  <button mat-raised-button color="primary" mat-dialog-close (click)="cancel()" *ngIf="error && !isSaving">Close</button>
  <div *ngIf="isSaving" class="npc-request-spinner">
    <mat-spinner [diameter]="24"></mat-spinner>
  </div>
</div>