
import { Component, OnInit, AfterViewInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RichTextEditorComponent } from 'src/app/rich-text-editor/rich-text-editor.component';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { MatDialog } from '@angular/material/dialog';
import { RequestType } from '../npc-request-types';

@Component({
  selector: 'app-concise-request',
  templateUrl: './concise-request.component.html',
  styleUrls: ['./concise-request.component.css']
})
export class ConciseRequestComponent extends NpcRequestSectionBase implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('situation') situationEditorComponent!: RichTextEditorComponent;

  public situationHelpValues = {
    [RequestType.NEW_REQUEST]: "<p>Please describe relevant official decisions and activities within Alpiq as well as relevant market developments.</p><p>Which strategic decisions by Mgmt. Board require the proposed product?</p><p>Are there mandatory deadlines to be accounted for?</p><p>Describe how alignment with business model is ensured.</p>",
    [RequestType.EXTENSION]: "<p>Please describe relevant official decisions and activities within Alpiq as well as relevant market developments.</p><p>Which strategic decisions by Mgmt. Board require the proposed product?</p><p>Are there mandatory deadlines to be accounted for?</p><p>Describe how alignment with business model is ensured.</p>",
    [RequestType.TECH_CHANGE]: "<p>Please describe relevant official decisions and activities within Alpiq as well as relevant market developments.</p><p>Which strategic decisions by Mgmt. Board or which existing issue require the proposed technical change?</p><p>Which strategic decisions by Mgmt. Board or which existing issue require the proposed technical change?</p>",
  };
  public conciseOfRequestHelpValues = {
    [RequestType.NEW_REQUEST]: "<p>Describe in short the business objective of the request</p>",
    [RequestType.EXTENSION]: "<p>Describe in short the business objective of the request</p>",
    [RequestType.TECH_CHANGE]: "<p>Describe in short the business objective of the request to technically change a product</p>",
  };

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
    super.helpPerRequestType = [
      {
        field: 'conciseOfRequest',
        title: 'Concise of Request *',
        help: {
          [RequestType.NEW_REQUEST]: "<p>Describe in short the business objective of the request</p>",
          [RequestType.EXTENSION]: "<p>Describe in short the business objective of the extension request</p>",
          [RequestType.TECH_CHANGE]: "<p>Describe in short the business objective of the extension request</p>",
        }
      },
    ];
  }

  ngOnInit(): void {
    this.fields = {
      conciseOfRequest: new FormControl(null, [Validators.required])
    };
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    const richTextFields = [
      {
          fieldId: 'situation', 
          component: this.situationEditorComponent, 
          isMandatory: true
      }
    ];
    this.richTextFields = richTextFields;
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  public getSituationHelp(): string {
    return this.situationHelpValues[this.requestType];
  }

  public getConciseOfRequestHelp(): string {
    return this.conciseOfRequestHelpValues[this.requestType];
  }

}