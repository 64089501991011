<div class="users-page-container">
  <h1>Registered Users</h1>
  <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z8" >
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let user">{{ user.email }}</td>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['firstName', 'lastName', 'email']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['firstName', 'lastName', 'email']">
      </mat-row>
  </table>
</div>
<div class="users-page-container">
  <h1>Assume Role</h1>
  <mat-checkbox color="primary" [(ngModel)]="assumeRole">Assume another role than 'Admin' (for testing purposes)</mat-checkbox>
  <mat-form-field>
    <mat-label>Select role to assume</mat-label>
    <mat-select [(ngModel)]="selectedRole" [disabled]="!assumeRole">
      <mat-option value="basic-user">Basic User</mat-option>
      <mat-option value="user">User</mat-option>
      <mat-option value="risk-manage-user">Risk Manage User</mat-option>
      <mat-option value="compliance-office-user">Compliance Office User</mat-option>
      <mat-option value="middle-office-user">Middle Office User</mat-option>
      <mat-option value="finance-user">Finance User</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="saveAssumeRole()">Save</button>
</div>