<form [formGroup]="form">
    <div class="grid-container">
        <div class="grid-item" *ngIf="!isTechChange()">
            <div fxLayout="column">
                <input type="text" matInput placeholder="Search markets" [formControl]="marketControl" 
                    (input)="onSearchMarket($event)" *ngIf="!isDisabled('market') && form.get('market')?.enabled">
                <mat-form-field *ngIf="!isDisabled('market')">
                    <mat-label>Markets</mat-label>
                    <mat-select multiple formControlName="market">
                      <mat-option *ngFor="let val of marketOptions" [value]="val"
                      [ngStyle]="{ display : isMarketOptionDisplayed(val) ? 'flex' : 'none' }">
                        {{val}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('productGroup')">
                    <mat-label>Product group</mat-label>
                    <mat-select formControlName="productGroup">
                        <mat-option *ngFor="let val of ProductGroup" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('product') && !isNewRequest()">
                    <mat-label>Product</mat-label>
                    <mat-select formControlName="product">
                      <mat-option *ngFor="let val of Product" [value]="val">
                        {{val}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!isDisabled('product') && isNewRequest()">
                    <mat-label>Product</mat-label>
                    <input matInput formControlName="product">
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('classification')">
                    <mat-label>Classification</mat-label>
                    <mat-select formControlName="classification">
                        <mat-option *ngFor="let val of Classification" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">    
                <mat-form-field *ngIf="!isDisabled('direction')">
                    <mat-label>Buy / Sell</mat-label>
                    <mat-select formControlName="direction">
                        <mat-option *ngFor="let val of Direction" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('instrument')">
                    <mat-label>Instrument</mat-label>
                    <mat-select multiple formControlName="instrument">
                        <mat-option *ngFor="let val of Instrument" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="grid-item" *ngIf="!isTechChange()">
            <div fxLayout="column">
                <mat-form-field *ngIf="form?.get('instrument')?.value === 'Option' && !isDisabled('optionMarginType')">
                    <mat-label>Option Margin Type</mat-label>
                    <mat-select multiple formControlName="optionMarginType">
                        <mat-option *ngFor="let val of OptionMarginType" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('commodity')">
                    <mat-label>Commodity</mat-label>
                    <mat-select formControlName="commodity">
                        <mat-option *ngFor="let val of Commodity" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('tradedVia')">
                    <mat-label>Traded via</mat-label>
                    <mat-select multiple formControlName="tradedVia">
                        <mat-option *ngFor="let val of TradedVia" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('platform')">
                    <mat-label>Platform</mat-label>
                    <mat-select multiple formControlName="platform">
                        <mat-option *ngFor="let val of Platform" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('settlement')">
                    <mat-label>Settlement</mat-label>
                    <mat-select multiple formControlName="settlement">
                        <mat-option *ngFor="let val of Settlement" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="isTenorDisplayed() && !isDisabled('tenor')">
                    <mat-label>Tenor</mat-label>
                    <mat-select multiple formControlName="tenor">
                        <mat-option *ngFor="let val of Tenor" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('profile')">
                    <mat-label>Profile</mat-label>
                    <mat-select multiple formControlName="profile">
                        <mat-option *ngFor="let val of Profile" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="grid-item">
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('currency')">
                    <mat-label>Currency</mat-label>
                    <mat-select multiple formControlName="currency">
                        <mat-option *ngFor="let val of Currency" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('unit')">
                    <mat-label>Unit</mat-label>
                    <mat-select multiple formControlName="unit">
                        <mat-option *ngFor="let val of Unit" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="form?.get('tradedVia')?.value.includes('Exchange') && !isDisabled('exchangeProductCode')">
                    <mat-label>Exchange Product Code</mat-label>
                    <input matInput formControlName="exchangeProductCode">
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="form?.get('tradedVia')?.value.includes('Exchange') && !isDisabled('exchangeProductName')">
                    <mat-label>Exchange Product Name</mat-label>
                    <input matInput formControlName="exchangeProductName">
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('marketAccess')">
                    <mat-label>Market Access</mat-label>
                    <mat-select multiple formControlName="marketAccess">
                        <mat-option *ngFor="let val of MarketAccess" [value]="val">
                            {{val}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="column">
                <mat-form-field *ngIf="!isDisabled('usedPortfolios')">
                    <mat-label>Used Portfolios</mat-label>
                    <input matInput formControlName="usedPortfolios">
                </mat-form-field>
            </div>
        </div>
    </div>
    <rich-text-editor #productDefinition *ngIf="model && !isDisabled('productDefinition')" [metadata]="getRichTextMetadata('productDefinition')" title="Product Definition *" 
        [help]="productDefinitionHelp" [npcId]="npcId" [sectionId]="sectionId" fieldId="productDefinition" [isReadOnly]="isReadOnly('productDefinition')"
        (saveStatus)="onRichTextFieldSaveStatus($event, 'productDefinition')" (getStatus)="onRichTextFieldGetStatus($event, 'productDefinition')"
        [isDisplayed]="true" [isRequired]="true">
    </rich-text-editor>
    <rich-text-editor #marketPotential *ngIf="model && !isDisabled('marketPotential')" [metadata]="getRichTextMetadata('marketPotential')" title="Market Potential *" 
        [npcId]="npcId" [sectionId]="sectionId" fieldId="marketPotential" [isReadOnly]="isReadOnly('marketPotential')"
        (saveStatus)="onRichTextFieldSaveStatus($event, 'marketPotential')" (getStatus)="onRichTextFieldGetStatus($event, 'productDefinition')"
        [isDisplayed]="true" [isRequired]="true">
    </rich-text-editor>
    <npc-attachments #attachments [metadata]="getAttachmentMetadata()" [npcId]="npcId" [sectionId]="sectionId" [isReadOnly]="isReadOnly('attachments')"
        (saveAttachmentsResponse)="handleSaveAttachmentsResponse($event)"></npc-attachments>
</form>