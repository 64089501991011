<div *ngIf="product">
    <h2>Product Detail</h2>
    <table>
      <tbody>
        <tr><td>Buy/Sell</td><td>{{ product.buySell }}</td></tr>
        <tr><td>Classification</td><td>{{ product.classification }}</td></tr>
        <tr><td>Comment</td><td>{{ product.comment }}</td></tr>
        <tr><td>Commodity</td><td>{{ product.commodity }}</td></tr>
        <tr><td>Currency</td><td>{{ product.currency }}</td></tr>
        <tr><td>Exchange Product Code</td><td>{{ product.exchangeProductCode }}</td></tr>
        <tr><td>Exchange Product Name</td><td>{{ product.exchangeProductName }}</td></tr>
        <tr><td>ID</td><td>{{ product.id }}</td></tr>
        <tr><td>Instrument</td><td>{{ product.instrument }}</td></tr>
        <tr><td>Legal Entity</td><td>{{ product.legalEntity }}</td></tr>
        <tr><td>Market</td><td>{{ product.market }}</td></tr>
        <tr><td>Market Access</td><td>{{ product.marketAccess }}</td></tr>
        <tr><td>Name</td><td>{{ product.name }}</td></tr>
        <tr><td>NPC ID</td><td>{{ product.npcId }}</td></tr>
        <tr><td>Option Margin Type</td><td>{{ product.optionMarginType }}</td></tr>
        <tr><td>Platform</td><td>{{ product.platform }}</td></tr>
        <tr><td>Product</td><td>{{ product.product }}</td></tr>
        <tr><td>Product Group</td><td>{{ product.productGroup }}</td></tr>
        <tr><td>Product ID</td><td>{{ product.productId }}</td></tr>
        <tr><td>Profile</td><td>{{ product.profile }}</td></tr>
        <tr><td>Settlement</td><td>{{ product.settlement }}</td></tr>
        <tr><td>Status</td><td>{{ product.status }}</td></tr>
        <tr><td>Tenor</td><td>{{ product.tenor }}</td></tr>
        <tr><td>Traded Via</td><td>{{ product.tradedVia }}</td></tr>
        <tr><td>Unit</td><td>{{ product.unit }}</td></tr>
        <tr><td>Used MX Instrument</td><td>{{ product.usedMXInstrument }}</td></tr>
        <tr><td>Validity Start Date</td><td>{{ product.startValidityDate }}</td></tr>
        <tr><td>Validity End Date</td><td>{{ product.endValidityDate }}</td></tr>
      </tbody>
    </table>
  </div>
  