import { Component, ViewChild, OnInit } from '@angular/core';
import { AttachmentMetadata, SaveAttachmentsResponse } from './attachment.types';
import { AttachmentComponent } from './attachment.component';

@Component({
  selector: 'npc-attachments-demo',
  templateUrl: './attachment-page-demo.component.html',
  styleUrls: []
})
export class AttachmentPageDemoComponent implements OnInit {
  npcId = '17c02659-0367-4711-908c-351960b51c3b'; // ID of a valid test NPC
  sectionId = 'product-description'
  message?: string;
  attachments: AttachmentMetadata[] = [];
  
  @ViewChild(AttachmentComponent) attachmentComponent!: AttachmentComponent;

  ngOnInit(): void {
    const cachedAttachments = localStorage.getItem(`${this.npcId}_attachments`);
    if (cachedAttachments) {
      this.attachments = JSON.parse(cachedAttachments);
    }
  }

  onSave(): void {
    this.message = "Saving attachments...";
    this.attachmentComponent.saveAttachments();
  }

  handleSaveAttachmentsResponse(response: SaveAttachmentsResponse): void {
    this.message = response.isSuccessful ? 'Attachments saved successfully' : 'Failed to save attachments';
    console.log(this.message);
    this.attachments = response.attachments;
    localStorage.setItem(`${this.npcId}_attachments`, JSON.stringify(this.attachments));
  }

  public displayAttachments(): string {
    return JSON.stringify(this.attachments, null, 2);
  }
}