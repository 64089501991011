import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

import { ProductCreate, Product, PRODUCT_UNIQUENESS_DEFAULT_FIELDS, PRODUCT_UNIQUENESS_SELECTABLE_FIELDS,
  REQUEST_TO_PRODUCT_MAPPING, PRODUCTS_FIELDS_NULL_TO_NON_APPLICABLE, NOT_APPLICABLE, PRODUCT_STATUSES, ProductUi, ProductsDownload } from '../../../product-catalogue/product';
import { ProductListService } from '../../../product-catalogue/product-list.service';
import { Subscription, catchError, forkJoin, of } from 'rxjs';
import { getApiErrorMessage } from 'src/app/utils/api-error';
import { DialogHelpComponent } from 'src/app/dialog-help/dialog-help.component';
import { MatDialog } from '@angular/material/dialog';
import { Market } from '../../model/market';

interface NpcRequest {
  [key: string]: string | string[];
}

interface ProductsCombinations {
  singleValueFields: string[];
  multiValuesFields: string[];
  products: ProductCreate[];
}

// function below used for mocking the NPC request
/*
function loadJSON(filePath: string): string {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', filePath, false);
  xhr.send();
  if (xhr.readyState === 4 && xhr.status === 200) {
    return xhr.responseText;
  } else {
    throw new Error(`Failed to load file: ${filePath}`);
  }
}*/

@Component({
  selector: 'app-product-checker',
  templateUrl: './check-product.component.html',
  styleUrls: ['./check-product.component.css']
})
export class ProductCheckerComponent implements OnInit, OnDestroy {
  npcRequest?: NpcRequest = undefined;
  requestProducts: ProductCreate[] = [];
  selectedFields: string[] = PRODUCT_UNIQUENESS_DEFAULT_FIELDS.slice();
  allFields: string[] = PRODUCT_UNIQUENESS_SELECTABLE_FIELDS;
  fields: string[] = [];
  optionFields: string[] = [];
  multiValuesFields: string[] = [];
  singleValueFields: string[] = [];
  processing = false;
  existingProducts: string[][] = [];
  npcId?: string;
  editableFields: string[] = ['comment', 'status', 'startValidityDate', 'endValidityDate'];
  errors: (string | undefined)[] = [];
  selectedProductsIndexes: number[] = [];
  isCreate: boolean = false;
  showError: boolean[] = [];
  isSuccess: boolean | undefined = undefined;
  public productStatuses: string[] = PRODUCT_STATUSES;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private productsListService: ProductListService, private dialog: MatDialog) { 
    this.npcRequest = this.router.getCurrentNavigation()?.extras.state as NpcRequest; 
    //this.npcRequest = JSON.parse(loadJSON('assets/test2.json'));  // TODO remove this and referenced files, used for mocking
    this.setAvailableFields();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams
        .subscribe(params => {
          if (params) {
            if (params['npcId'] !== undefined) {
              this.npcId = params['npcId'] as string;
            }
            this.isCreate = params['action'] === 'create';
          }
        })
    );
    if (this.npcRequest) {
      const productCombinations: ProductsCombinations = this.requestToProductMapping();
      this.requestProducts = productCombinations.products;
      this.multiValuesFields = productCombinations.multiValuesFields;
      this.singleValueFields = productCombinations.singleValueFields.filter(field => !this.editableFields.includes(field));
      this.existingProducts = [];
      for (let i=0; i<this.requestProducts.length; i++) {
        this.existingProducts[i] = [];
        this.errors[i] = undefined;
      }
      this.checkProducts();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public get fieldControl(): FormControl {
    return new FormControl();
  }

  public setAvailableFields(): void {
    this.fields = this.allFields.filter(field => !this.selectedFields.includes(field));
  }

  public addField(field: string): void {
    if (!this.selectedFields.includes(field)) {
      this.selectedFields.push(field);
      this.fields.splice(this.fields.indexOf(field), 1);
      this.fieldControl.setValue('');
      this.setAvailableFields();
      this.checkProducts();
    }
  }

  public removeField(field: string): void {
    if (this.selectedFields.includes(field)) {
      this.selectedFields.splice(this.selectedFields.indexOf(field), 1);
      this.fields.push(field);
      this.setAvailableFields();
      this.checkProducts();
    }
  }

  public cancel(): void {
    this.router.navigate(['/npc-request'],
    { queryParams: { action: 'view', npcId: this.npcId } });
  }

  public checkProducts(): void {
    this.processing = true;
    this.subscriptions.push(
      this.productsListService.getProducts(true).subscribe((download: ProductsDownload) => {
        for (let i=0; i<this.requestProducts.length; i++) {
          const requestProduct: ProductCreate = this.requestProducts[i];
          const existingProducts: string[] = download.products
            .filter((product: ProductUi) => this.isProductMatching(product, requestProduct))
            .map((product: ProductUi) => product.id);
          this.existingProducts[i] = existingProducts;
        }
        this.processing = false;
      })
    );
  }

  private isProductMatching(product1: Product, product2: ProductCreate): boolean {
    for (const field of this.selectedFields) {
      if (product1[field] !== product2[field]) {
        return false;
      }
    }
    return true;
  }

  private convertNullFields(fieldId: string, fieldValue: any | null): any | null {
    if (fieldValue === null) {
      if (PRODUCTS_FIELDS_NULL_TO_NON_APPLICABLE.includes(fieldId)) {
        return NOT_APPLICABLE;
      } else {
        return null;
      }
    } else {
      return fieldValue;
    }
  }

  private requestToProductMapping(): ProductsCombinations {
    if (!this.npcRequest) {
      return { singleValueFields: [], multiValuesFields: [], products: [] };
    }
    const products: ProductCreate[] = [{} as ProductCreate];
    const singleValueFields: string[] = [];
    const multiValuesFields: string[] = [];
  
    for (const section in REQUEST_TO_PRODUCT_MAPPING) {
      const sectionMapping = REQUEST_TO_PRODUCT_MAPPING[section];
      for (const field in sectionMapping) {
        let productField: string = "";
        let requestValue: any;
        if (section === 'other') {
          productField = field;
          requestValue = this.convertNullFields(productField, sectionMapping[field]);
        } else {
          productField = sectionMapping[field];
          requestValue = this.convertNullFields(
            productField,
            this.npcRequest[section as keyof NpcRequest]?.[field as keyof NpcRequest[keyof NpcRequest]] as string | string[] | undefined
          );
        }
        if (requestValue !== undefined && requestValue !== null) {
          
          if (section === 'productDescription' && field === 'market' && Array.isArray(requestValue)) {
            // specific handling is needed for market field, as marketDetail is a nested array within market
            const marketsValues: string[][] = requestValue.flatMap((m: Market) => {
              return m.marketDetail.map((md: string) => [m.market, md]);
            });
            if (marketsValues.length > 1) {
              multiValuesFields.push('market');
              multiValuesFields.push('marketDetail');
              const newProducts: ProductCreate[] = [];
              for (const value of marketsValues) {
                for (const product of products) {
                  const newProduct = { ...product };
                  newProduct['market'] = value[0];
                  newProduct['marketDetail'] = value[1];
                  newProducts.push(newProduct);
                }
              }
              products.splice(0, products.length, ...newProducts);
            } else if (marketsValues.length === 1) {
              singleValueFields.push('market');
              singleValueFields.push('marketDetail');
              for (const product of products) {
                product['market'] = marketsValues[0][0];
                product['marketDetail'] = marketsValues[0][1];
              }
            }
          } else if (Array.isArray(requestValue) && requestValue.length > 1 && productField !== 'mandates') {
            multiValuesFields.push(productField);
            const newProducts: ProductCreate[] = [];
            for (const value of requestValue) {
              for (const product of products) {
                const newProduct = { ...product };
                newProduct[productField] = value;
                newProducts.push(newProduct);
              }
            }
            products.splice(0, products.length, ...newProducts);
          } else {
            if (Array.isArray(requestValue) && productField !== 'mandates') {
              requestValue = requestValue[0];
            }
            singleValueFields.push(productField);
            for (const product of products) {
              product[productField] = requestValue;
            }
          }
        }
      }
    }
    // add npcId and name
    for (const product of products) {
      product.npcId = this.npcId!;
      product.name = uuidv4();
    }
    const resultCombinations: ProductsCombinations = { singleValueFields, multiValuesFields, products };
    return resultCombinations;
  }

  public hasErrors(): boolean {
    return this.errors.some((error: any | undefined) => error !== undefined);
  }

  public createProducts(): void {
    this.processing = true;

    //re-initialize errors array
    this.errors = this.requestProducts.map(() => undefined);

    if (this.requestProducts.length === 1) {
      this.selectedProductsIndexes = [0];
    }

    const observables = this.selectedProductsIndexes
      .map((index: number) => {
        const product: ProductCreate = this.requestProducts[index];
        return this.productsListService.createProduct(product)
          .pipe(catchError(error => {
            console.error(`Error occurred when trying to create Product: ${error}`);
            this.errors[index] = getApiErrorMessage(error);
            return of(undefined);
          }))
      });
    this.subscriptions.push(
      forkJoin(observables).subscribe({
        next: (products: (Product | undefined)[]) => {
          for (let i = 0; i < products.length; i++) {
            if (products[i] !== undefined) {
              this.existingProducts[i].push(products[i]!.id);
            }
          }
        },
        error: (error: any) => {
          // it should not happen as errors are handled in the each observable
          console.error(error)
        },
        complete: () => {
          this.processing = false;
          this.isSuccess = this.hasErrors() ? false : true;
          this.checkProducts();
          this.selectedProductsIndexes = [];
        }
      })
    );
  }

  public onProductSelect(event: any, index: number): void {
    if (event.target.checked && !this.selectedProductsIndexes.includes(index)) {
      this.selectedProductsIndexes.push(index);
    } else {
      const i = this.selectedProductsIndexes.indexOf(index);
      if (i !== -1) {
        this.selectedProductsIndexes.splice(i, 1);
      }
    }
  }

  public getErrorTooltip(product: any, field: string, index: number): any {
    if (this.showError[index] && this.errors.length > index && this.errors[index] !== undefined) {
      return this.errors[index];
    } else {
      return product[field];
    }
  }
  
  public onRowMouseOver(event: MouseEvent, index: number): void {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'INPUT') {
      this.showError[index] = true;
    }
  }
  
  public onRowMouseOut(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'INPUT') {
      this.showError.fill(false);
    }
  }

  public viewProduct(event: any, productIds: string[]): void {
    event.preventDefault();
    if (productIds.length === 0) {
      return;
    }
    if (productIds.length === 1) {
      const url = this.router.createUrlTree(['/product-viewer'], { queryParams: { id: productIds[0] } });
      window.open(url.toString(), '_blank');
    } else {
      this.displayMultipleExistingProducts(productIds);
    }
  }

  private displayMultipleExistingProducts(productIds: string[]): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, { data: { 
      title: `${productIds.length} similar existing Products found!` 
    } });
    let contentLinks: string = productIds.reduce((acc: string, id: string) => {
      const url: string = this.router.createUrlTree(['/product-viewer'], { queryParams: { id } }).toString();
      return acc += `<li><a href="${url}" target="_blank">${id}</a></li>`;
    }, '<ul>') + '</ul>';
    dialogRef.componentInstance.htmlContent = contentLinks;
  }

  public getCommonFieldValue(field: string): string | null {
    if (this.requestProducts.length === 0) {
      return null;
    }
    const value = this.requestProducts[0][field];
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value;
  }

}