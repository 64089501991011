<div class="page-container">
  <h1>NPC Requests</h1>
  <br>
  <div class="link-new-request">
    <a mat-button href="npc-request?action=new" *ngIf="canCreate">Create new Request</a>
  </div>
  <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z8 request-table">
      <ng-container matColumnDef="productNumber">
        <mat-header-cell *matHeaderCellDef>NPC Request Number</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.productNumber}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="productName">
          <mat-header-cell *matHeaderCellDef>NPC Title</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.productName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="npcType">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.npcType}}</mat-cell>
    </ng-container>
      <ng-container matColumnDef="authorCreation">
          <mat-header-cell *matHeaderCellDef>Applicant Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.authorCreation}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.status}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['productNumber', 'productName', 'npcType', 'authorCreation', 'status']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['productNumber', 'productName', 'npcType', 'authorCreation', 'status']"
        (click)="viewRequest(row)">
      </mat-row>
  </table>
</div>