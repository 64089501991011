<table class="selectedResponsibilities">
    <tr>
        <th>Process area</th>
        <th>Responsible</th>
        <th>Tasks</th>
    </tr>
    <tr *ngFor="let responsibility of responsibilityTasks">
        <td>
            {{responsibility.team}}
        </td>
        <td>
            {{responsibility.location}}
        </td>
        <td>
            <ul>
                <li *ngFor="let task of responsibility.tasks">
                    {{task}}
                    <span *ngIf="!readOnlyMode"> - </span>
                    <a (click)="remove(responsibility.location, responsibility.team, task)" *ngIf="!readOnlyMode"> 
                        <mat-icon style="font-size: small;">delete</mat-icon> Remove </a>
                </li>
            </ul>
        </td>
    </tr>
</table>

<form [formGroup]="form" *ngIf="!readOnlyMode">
    <table>
        <tr>
            <td>
                <mat-form-field>
                    <mat-label>Process area</mat-label>
                    <input matInput formControlName="processArea">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <mat-label>Responsible</mat-label>
                    <input matInput formControlName="responsible">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <mat-label>Task</mat-label>
                    <input matInput formControlName="task">
                </mat-form-field>
            </td>
            <td>
                <button mat-button (click)="add()" *ngIf="canBeAdded()">Add</button>
            </td>
        </tr>
    </table>
</form>
        
