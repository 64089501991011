import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Classification } from '../model/enum/classification';
import { Direction } from '../model/enum/direction';
import { Instrument } from '../model/enum/instrument';
import { PlatformExchange } from '../model/enum/platform-exchange';
import { PlatformAuction } from '../model/enum/platform-auction';
import { PlatformOtc } from '../model/enum/platform-otc';
import { Product } from '../model/enum/product';
import { ProductGroup } from '../model/enum/product-group';
import { Profile } from '../model/enum/profile';
import { Settlement } from '../model/enum/settlement';
import { Tenor } from '../model/enum/tenor';
import { TradedVia } from '../model/enum/traded-via';
import { Unit } from '../model/enum/unit';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { RichTextEditorComponent } from 'src/app/rich-text-editor/rich-text-editor.component';
import { OptionMarginType } from '../model/enum/option-margin-type';
import { Commodity } from '../model/enum/commodity';
import { Currency } from '../model/enum/currency';
import { MarketAccess } from '../model/enum/market-access';
import { RichTextField } from 'src/app/rich-text-editor/rich-text-editor.types';
import { AttachmentComponent } from 'src/app/attachment/attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { ListsPerRequestType, RequestType } from '../npc-request-types';
import { Markets } from '../model/enum/market';
import { Market } from '../model/market';

function optionMarginTypeValidator(form: FormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const needRequire = form.get('instrument')?.value === 'Option'
    const noValue = needRequire ? !(control.value) || control.value.length === 0 : false;
    return noValue ? {required: control.value} : null;
  };
}

function exchangeProductValidator(form: FormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const tradedVia = form.get('tradedVia')?.value;
    const needRequire = Array.isArray(tradedVia) && tradedVia.includes('Exchange');
    const noValue = needRequire ? !(control.value) || control.value === null || (Array.isArray(control.value) && control.value.length === 0) : false;
    return noValue ? {required: control.value} : null;
  };
}

function tenorValidator(form: FormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const tradedVia = form.get('tradedVia')?.value;
    const needRequire = Array.isArray(tradedVia) && tradedVia.some((val: string) => tradedViaValuesForTenor.includes(val));
    const noValue = needRequire ? !(control.value) || control.value === null || (Array.isArray(control.value) && control.value.length === 0) : false;
    return noValue ? {required: control.value} : null;
  };
}

const tradedViaValuesForTenor: string[] = ['Exchange', 'Auction'];

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css']
})
export class ProductDescriptionComponent extends NpcRequestSectionBase implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('productDefinition') productDefinitionEditorComponent!: RichTextEditorComponent;
  @ViewChild('marketPotential') marketPotentialEditorComponent!: RichTextEditorComponent;
  @ViewChild('attachments') attachmentComponent!: AttachmentComponent;

  public productDefinitionHelp = "<p>Which product(s) are being developed? Short description. Detailed description in the appendix if needed.</p><p>Price Feeds and Specs:</p><p>Link to Price Feeds and Specs:</p><p>Platforms & Exchanges:</p><p>Link to Product on Exchange (if applicable):<BR>When do you want to start trading with this new product?</p>";
  public marketOptions: string[] = [];
  public ProductGroup: string[] = ProductGroup.sort();
  public Product: string[] = Product.sort();
  public Classification: string[] = Classification.sort();
  public Direction: string[] = Direction.sort();
  public Instrument: string[] = Instrument.sort();
  public Platform: string[] = ['Not applicable'];
  public Profile: string[] = Profile.sort();
  public Settlement: string[] = Settlement.sort();
  public Tenor: string[] = Tenor.sort();
  public TradedVia: string[] = TradedVia.sort();
  public Unit: string[] = Unit.sort();
  public OptionMarginType: string[] = OptionMarginType.sort();
  public Commodity: string[] = Commodity.sort();
  public Currency: string[] = Currency.sort();
  public MarketAccess: string[] = MarketAccess.sort();
  public MarketMarketDetails: string[] = [];
  public filteredMarketOptions: string[] = [];
  public marketControl: FormControl = new FormControl([], []);

  constructor(protected override dialog: MatDialog) {
    const readonlyFields: ListsPerRequestType = {
      [RequestType.NEW_REQUEST]: [],
      [RequestType.EXTENSION]: ['productGroup', 'product', 'classification', 'instrument', 'commodity', 'tradedVia', 'platform'],
      [RequestType.TECH_CHANGE]: [],
    };
    const disabledFields: ListsPerRequestType = {
      [RequestType.NEW_REQUEST]: [],
      [RequestType.EXTENSION]: [],
      [RequestType.TECH_CHANGE]: [],
    };
    super(dialog, readonlyFields, disabledFields);
    this.marketOptions = Markets.flatMap((m: Market) => {
      return m.marketDetail.map((md: string) => {
        if (m.market === md) {
          return md;
        }
        return `${m.market} - ${md}`;
      });
    });
    this.filteredMarketOptions = this.marketOptions;
  }

  ngOnInit(): void {
    
    this.fields = {
      market: new FormControl(null, [Validators.required]),
      productGroup: new FormControl(null, [Validators.required]),
      product: new FormControl(null, [Validators.required]),
      classification: new FormControl(null, [Validators.required]),
      direction: new FormControl(null, [Validators.required]),
      instrument: new FormControl(null, [Validators.required]),
      optionMarginType: new FormControl(null, []),
      commodity: new FormControl(null, [Validators.required]),
      tradedVia: new FormControl(null, [Validators.required]),
      platform: new FormControl(null, [Validators.required]),
      settlement: new FormControl(null, [Validators.required]),
      tenor: new FormControl(null, []),
      profile: new FormControl(null, [Validators.required]),
      currency: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      exchangeProductCode: new FormControl(null, []),
      exchangeProductName: new FormControl(null, []),
      mxInstrument: new FormControl(null, []),
      underlyingMxIndexes: new FormControl(null, []),
      mdcIds: new FormControl(null, []),
      productUid: new FormControl(null, []),
      marketAccess: new FormControl(null, [Validators.required]),
      usedPortfolios: new FormControl(null, []),
    };
    const form: FormGroup = new FormGroup(this.fields);
    
    form.get('optionMarginType')?.addValidators(optionMarginTypeValidator(form));
    if (form.get('instrument')) {
      this.subscriptions.push(
        form.get('instrument')!.valueChanges.subscribe(x => {
          this.form.get('optionMarginType')?.updateValueAndValidity(); // when 'instrument' is changed, we must re-evaluate the validity of 'optionMarginType'
        })
      )
    }
    form.get('exchangeProductCode')?.addValidators(exchangeProductValidator(form));
    form.get('exchangeProductName')?.addValidators(exchangeProductValidator(form));
    form.get('tenor')?.addValidators(tenorValidator(form));
    if (form.get('tradedVia')) {
      this.subscriptions.push(
        form.get('tradedVia')!.valueChanges.subscribe(tradedViaVal => {
          if (!Array.isArray(tradedViaVal) || !tradedViaVal.some(val => tradedViaValuesForTenor.includes(val))) {
            // reset values which are not applicable when 'tradedVia' is not 'Exchange' or 'Auction'
            form.get('exchangeProductCode')?.setValue(null);
            form.get('exchangeProductName')?.setValue(null);
            form.get('tenor')?.setValue(['Not applicable']);
          } else {
            const tenorValue = form.get('tenor')?.value;
            if (tenorValue && tenorValue.includes('Not applicable')) {
              const newValue = tenorValue.filter((value: string) => value !== 'Not applicable');
              form.get('tenor')?.setValue(newValue);
            }
          }
          this.form.get('exchangeProductCode')?.updateValueAndValidity(); // when 'tradedVia' is changed, we must re-evaluate the validity of 'exchangeProductCode'
          this.form.get('exchangeProductName')?.updateValueAndValidity(); // when 'tradedVia' is changed, we must re-evaluate the validity of 'exchangeProductName'
          this.form.get('tenor')?.updateValueAndValidity(); // when 'tradedVia' is changed, we must re-evaluate the validity of 'tenor'

          // update the list of available options for Platform
          if (!Array.isArray(tradedViaVal) || tradedViaVal.length === 0) {
            this.Platform = ['Not applicable'];
          } else {
            this.Platform = [];
            if (tradedViaVal.includes('Exchange')) {
              this.Platform.push(...PlatformExchange);
            }
            if (tradedViaVal.includes('OTC - Bilateral')) {
              this.Platform.push(...PlatformOtc);
            }
            if (tradedViaVal.includes('Auction')) {
              this.Platform.push(...PlatformAuction);
            }
          }
        })
      );
    }
    this.form = form;
    this.setFormFromModel();
  }
  
  ngOnDestroy(): void {
    this.unsubscribe();
  }

  ngAfterViewInit(): void {
    const richTextFields: RichTextField[] = [
      {
        fieldId: 'productDefinition',
        component: this.productDefinitionEditorComponent,
        isMandatory: true
      },
      {
        fieldId: 'marketPotential',
        component: this.marketPotentialEditorComponent,
        isMandatory: true,
        defaultTemplatePath: 'assets/rich-text-templates/market_potential_template.html'
      }
    ];
    this.richTextFields = richTextFields;
    
    this.attachmentsField = { component: this.attachmentComponent };

    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

  private getMarketDetails(): Market[] {
    const marketDetails: Market[] = [];
    const marketValues = this.form.get('market')?.value;
    if (Array.isArray(marketValues)) {
      marketValues.reduce((acc: Market[], marketValue: string) => {
        const components: string[] = marketValue.split(' - ');
        const market: string = components[0];
        let marketDetail: string = market;
        let marketItem: Market;
        if (components.length === 2) {
          marketDetail = components[1];
        }
        const marketItemIndex: number = acc.findIndex((m: Market) => m.market === market);
        if (marketItemIndex === -1) {
          marketItem = { market, marketDetail: [marketDetail] };
          acc.push(marketItem);
        } else {
          marketItem = acc[marketItemIndex];
          marketItem.marketDetail.push(marketDetail);
        }
        return acc;
      }
      , marketDetails);
    }
    return marketDetails;
  }

  protected override getFormValue(): any {
    const formValue: any = this.form.getRawValue();
    return { 
      ...formValue, 
      optionMarginType: Array.isArray(this.form.get('optionMarginType')?.value) && this.form.get('optionMarginType')?.value.length > 0 ?
        this.form.get('optionMarginType')?.value : 
        ['Not applicable'],
      market: this.getMarketDetails(),
      exchangeProductCode: this.form.get('exchangeProductCode')?.value ? [this.form.get('exchangeProductCode')?.value]: [],
      exchangeProductName: this.form.get('exchangeProductName')?.value ? [this.form.get('exchangeProductName')?.value]: []
    };
  }

  protected override getValueFromModel(fieldName: string): any {
    if (this.model && this.model.hasOwnProperty(fieldName)) {
      if (!['market', 'exchangeProductCode', 'exchangeProductName'].includes(fieldName)) {
        return (this.model as any)[fieldName];
      }
      if (fieldName === 'exchangeProductCode' || fieldName === 'exchangeProductName') {
        const value: string[] = (this.model as any)[fieldName];
        return value.length > 0 ? value[0] : null;
      }
      // fieldName === 'market'
      const value: Market[] = (this.model as any)['market'];
      return value.flatMap((m: Market) => {
        return m.marketDetail.map((md: string) => {
          if (m.market === md) {
            return md;
          }
          return `${m.market} - ${md}`;
        });
      });
    }
    return undefined;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const result: string[] = this.marketOptions.filter(option => option.toLowerCase().includes(filterValue));
    return result;
  }

  public onSearchMarket(event?: any) {
    if (event?.target?.value) {
      this.filteredMarketOptions = this._filter(event.target.value);
    } else {
      this.filteredMarketOptions = this.marketOptions;
    }
  }

  public isMarketOptionDisplayed(option: string): boolean {
    return this.filteredMarketOptions.includes(option);
  }

  public isTenorDisplayed(): boolean {
    return Array.isArray(this.form.get('tradedVia')?.value)  
        && this.form.get('tradedVia')?.value.some((val: string) => tradedViaValuesForTenor.includes(val));
  }
}