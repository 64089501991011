export const Tenor: string[] = [
    "Not applicable",
    "Weekly",
    "Weekend",
    "Monthly",
    "Quarterly",
    "Yearly",
    "Daily",
    "Season",
    "Day-ahead",
    "Intraday"
];