import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-column-config-dialog',
  templateUrl: './column-config-dialog.component.html',
  styleUrls: ['./column-config-dialog.component.css']
})
export class ColumnConfigDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ColumnConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { columns: any[] }
  ) {}

  columns = this.data.columns;

  onCancelClick(): void {
    this.dialogRef.close();
  }
}