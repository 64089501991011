import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { NpcRequestService } from "../../npc-request.service";
import { Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

import { getApiErrorMessage } from '../../../utils/api-error';
import { ApprovalRequest } from "../../model/npc-request";
import { User } from "../../../users/users-types";
import { UsersService } from "src/app/users/users.service";
import { Subscription } from "rxjs";

export interface RequestApprovalDialogData {
  action: string;
  approvalType: string;
  npcId: string;
  productNumber: string;
}

@Component({
  selector: 'request-approval-dialog',
  templateUrl: 'request-approval-dialog.component.html',
  styleUrls: ['./request-approval-dialog.component.css']
})
export class RequestApprovalDialogComponent implements OnInit, OnDestroy {
  public action?: string;
  public approvalType?: string;
  public npcId?: string;
  public productNumber?: string;
  public productName?: string;
  public error?: string;
  public approvalRequestForm: FormGroup;
  public approvers: User[] = [];
  public requestText = '';
  public requestor?: User;
  public isSaving: boolean = false;
  private subscriptions: Subscription[] = [];
 
  constructor(private npcRequestService: NpcRequestService, private router: Router, private route: ActivatedRoute,
    private usersService: UsersService) { 
      this.approvalRequestForm = new FormGroup({
        requestText: new FormControl(null, [Validators.required, this.minLengthValidator(10)]),
      });
  }

  private minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value.length < minLength) {
        return { 'minLength': { requiredLength: minLength, actualLength: value.length } };
      }
      return null;
    };
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params => {
        this.npcId = params['npcId'];
        this.productNumber = params['productNumber'];
        this.action = params['action'];
        this.approvalType = params['approvalType'];
        this.productName = params['productName'];

        this.subscriptions.push(
          this.usersService.getUser().subscribe({
            next: (user: User | undefined) => {
              this.requestor = user;
              this.setCustomText();
            },
            error: (error: any) => {
              console.error("Failed to get user", error);
            }
          })
        );
      }))
    );
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private setCustomText(): void {
    this.approvalRequestForm.get('requestText')!.setValue(this.getDefaultApprovalText());
  }

  private reroute(): void {
    this.router.navigate(['/npc-request'],
      { queryParams: { action: 'view', npcId: this.npcId } });
  }

  public cancel() {
    this.reroute();
  }

  public confirm() {
    if (!this.isValid()) {
      return;
    }
    const request: ApprovalRequest = {
      approvers: this.approvers.map((approver: User) => approver.email),
      approvalType: this.approvalType!,
      customText: this.approvalRequestForm.get('requestText')!.value
    }
    this.isSaving = true;
    this.subscriptions.push(
      this.npcRequestService.createApprovalRequest(this.npcId!, request).subscribe({
        next: () => {
          this.reroute();
          this.isSaving = false;
        },
        error: (error: any) => {
          console.error("Failed to send request approval", error);
          this.error = getApiErrorMessage(error);
          this.isSaving = false;
        }
      })
    );
  }

  public changeSelectedUsers(approvers: User[]) {
    this.approvers = approvers;
    this.setCustomText();
  }

  public isValid(): boolean {
    return this.approvalRequestForm.valid && Array.isArray(this.approvers) && this.approvers.length > 0;
  }

  private getTextBuHeadApproval(): string {
    const username = (this.approvers.length === 1) ? this.capitalize(this.approvers[0].firstName) : 'all';
    if (!this.requestor || !this.productName) {
      return '';
    }
    const requestorFirstName: string = this.capitalize(this.requestor.firstName);
    const requestorLastName: string = this.capitalize(this.requestor.lastName);
    return `Dear ${username},

We received a new NPC-request from ${requestorFirstName} ${requestorLastName} to implement ${this.productName}.
May we kindly ask you to approve the implementation of this request?

Thank you very much and have a great day.
`;
  }

  private getTextStakeholderApproval(): string {
    if (!this.requestor || !this.productName) {
      return '';
    }
    const requestorFirstName: string = this.capitalize(this.requestor.firstName);
    const requestorLastName: string = this.capitalize(this.requestor.lastName);
    return `Dear all,

May we kindly ask for your approval of the implementation for ${this.productName}.
For further information, you can find the NPC-Request in the attached link.

Thank you very much and have a great day.
`;
  }

  private getDefaultApprovalText(): string {
    if (this.approvalType === 'BU_HEAD_APPROVAL') {
      return this.getTextBuHeadApproval();
    }
    if (this.approvalType === 'STAKEHOLDER_APPROVAL') {
      return this.getTextStakeholderApproval();
    }
    return '';
  }

  private capitalize(str: string): string {
    // capitalize first letter
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}