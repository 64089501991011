
  <!--div style="width: 100px; position: absolute; top: 70px; right: 100px;">
    Status: 
    <button mat-raised-button color="primary">
        <mat-icon>done</mat-icon>
        Active
      </button>
    <span style="font-weight: 100; font-size: xx-small;">No deactivation date set.</span>
  </div-->

<div style="width: 70%; margin-left: 15%; margin-top: 20px;">
    <app-html-content path="assets/{{productName}}.html"></app-html-content>
    <!--div *ngIf="product">
        <h1>{{ product.name }}</h1>
        <div *ngFor="let feature of product.features">
          <h2>{{ feature.name }}</h2>
          <p>{{ feature.content }}</p>
        </div>
      </div-->
<br>
<br>
<br>

<!--h2><mat-icon>add_task</mat-icon>Related NPC requests</h2-->

</div>