<p>
  
  <mat-toolbar style="background-color: #052f43;">
    <img alt="Alpiq Logo" src="assets/alpiq_logo.svg" width="108" height="34"/>
    <span style="margin: 30px; color: aliceblue;">Alpiq Product Catalogue</span>
    <a mat-button style="color: aliceblue;" routerLink="/products" *ngIf="productsLink">Products</a>
    <a mat-button style="color: aliceblue;" routerLink="/npc-list" *ngIf="npcLink">Product Requests</a>
    <a mat-button style="color: aliceblue;" routerLink="/users" *ngIf="usersLink">Users</a>
  </mat-toolbar>
  <splash-screen></splash-screen>
  <router-outlet></router-outlet>
</p>

