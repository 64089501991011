import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NpcRequestSectionBase } from '../npc-request-section.base';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.css']
})
export class ValuationComponent extends NpcRequestSectionBase implements OnInit, OnChanges, AfterViewInit {

  constructor(protected override dialog: MatDialog) {
    super(dialog, undefined, undefined);
  }

  ngOnInit(): void {
    this.fields = {
      generalApproach : new FormControl(null, []),
      adjustments : new FormControl(null, []),
      impactOnReporting : new FormControl(null, []),
      impactOnFXManagement : new FormControl(null, [])
    };
    const form: FormGroup = new FormGroup(this.fields);
    this.form = form;
    this.setFormFromModel();
  }

  ngAfterViewInit(): void {
    this.applyEditPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPermissions']) {
      this.applyEditPermissions();
    }
  }

}