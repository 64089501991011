import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { Subscription } from "rxjs";
import { Product } from "../product";
import { AuthService } from "src/app/authentication/auth.service";
import { RequestType } from "src/app/npc-request/npc-request-types";

@Component({
  selector: 'product-viewer-dialog',
  templateUrl: 'product-viewer-dialog.component.html',
  styleUrls: ['./product-viewer-dialog.component.css']
})
export class ProductViewerDialogComponent implements OnDestroy, OnInit {
  public product?: Product;
  public id?: string;
  public canCreate: boolean = false;
  private subscriptions: Subscription[] = [];
 
  constructor(@Inject(MAT_DIALOG_DATA) public data: Product | string, public dialogRef: MatDialogRef<Product | string>,
    private authService: AuthService, private router: Router) {
      if (typeof data === 'string') {
        this.id = data;
      } else if (Array.isArray(data) && data.length > 0) {
        this.id = data[0];
      } else {
        this.product = data as Product;
      } 
  }

  ngOnInit() {
    // Check if user can create new NPC request
    this.subscriptions.push(
      this.authService.canCreateNpc().subscribe(canCreate => {
        this.canCreate = canCreate;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  public close() {
    this.dialogRef.close(false);
  }

  public create(isExtension: boolean) {
    const requestType: RequestType = isExtension ? RequestType.EXTENSION : RequestType.TECH_CHANGE;
    const requestTypeValue: string = requestType.toString();
    this.router.navigate(['/npc-request'], { queryParams: 
        { 
          action: 'new', 
          requestType: requestTypeValue,
          sourceProductId: this.id || this.product?.id,
        } 
    });
    this.dialogRef.close(true);
  }

}