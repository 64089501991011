const emptyRequestTechnicalChange: any = {
  "genericInfo": {
    "productName": null,
    "productOwner": null,
    "applicantName": null,
    "coordinatedBy": [],
    "technicalChangeTitle": null,
  },
  "conciseOfRequest": {
    "conciseOfRequest": null,
    "situation": {}
  },
  "productDescription": {
    "usedPortfolios": "",
    "technicalChangeDefinition": {},
    "improvementPotential": {},
    "attachments": []
  },
  "valuation": {
    "generalApproach": null,
    "adjustments": null,
    "impactOnReporting": null,
    "impactOnFXManagement": null
  },
  "dealEntryInstructions": {
    "dealTicketPrefix": null,
    "murexInstruments": [],
    "instructions": {}
  },
  "specificRemarks": {},
  "operationalResponsibilities": {
    "followStandardProcess": null,
    "confirmationNeeded": null,
    "newDealTicketPrefixNeeded": null,
    "remitReportNeeded": null,
    "roboReportNeeded": null,
    "responsibilities": []
  },
	"attachments": []
};
export {emptyRequestTechnicalChange};