export function getApiErrorMessage(error: any): string {
  let errorMessage: string;
  if (!error) {
    errorMessage = '';
  } else if (typeof error === 'object') {
    if (error?.error?.message) {
      errorMessage = error.error.message;
      if (error?.error?.path && Array.isArray(error.error.path) && error.error.path.length > 0) {
        errorMessage += ` (${error.error.path.join(' / ')})`;
      }
    } else if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error, null, 2);
    }
  } else {
    errorMessage = error;
  }
  return errorMessage;
};