<form [formGroup]="form">
    <div *ngIf="!isDisabled('TMB')">
        <h2>TMB Classification:</h2>
        <mat-selection-list formControlName="TMB">
            <mat-list-option color="primary" *ngFor="let c of tmbs" [value]="c.value">
                {{c.label}}
            </mat-list-option>
        </mat-selection-list>
        <mat-form-field class="form-element-full-width" *ngIf="TMBOtherSelected">
            <mat-label>Description of other classification</mat-label>
            <textarea matInput formControlName="TMBOther">
            </textarea>
        </mat-form-field>
    </div>
    <div *ngIf="!isDisabled('NTMB')">
        <h2>NTMB Classification:</h2>
        <mat-selection-list formControlName="NTMB">
            <mat-list-option color="primary" *ngFor="let c of ntmbs" [value]="c.value">
                {{c.label}}
            </mat-list-option>
        </mat-selection-list>
        <mat-form-field class="form-element-full-width" *ngIf="NTMBOtherSelected">
            <mat-label>Description of other classification</mat-label>
            <textarea matInput formControlName="NTMBOther">
            </textarea>
        </mat-form-field>
    </div>
    <div *ngIf="!isDisabled('mifid')" formGroupName="MIFID">
        <h2>MiFID Classification:</h2>
        <table>
            <tr class="radio-field" *ngFor="let c of mifids; let index = index">
              <td>
                <mat-label>{{c.label}}: </mat-label>
              </td>
              <td>
                <mat-radio-group [formControlName]="c.value">
                    <mat-radio-button color="primary" value="yes" [checked]="isMifidOptionSelected(c.value, 'yes')">Yes</mat-radio-button>
                    <mat-radio-button color="primary" value="no" [checked]="isMifidOptionSelected(c.value, 'no')">No</mat-radio-button>
                    <mat-radio-button color="primary" value="N/A" [checked]="isMifidOptionSelected(c.value, 'N/A')" *ngIf="index > 1">N/A</mat-radio-button>
                </mat-radio-group>
              </td>          
            </tr>
        </table>
    </div>
</form>