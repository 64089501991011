<div style="margin: 30px">
    <form [formGroup]="form">
        <div fxLayout="column">
            <mat-form-field *ngIf="!isNew && !isDisabled('productNumber')">
                <mat-label *ngIf="isNewRequest()">NPC Request number</mat-label>
                <mat-label *ngIf="isExtension()">NPC Request Number of Extension</mat-label>
                <mat-label *ngIf="isTechnicalChange()">Technical Change Number</mat-label>
                <input matInput formControlName="productNumber">
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field *ngIf="!isDisabled('productName')">
                <mat-label>NPC title</mat-label>
                <input matInput formControlName="productName">
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field *ngIf="!isDisabled('productOwner')">
                <mat-label>Product owner</mat-label>
                <mat-select formControlName="productOwner">
                    <mat-option *ngFor="let o of ValueChain | keyvalue" [value]="o.value">
                        {{o.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field *ngIf="!isDisabled('legalEntities')">
                <mat-label>Legal Entities</mat-label>
                <mat-select multiple formControlName="legalEntities">
                    <mat-option *ngFor="let o of LegalEntity | keyvalue" [value]="o.value">
                        {{o.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field *ngIf="!isDisabled('mandates')">
                <mat-label>Mandates</mat-label>
                <mat-select multiple formControlName="mandates">
                    <mat-option *ngFor="let o of Mandates | keyvalue" [value]="o.value">
                        {{o.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field *ngIf="!isDisabled('technicalChangeTitle')">
                <mat-label>Technical Change Title</mat-label>
                <input matInput formControlName="technicalChangeTitle">
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <users-selector (selectedUsersChange)="changeSelectedApplicant($event)" title="Applicant name" 
                [basicStyling]="true" [singleSelection]="true" *ngIf="!isDisabled('applicantName')" 
                [initUsers]="applicantNameUsers" [displayTitle]="false" [centeredDisplay]="false" [isMandatory]="true"
                [isReadOnly]="isReadOnly('applicantName')">
            </users-selector>
        </div>
        <div fxLayout="column">
            <users-selector (selectedUsersChange)="changeSelectedCoordinator($event)" title="Coordinated by" 
                [basicStyling]="true" [singleSelection]="false" *ngIf="!isDisabled('coordinatedBy')"
                [initUsers]="coordinatedByUsers" [displayTitle]="false" [centeredDisplay]="false" [isMandatory]="true"
                [isReadOnly]="isReadOnly('coordinatedBy')">
            </users-selector>
        </div>
    </form>
</div>