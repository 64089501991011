import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OperationalResponsibility, OperationalResponsibilityProcessArea } from '../../model/responsibility';
import processAreasJson from '../../model/enum/operational-responsibilities.json';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-responsibility-selector',
  templateUrl: './responsibility-selector.component.html',
  styleUrls: ['./responsibility-selector.component.css']
})
export class ResponsibilitySelectorComponent implements OnInit, OnDestroy {
  @Input() inResponsibilityTasks?: OperationalResponsibility[]; 
  @Input() readOnlyMode?: boolean;
  @Input() isNew: boolean = false;
  @Output() responsibilityTasksOut = new EventEmitter<OperationalResponsibility[]>();
  public responsibilityTasks: OperationalResponsibility[] = [];
  public form!: FormGroup;
  private processAreas: OperationalResponsibilityProcessArea[] = processAreasJson;
  private subscriptions: Subscription[] = [];

  constructor() {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      processArea: new FormControl(undefined, []),
      responsible: new FormControl(undefined, []),
      task: new FormControl(undefined, [])
    });
    if (this.isNew) {
      this.responsibilityTasks = this.convertToOperationalResponsibilities(this.processAreas);
    }
    else if (this.inResponsibilityTasks) {
      this.responsibilityTasks = this.inResponsibilityTasks;
    }
    this.emitValue();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.error('Failed to unsubscribe:', error);
      }
    });
  }

  private convertToOperationalResponsibilities(processAreas: OperationalResponsibilityProcessArea[]): OperationalResponsibility[] {
    const operationalResponsibilities: OperationalResponsibility[] = [];
    for (const processArea of processAreas) {
      for (const responsible of processArea.responsibles) {
        operationalResponsibilities.push({
          team: processArea.name,
          location: responsible.name,
          tasks: responsible.tasks
        });
      }
    }
    return operationalResponsibilities;
  }

  public canBeAdded(): boolean {
    return this.form.get('processArea')?.value !== undefined && this.form.get('responsible')?.value !== undefined && this.form.get('task')?.value !== undefined;
  }

  public add(){
    //search for an already existing responsibility according to parameters
    if (!this.canBeAdded()) {
      return;
    }
    
    let inserted = false;
    this.responsibilityTasks.forEach((element: OperationalResponsibility) => {
      if (element.team === this.form.get('processArea')?.value && element.location === this.form.get('responsible')?.value) {
        if(element.tasks.indexOf(this.form.get('task')?.value) === -1){
          element.tasks.push(this.form.get('task')?.value!) 
        };
        inserted = true;
        return;
      }
    });

    if (!inserted) {
      this.responsibilityTasks.push({
        team: this.form.get('processArea')?.value,
        location: this.form.get('responsible')?.value,
        tasks: [this.form.get('task')?.value]
      });
      this.emitValue();
    }

    this.form.get('processArea')?.setValue(undefined);
    this.form.get('responsible')?.setValue(undefined);
    this.form.get('task')?.setValue(undefined);
  }

  public remove(location: string, team: string, task: string){
    //search for an already existing responsibility according to parameters
    this.responsibilityTasks.forEach(element => {
      
      if(element.location === location && element.team === team){
        const elementIndex = element.tasks.indexOf(task)
        if(elementIndex > -1){
          element.tasks.splice(elementIndex, 1)
        }
        
        if(element.tasks.length === 0){
          this.responsibilityTasks.splice(this.responsibilityTasks.indexOf(element), 1)
        }
      }
    });
    this.emitValue();
  }

  public getResponsibilityTasks(): OperationalResponsibility[] {
    return this.responsibilityTasks;
  }

  public emitValue(): void {
    this.responsibilityTasksOut.emit(this.responsibilityTasks);
  }
}
