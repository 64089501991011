<h1 style="margin-left: 5%; margin-top: 20px; margin-bottom: 20px;">What products are you looking for?</h1>

<div class="search-box">
  <div class="search-bar-container">
    <form [formGroup]="searchForm">
      <mat-form-field appearance="fill" class="search-bar">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Product name.. or Murex identifier" (input)="textSearch($event)"
          formControlName="search">
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="!isLoading" class="excel-export">
    <div *ngIf="!isExporting">
      <button mat-icon-button (click)="excelExport()" matTooltip="Export to Excel">
        <mat-icon aria-label="Export to Excel">save_alt</mat-icon>
      </button>
    </div>
    <div *ngIf="isExporting" class="npc-request-spinner">
      <mat-spinner [diameter]="24"></mat-spinner>
    </div>
  </div>
</div>

<app-product-catalogue-tree-filter (resultChanged)="treeFilterChanged($event)" [products]="allProducts" 
  [clearFilters]="clearFilters" *ngIf="allProducts">
</app-product-catalogue-tree-filter>

<div class="grid-container">

  <mat-grid-list cols="6" rowHeight="3:1">
    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Product group</mat-label>
        <mat-select multiple [(value)]="selectedProductGroups" (valueChange)="filterChanged($event)">
          <mat-option [disabled]="true">No Filter</mat-option>
          <mat-option *ngFor="let productGroup of productGroups" [value]="productGroup.value">
            {{productGroup.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedProductGroup" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedProductGroup=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Product</mat-label>
        <mat-select multiple [(value)]="selectedProducts" (valueChange)="filterChanged($event)">
          <mat-option [disabled]="true">No Filter</mat-option>
          <mat-option *ngFor="let product of products" [value]="product.value">
            {{product.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedProduct" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedProduct=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Market</mat-label>
        <mat-select multiple="" [(value)]="selectedMarkets" (valueChange)="filterChanged($event)">
          <mat-option [disabled]="true">No Filter</mat-option>
          <mat-option *ngFor="let market of markets" [value]="market.value">
            {{market.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedMarket" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedMarket=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Commodity</mat-label>
        <mat-select multiple [(value)]="selectedCommodities" (valueChange)="filterChanged($event)">
          <mat-option [disabled]="true">No Filter</mat-option>
          <mat-option *ngFor="let commodity of commodities" [value]="commodity.value">
            {{commodity.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedCommodity" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedCommodity=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Platform</mat-label>
        <mat-select [(value)]="selectedPlatform" (valueChange)="filterChanged($event)">
          <mat-option>No Filter</mat-option>
          <mat-option *ngFor="let platform of platforms" [value]="platform.value">
            {{platform.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedPlatform" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedPlatform=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Legal entity</mat-label>
        <mat-select [(value)]="selectedLegalEntity" (valueChange)="filterChanged($event)">
          <mat-option>No Filter</mat-option>
          <mat-option *ngFor="let legalEntity of legalEntities" [value]="legalEntity.value">
            {{legalEntity.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedLegalEntity" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedLegalEntity=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Settlement</mat-label>
        <mat-select [(value)]="selectedSettlement" (valueChange)="filterChanged($event)">
          <mat-option *ngFor="let settlement of settlements" [value]="settlement.value">
            {{settlement.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedSettlement" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedSettlement=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>  
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Profile</mat-label>
        <mat-select [(value)]="selectedProfile" (valueChange)="filterChanged($event)">
          <mat-option *ngFor="let profile of profiles" [value]="profile.value">
            {{profile.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedProfile" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedProfile=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Currency</mat-label>
        <mat-select [(value)]="selectedCurrency" (valueChange)="filterChanged($event)">
          <mat-option *ngFor="let currency of currencies" [value]="currency.value">
            {{currency.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedCurrency" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedCurrency=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Unit</mat-label>
        <mat-select [(value)]="selectedUnit" (valueChange)="filterChanged($event)">
          <mat-option *ngFor="let unit of units" [value]="unit.value">
            {{unit.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedUnit" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedUnit=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Traded Via</mat-label>
        <mat-select [(value)]="selectedTradedVia" (valueChange)="filterChanged($event)">
          <mat-option>No Filter</mat-option>
          <mat-option *ngFor="let traded of tradedVia" [value]="traded.value">
            {{traded.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedTradedVia" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedTradedVia=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="filter">
        <mat-label>Mandates</mat-label>
        <mat-select [(value)]="selectedMandates" (valueChange)="filterChanged($event)">
          <mat-option>No Filter</mat-option>
          <mat-option *ngFor="let mandate of mandates" [value]="mandate.value">
            {{mandate.value}}
          </mat-option>
        </mat-select>
        <button *ngIf="selectedMandates" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="selectedMandates=undefined; $event.stopPropagation(); filterChanged($event);">
          <mat-icon class="icon-in-table">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
        <button *ngIf="hasFilter()" matSuffix mat-icon-button type="button" aria-label="Clear"
          (click)="cleanAllFilters(); $event.stopPropagation(); filterChanged($event);">
          Clear all filters<mat-icon class="icon-in-table">close</mat-icon>
        </button>
    </mat-grid-tile>

  </mat-grid-list>

  </div>

<div *ngIf="isRefreshing || isLoading" class="progress-bar">
  <div class="progress-bar-text" *ngIf="isRefreshing">The products list is being refreshed in the background...</div>
  <div class="progress-bar-text" *ngIf="!isRefreshing">The products list is being loaded...</div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="tableDataSource">
  <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z8"
    style="width: 95%; font-size: x-small; margin-left: 2.5%;">

    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="npcId">
      <th mat-header-cell *matHeaderCellDef matTooltip="Link to existing NPC documentation."> NPC Id
      </th>
      <td mat-cell *matCellDef="let element"> 
        <!--span *ngFor="let link of extractLinks(element.npc)">
          <a href="{{link.url}}" target="_blank">{{link.name}}</a> &nbsp;
        </span-->
        
        <span *ngIf="extractLinks(element.npc).length > 0; else noLinks">
          <span *ngFor="let link of extractLinks(element.npc)">
            <a href="{{link.url}}" target="_blank">{{link.name}}</a> &nbsp;
          </span>
        </span>
        <ng-template #noLinks>
          <!-- Add your alternative content here -->
          <span>{{element.npc}}</span>
        </ng-template>
        
      </td>
    </ng-container>

    <ng-container matColumnDef="productGroup">
      <th mat-header-cell *matHeaderCellDef matTooltip="A product group contains products of similar type."> Product Group 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.productGroup}} </td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef matTooltip="Describes the precise name of a product."> Product 
      </th>
      <td mat-cell *matCellDef="let element">
        <!--a href="/product?name={{element.product}}" target="_blank"-->
        {{element.product}} 
        <!--mat-icon
            class="icon-in-table">open_in_new</mat-icon> </a-->
      </td>
    </ng-container>

    <ng-container matColumnDef="instrument">
      <th mat-header-cell *matHeaderCellDef matTooltip="Instrument describes the type of instrument (future, forward, option, swap) the product is. It is not necessarily what is used as the MX instrument."> Instrument 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.instrument}} </td>
    </ng-container>

    <ng-container matColumnDef="tradedVia">
      <th mat-header-cell *matHeaderCellDef matTooltip="Shows via what type of platform the product is traded. E.g., if it is traded via exchange or OTC."> Traded Via 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.tradedVia}} </td>
    </ng-container>

    <ng-container matColumnDef="platform">
      <th mat-header-cell *matHeaderCellDef matTooltip="Shows the name of the platform or exchange where the product is listed.
      For bilaterally traded products it could show B2B or the TSO, depending on the product."> Platform / Exchange 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.platform}} </td>
    </ng-container>

    <ng-container matColumnDef="commodity">
      <th mat-header-cell *matHeaderCellDef matTooltip="Shows the underlying commodity of a deal. For products which contain multiple commodities by nature (e.g., VPPs), it states 'cross-commodity'."> Commodity 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.commodity}} </td>
    </ng-container>

    <ng-container matColumnDef="settlement">
      <th mat-header-cell *matHeaderCellDef matTooltip="States whether a deal has a financial or physical settlement. Physical settlement means physical delivery of a commodity."> Settlement 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.settlement}} </td>
    </ng-container>

    <ng-container matColumnDef="market">
      <th mat-header-cell *matHeaderCellDef matTooltip="Explains the geographical market where a product is traded. 
      For power: countries where it is traded.
      For gas: gas hub where gas is traded.
      Oil: location where oil is coming from (e.g. Brent).
      Coal: the different harbours.
      Cross-border: borders which can be traded
      Please note: for location swaps, both locations (e.g., TTF and THE) need to be in the list to be able to trade."> Market 
      </th>
      <td mat-cell *matCellDef="let element"> {{element.market}} </td>
    </ng-container>

    <ng-container matColumnDef="marketDetail">
      <th mat-header-cell *matHeaderCellDef matTooltip="Specifies the different pricing zones for the Nordics and Italy and the different borders for cross-border capacities."> Market Detail
      </th>
      <td mat-cell *matCellDef="let element"> {{element.marketDetail}} </td>
    </ng-container>

    <ng-container matColumnDef="tenor">
      <th mat-header-cell *matHeaderCellDef matTooltip="Only applicable for exchange traded products. Shows the tenor/maturity of a product. E.g., a monthly future"> Tenor </th>
      <td mat-cell *matCellDef="let element"> {{element.tenor}} </td>
    </ng-container>

    <ng-container matColumnDef="profile">
      <th mat-header-cell *matHeaderCellDef matTooltip="Power: explains whether a products is e.g., peak, base or according to a customer specific profile.
      Gas: not applicable for exchange traded products. For bilateral supply contracts it explains the customer specific profile.
      Oil: not applicable"> Profile </th>
      <td mat-cell *matCellDef="let element"> {{element.profile}} </td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef matTooltip="Currency in which the product is traded."> Currency </th>
      <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
    </ng-container>

    <ng-container matColumnDef="unit" >
      <th mat-header-cell *matHeaderCellDef matTooltip="Unit in which the product is traded."> Unit </th>
      <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
    </ng-container>

    <ng-container matColumnDef="legalEntity">
      <th mat-header-cell *matHeaderCellDef matTooltip="Explains under what legal entity a product can be traded."> Legal Entity </th>
      <td mat-cell *matCellDef="let element"> {{element.legalEntity}} </td>
    </ng-container>

    <ng-container matColumnDef="optionMargingType">
      <th mat-header-cell *matHeaderCellDef matTooltip="Only applicable for options. Explains the margining type i.e. when the option premium payment is due (equity or future style)."> Option Margin Type </th>
      <td mat-cell *matCellDef="let element"> {{element.optionMargingType}} </td>
    </ng-container>

    <ng-container matColumnDef="exchangeProductCode">
      <th mat-header-cell *matHeaderCellDef matTooltip="Productcode as provided by the exchange."> Exchange Product Code </th>
      <td mat-cell *matCellDef="let element"> {{element.exchangeProductCode}} </td>
    </ng-container>

    <ng-container matColumnDef="exchangeProductName">
      <th mat-header-cell *matHeaderCellDef matTooltip="Productname as provided by the exchange."> Exchange Product Name </th>
      <td mat-cell *matCellDef="let element"> {{element.exchangeProductName}}</td>
    </ng-container>

    <ng-container matColumnDef="usedMxInstrument">
      <th mat-header-cell *matHeaderCellDef matTooltip="Shows which instrument should be used in MX for deal booking."> Used MX instrument </th>
      <td mat-cell *matCellDef="let element"> {{element.usedMXInstrument}} </td>
    </ng-container>

    <ng-container matColumnDef="classification">
      <th mat-header-cell *matHeaderCellDef matTooltip="Classification according to REMIT (Standard, Non-Standard)."> Classification </th>
      <td mat-cell *matCellDef="let element"> {{element.classification}} </td>
    </ng-container>

    <ng-container matColumnDef="productId">
      <th mat-header-cell *matHeaderCellDef matTooltip="Unique identifier of products. To be used to identify a product in Alpiq Systems."> Product UID </th>
      <td mat-cell *matCellDef="let element"> {{element.productId}} </td>
    </ng-container>

    <ng-container matColumnDef="marketAccess">
      <th mat-header-cell *matHeaderCellDef matTooltip="Explains whether a product is offering with market access. This could be either for external counterparties (to a specific market) or for internal usage (e.g. AAG provides market access to EEX for ASE)."> Market Access </th>
      <td mat-cell *matCellDef="let element"> {{element.marketAccess}} </td>
    </ng-container>

    <ng-container matColumnDef="buySell">
      <th mat-header-cell *matHeaderCellDef matTooltip="Explains whether a product can be bought, sold or both."> Buy / Sell </th>
      <td mat-cell *matCellDef="let element"> {{element.buySell}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef matTooltip="Shows whether a product is active, and can therefore be traded. Or if a product is deactivated, and therefore cannot be traded."> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <ng-container matColumnDef="validityStartDate">
      <th mat-header-cell *matHeaderCellDef matTooltip="In case a product is valid for only a certain timeframe."> Validity Start Date </th>
      <td mat-cell *matCellDef="let element"> {{element.validityStartDate }} </td>
    </ng-container>

    <ng-container matColumnDef="validityEndDate">
      <th mat-header-cell *matHeaderCellDef matTooltip="When set, it defines the end date of validity for the product"> Validity End Date </th>
      <td mat-cell *matCellDef="let element"> {{element.validityEndDate }} </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef matTooltip="Any additional comments."> Comment </th>
      <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #0a384f;"></tr>
    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;" 
      [ngClass]="{'tableRowGray': even, 'deactivated': row.status === 'Deactivated'}" 
      (dblclick)="expandProductView(row)">
    </tr>
  </table>
</div>

<div class="fab-container">
  <button mat-mini-fab color="primary" (click)="openColumnConfigDialog()">
    <mat-icon>tune</mat-icon>
  </button>
</div>