export const Mandates: string[] = [
    "Asset - CH",
    "Asset - Italy",
    "Asset - Spain",
    "Asset - Hungary",
    "Asset - France",
    "Asset - Nordics",
    "Trading - Admin",
    "Trading - Gas",
    "Trading - Prop West",
    "Trading - Prop East",
    "Trading - Stanari",
    "Origination - NCS",
    "Origination - CH",
    "Origination - Germany",
    "Origination - France",
    "Origination - Hungary",
    "Origination - Italy",
    "Origination - Nordics",
    "Origination - Spain"
];