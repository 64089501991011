<h2 mat-dialog-title class="title">{{action}} for request '{{productNumber}}'</h2>
<div class="approval-request-container">
  <div mat-dialog-content>
    <form [formGroup]="approvalRequestForm">
      <div>
        <users-selector (selectedUsersChange)="changeSelectedUsers($event)" title="Select approvers" [isMandatory]="true">
        </users-selector>
      </div>
      <div>
        <mat-form-field class="form-element-full-width">
          <textarea matInput formControlName="requestText" required class="approval-text"></textarea>
          <mat-label>Approval request text</mat-label>
        </mat-form-field>
      </div>
    </form>
    <div *ngIf="error" class="action-error">
      Request approval could not be sent because an error occurred: {{error}}
    </div>
  </div>
</div>
<div mat-dialog-actions class="actions-button-container">
  <button mat-raised-button color="primary" (click)="confirm()" [disabled]="!isValid() ||  isSaving" class="actions-button">OK</button>
  <button mat-raised-button color="primary" mat-dialog-close (click)="cancel()" class="actions-button" *ngIf="!isSaving">
    {{error ? 'Close' : 'Cancel'}}
  </button>
  <div *ngIf="isSaving" class="npc-request-spinner">
    <mat-spinner [diameter]="24"></mat-spinner>
  </div>
</div>