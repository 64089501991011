<form [formGroup]="form">
  <div class="grid-container">
    <div class="grid-item">
      <table>
        <tr class="radio-field" *ngIf="!isDisabled('followStandardProcess')">
          <td>
            <mat-label>Follows standard process?</mat-label>
          </td>
          <td>
            <mat-radio-group formControlName="followStandardProcess">
              <mat-radio-button color="primary" [value]="true" [checked]="form.get('followStandardProcess')?.value===true">Yes</mat-radio-button>
              <mat-radio-button color="primary" [value]="false" [checked]="form.get('followStandardProcess')?.value===false">No</mat-radio-button>
            </mat-radio-group>
          </td>          
        </tr>
        <tr class="radio-field" *ngIf="!isDisabled('confirmationNeeded')">
          <td>
            <mat-label>Confirmation needed?</mat-label>
          </td>
          <td>
            <mat-radio-group formControlName="confirmationNeeded">
              <mat-radio-button color="primary" [value]="true" [checked]="form.get('confirmationNeeded')?.value===true">Yes</mat-radio-button>
              <mat-radio-button color="primary" [value]="false" [checked]="form.get('confirmationNeeded')?.value===false">No</mat-radio-button>
            </mat-radio-group>
          </td>  
        </tr>
        <tr class="radio-field" *ngIf="!isDisabled('newDealTicketPrefixNeeded')">
          <td>
            <mat-label>New deal ticket pre-fix needed?</mat-label>
          </td>
          <td>
            <mat-radio-group formControlName="newDealTicketPrefixNeeded">
              <mat-radio-button color="primary" [value]="true" [checked]="form.get('newDealTicketPrefixNeeded')?.value===true">Yes</mat-radio-button>
              <mat-radio-button color="primary" [value]="false" [checked]="form.get('newDealTicketPrefixNeeded')?.value===false">No</mat-radio-button>
            </mat-radio-group>
          </td>       
        </tr>
        <tr class="radio-field" *ngIf="!isDisabled('remitReportNeeded')">
          <td>
            <mat-label>Remit reporting needed?</mat-label>
          </td>
          <td>
            <mat-radio-group formControlName="remitReportNeeded">
              <mat-radio-button color="primary" [value]="true" [checked]="form.get('remitReportNeeded')?.value===true">Yes</mat-radio-button>
              <mat-radio-button color="primary" [value]="false" [checked]="form.get('remitReportNeeded')?.value===false">No</mat-radio-button>
            </mat-radio-group>
          </td>       
        </tr>
        <tr class="radio-field" *ngIf="!isDisabled('roboReportNeeded')">
          <td>
            <mat-label>Robo reporting needed?</mat-label>
          </td>
          <td>
            <mat-radio-group formControlName="roboReportNeeded">
              <mat-radio-button color="primary" [value]="true" [checked]="form.get('roboReportNeeded')?.value===true">Yes</mat-radio-button>
              <mat-radio-button color="primary" [value]="false" [checked]="form.get('roboReportNeeded')?.value===false">No</mat-radio-button>
            </mat-radio-group>
          </td>        
        </tr>
      </table>
    </div>
  </div>
</form>

<app-responsibility-selector [inResponsibilityTasks]="responsibilityTasks" 
  (responsibilityTasksOut)="updateResponsibilityTasks($event)" [readOnlyMode]="readOnlyMode" [isNew]="isNew">
</app-responsibility-selector>