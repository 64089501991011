import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-html-content',
  templateUrl: './html-content.component.html',
  styleUrls: ['./html-content.component.css']
})
export class HtmlContentComponent implements OnInit {

  @Input() path!: string;
  htmlContent?: string;

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.loadHtml();
  }

  async loadHtml() {
    //this.http.request('GET', this.htmlPath, { responseType: 'text' }).toPromise();
    
    console.log(this.router.url)
    this.htmlContent = await this.http.request('GET', this.path, { responseType: 'text' }).toPromise();
  }
}
