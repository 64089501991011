<div class="rich-text">
  <div class="actions-icon-container" *ngIf="!isReadOnly">
    <mat-card-actions *ngIf="isDisplayed">
      <div *ngIf="isEditorReadOnly && !isReadOnly">
        <button mat-icon-button (click)="goToEditMode()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div *ngIf="!isEditorReadOnly && !isReadOnly">
        <button mat-icon-button (click)="goToReadOnlyMode()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </div>
  <mat-expansion-panel  (opened)="isDisplayed = true" (closed)="isDisplayed = false" [expanded]="isDisplayed" style="flex: 1;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="rich-text-title" [ngClass]="{'rich-text-title-valid': isValid, 'rich-text-title-invalid': !isValid}">{{ title }}&nbsp;</span>
      </mat-panel-title>
      <span class="spacer"></span>
    </mat-expansion-panel-header>
    <div *ngIf="!isEditorReadOnly" class="rich-text-editor-container">
      <ckeditor [editor]="Editor" [(ngModel)]="editorData" debounce="500" (change)="onChange()"></ckeditor>
    </div>
    <div *ngIf="isEditorReadOnly" [innerHTML]="editorData | safeHtml"></div>
  </mat-expansion-panel>
  <span>
    <button mat-icon-button (click)="openHelp()" *ngIf="help"><mat-icon>help_outline</mat-icon></button>
  </span>
</div>