import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { datadogRum } from '@datadog/browser-rum';

if (environment.production) {
  enableProdMode();
}

datadogRum.init({
  applicationId: 'bb619746-0a8b-4a01-90a9-507eda339a1c',
  clientToken: 'puba34dd1de5530147a9d500011ff7a0a81',
  site: 'datadoghq.eu',
  service: 'product-management-tool',
  env: environment.id,
  // Specify a version number to identify the deployed version of your application in Datadog  TODO, maybe later
  // version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


