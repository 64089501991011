export const Product: string[] = [
    'American Option',
    'Ancillary Services - aFFR',
    'Ancillary Services - FCR',
    'Ancillary Services - mFFR',
    'Ancillary Services - RR',
    'Asian Option',
    'Asset Optimization (?)',
    'Balancing Fixed Margin',
    'Balancing Indexed Pricing',
    'Balancing Matching Principle',
    'Balancing Min/Max Imbalances',
    'Balancing Service',
    'Balancing Service ',
    'Capacity',
    'Capacity Certificate',
    'Certificate - Biomethane',
    'Certificate - White TEE',
    'Certificates - Biogas',
    'Certificates - Biomass',
    'Certificates - Blue',
    'Certificates - Brown',
    'Certificates - Elcert',
    'Certificates - EUA',
    'Certificates - Gas',
    'Certificates - green',
    'Certificates - Hydro',
    'Certificates - NUC',
    'Certificates - Red',
    'Certificates - RES',
    'Certificates - Solar',
    'Certificates - TEE',
    'Certificates - Violet',
    'Certificates - White',
    'Certificates - Wind',
    'Certificates - Yellow',
    'Continuous Market',
    'Day-Ahead Auction',
    'DS Futures',
    'DSM',
    'DSM ',
    'EPAD',
    'European Option',
    'Explicit Allocation',
    'Forward',
    'Full-Offtake',
    'Full-Offtake Capacity Certificate',
    'Full-Offtake Guarantees of Origin',
    'Full-Offtake Multiclick',
    'Full-Supply',
    'Full-Supply Bloc&Spot',
    'Full-Supply Capacity Certificate',
    'Full-Supply Certificate - Biomethane',
    'Full-Supply Certificate - White',
    'Full-Supply Guarantees of Origin',
    'Full-Supply Multiclick',
    'Full-Supply Volume Commitment',
    'Futures',
    'Guarantees of Origin',
    'Guarantees of Origin - local',
    'Intraday Auction',
    'LNG In-Store',
    'LT PPA fixed',
    'Offtake',
    'Offtake  gas indexed for CHPs',
    'Offtake  Profit Sharing',
    'Offtake Bloc&Spot',
    'Offtake Capacity Certificate',
    'Offtake Guarantees of Origin',
    'Offtake MinMax fixed',
    'Offtake Multiclick',
    'Physical Storage',
    'Physical Transport',
    'PPA',
    'Sub-Balancing Perimeter (?)',
    'Swap',
    'Swing',
    'VHP - Virtual Hydro Plant',
    'Virtual Storage',
    'Virtual Transport',
    'VPP - Virtual Power Plant',
    'VPS - Virtual Pump Storage'
];