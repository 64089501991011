export enum WorkflowActions {
  DRAFT_TO_NEW = 'Convert draft as new request',
  CANCEL = 'Cancel request',
  REQUEST_BU_HEAD_APPROVAL = 'Request BU Head approval',
  CREATE_IMPLEMENTATION = 'Create implementation',
  REQUEST_MANAGEMENT_APPROVAL = 'Request Management approval',
  REQUEST_TESTING = 'Request Testing',
  REQUEST_STAKEHOLDER_APPROVAL = 'Request Stakeholder approval',
  CREATE_PRODUCT = 'Create product',
  CHECK_PRODUCT = 'Check product creation',
  CLOSE = 'Close request',
};

export enum Status {
  DRAFT_STATUS = 'draft',
  NEW_STATUS = 'new',
  WAITING_FOR_BU_HEAD_APPROVAL_STATUS = 'waiting_for_bu_head_approval',
  WAITING_FOR_IMPLEMENTATION_DEFINITION = 'waiting_for_implementation_definition',
  ESTIMATION_COLLECTION_STATUS = 'estimation_collection',
  WAITING_FOR_MANAGEMENT_APPROVAL_STATUS = 'waiting_for_management_approval',
  READY_FOR_IMPLEMENTATION_STATUS = 'ready_for_implementation',
  READY_FOR_TESTING_STATUS = 'ready_for_testing',
  WAITING_FOR_STAKEHOLDER_APPROVAL_STATUS = 'waiting_for_stakeholder_approval',
  APPROVED_STATUS = 'approved',
  REJECTED_STATUS = 'rejected',
  CLOSED_STATUS = 'closed',
  CANCELLED_STATUS = 'cancelled',
};

const StatusLabel = {
  [Status.DRAFT_STATUS]: 'Draft',
  [Status.NEW_STATUS]: 'New',
  [Status.WAITING_FOR_BU_HEAD_APPROVAL_STATUS]: 'Waiting for BU Head approval',
  [Status.WAITING_FOR_IMPLEMENTATION_DEFINITION]: 'Waiting for JIRA implementation definition',
  [Status.ESTIMATION_COLLECTION_STATUS]: 'Waiting for the implementation estimate',
  [Status.WAITING_FOR_MANAGEMENT_APPROVAL_STATUS]: 'Waiting for Management approval',
  [Status.READY_FOR_IMPLEMENTATION_STATUS]: 'Ready for implementation',
  [Status.READY_FOR_TESTING_STATUS]: 'Ready for testing',
  [Status.WAITING_FOR_STAKEHOLDER_APPROVAL_STATUS]: 'Waiting for stakeholder approval',
  [Status.APPROVED_STATUS]: 'Approved',
  [Status.REJECTED_STATUS]: 'Rejected',
  [Status.CLOSED_STATUS]: 'Closed',
  [Status.CANCELLED_STATUS]: 'Cancelled',
};

export function getStatus(statusValue: string): Status | undefined {
  let status: Status | undefined = undefined;
  for (const key in Status) {
    if (Object.prototype.hasOwnProperty.call(Status, key) && Status[key as keyof typeof Status] === statusValue) {
      status = Status[key as keyof typeof Status];
    }
  }
  return status;
}

export function getStatusLabel(status: Status | string): string {
  if (typeof status === 'string') {
    const statusKey: Status | undefined = getStatus(status);
    if (statusKey === undefined) {
      return status;
    }
    else {
      return StatusLabel[statusKey];
    }
  }
  else {
    return StatusLabel[status];
  }
}

export interface StatusTransition {
  from?: Status;
  to?: Status;
  action: WorkflowActions;
  approvalType?: string;
};

export function getAllTransitions(): StatusTransition[] {
  return [
    {
      from: Status.DRAFT_STATUS,
      to: Status.NEW_STATUS,
      action: WorkflowActions.DRAFT_TO_NEW
    },
    {
      from: Status.NEW_STATUS,
      to: Status.WAITING_FOR_BU_HEAD_APPROVAL_STATUS,
      action: WorkflowActions.REQUEST_BU_HEAD_APPROVAL,
      approvalType: 'BU_HEAD_APPROVAL'
    },
    {
      from: Status.WAITING_FOR_IMPLEMENTATION_DEFINITION,
      to: Status.ESTIMATION_COLLECTION_STATUS,
      action: WorkflowActions.CREATE_IMPLEMENTATION
    },
    {
      from: Status.ESTIMATION_COLLECTION_STATUS,
      to: Status.WAITING_FOR_MANAGEMENT_APPROVAL_STATUS,
      action: WorkflowActions.REQUEST_MANAGEMENT_APPROVAL,
      approvalType: 'MANAGEMENT_APPROVAL'
    },
    {
      from: Status.READY_FOR_IMPLEMENTATION_STATUS,
      to: Status.READY_FOR_TESTING_STATUS,
      action: WorkflowActions.REQUEST_TESTING
    },
    {
      from: Status.READY_FOR_TESTING_STATUS,
      to: Status.WAITING_FOR_STAKEHOLDER_APPROVAL_STATUS,
      action: WorkflowActions.REQUEST_STAKEHOLDER_APPROVAL,
      approvalType: 'STAKEHOLDER_APPROVAL'
    },
    {
      from: Status.APPROVED_STATUS,
      to: Status.CLOSED_STATUS,
      action: WorkflowActions.CREATE_PRODUCT
    },
    {
      from: Status.APPROVED_STATUS,
      to: Status.CLOSED_STATUS,
      action: WorkflowActions.CLOSE
    },
    {
      from: undefined,
      to: Status.CANCELLED_STATUS,
      action: WorkflowActions.CANCEL
    },
    {
      from: undefined,
      to: undefined,
      action: WorkflowActions.CHECK_PRODUCT
    }
  ];
}

export function getTransitions(statusLabel: string): StatusTransition[] {
  let status: Status | undefined = getStatus(statusLabel);
  const filteredTransitions: StatusTransition[] = getAllTransitions()
    .filter((transition: StatusTransition) => transition.from === status || transition.from === undefined);
  return filteredTransitions;
}

export function getSimpleActions(): WorkflowActions[] {
  // return all "simple actions", which are not linked to a transition
  return getAllTransitions().filter((transition: StatusTransition) => transition.from === undefined && transition.to === undefined)
    .map((transition: StatusTransition) => transition.action);
}

export function getApprovalActions(): string[] {
  // return all "approval actions"
  return Object.values(WorkflowActions).filter((action: WorkflowActions) => action.toString().endsWith('approval'));
}

export function getStatusesBeforeImplementationNotDraft(): Status[] {
  return [Status.NEW_STATUS, Status.WAITING_FOR_BU_HEAD_APPROVAL_STATUS , Status.WAITING_FOR_IMPLEMENTATION_DEFINITION, 
    Status.ESTIMATION_COLLECTION_STATUS, Status.WAITING_FOR_MANAGEMENT_APPROVAL_STATUS];
}

export function getStatusBeforeWaitMgtApproval(): Status[] {
  return [Status.DRAFT_STATUS, Status.NEW_STATUS, Status.WAITING_FOR_BU_HEAD_APPROVAL_STATUS , Status.WAITING_FOR_IMPLEMENTATION_DEFINITION,
    Status.ESTIMATION_COLLECTION_STATUS];
}

