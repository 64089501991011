export const Currency: string[] = [
    "EUR",
    "DKK",
    "SEK",
    "NOK",
    "CHF",
    "PLN",
    "BGN",
    "HUF",
    "CZK",
    "SK",
    "BAM",
    "USD",
    "GBP"
];
