
export interface ProductCreate {
  buySell: string,
  classification: string,
  comment: string,
  commodity: string,
  currency: string,
  exchangeProductCode: string | null,
  exchangeProductName: string | null,
  instrument: string,
  legalEntity: string,
  market: string,
  marketAccess: string,
  marketDetail: string,
  name: string,
  npcId: string,
  npc: string,
  optionMarginType: string | null,
  platform: string | null,
  product: string,
  productGroup: string,
  productId: string | null,
  profile: string,
  settlement: string,
  status: string,
  tenor: string,
  tradedVia: string,
  unit: string,
  usedMXInstrument: string | null,
  startValidityDate: string | null,
  endValidityDate: string | null,
  mandates: string[],
  [key: string]: string | null | string[]; // index signature
}

export interface Product extends ProductCreate {
  id: string,
};

export interface ProductsDownload {
  isOutdated: boolean,
  products: ProductUi[],
};

export interface ProductUi extends Product {
  npcUrl: string,
  [key: string]: string | null | string[] // index signature
}

export const PRODUCT_STATUSES: string[] = ['Active', 'Deactivated'];
export const PRODUCT_UNIQUENESS_DEFAULT_FIELDS: string[] = [
  "buySell",
  "classification",
  "commodity",
  "currency",
  "exchangeProductCode",
  "exchangeProductName",
  "instrument",
  "legalEntity",
  "market",
  "marketDetail",
  "marketAccess",
  "optionMarginType",
  "platform",
  "product",
  "productGroup",
  "profile",
  "settlement",
  "tenor",
  "tradedVia",
  "unit"
];

export const PRODUCT_UNIQUENESS_SELECTABLE_FIELDS: string[] = [
  'buySell',
  'classification',
  'commodity',
  'currency',
  'exchangeProductCode',
  'exchangeProductName',
  'instrument',
  'legalEntity',
  'market',
  'marketDetail',
  'marketAccess',
  'optionMarginType',
  'platform',
  'product',
  'productGroup',
  'productId',
  'profile',
  'settlement',
  'tenor',
  'tradedVia',
  'unit',
  'usedMXInstrument',
];

export const REQUEST_TO_PRODUCT_MAPPING: any = {
  "genericInfo": {
    "legalEntities": "legalEntity",
    "productNumber": "npc",
    "mandates": "mandates"
  },
  "productDescription": {
    "classification": "classification",
    "commodity": "commodity",
    "currency": "currency",
    "exchangeProductCode": "exchangeProductCode",
    "exchangeProductName": "exchangeProductName",
    "instrument": "instrument",
    "market": "market",
    "optionMarginType": "optionMarginType",
    "platform": "platform",
    "product": "product",
    "productGroup": "productGroup",
    "profile": "profile",
    "settlement": "settlement",
    "tenor": "tenor",
    "tradedVia": "tradedVia",
    "unit": "unit",
    "marketAccess": "marketAccess",
    "mxInstrument": "usedMXInstrument",
    "direction": "buySell"
  },
  "other": {
    "productId": null, // not set for now
    "comment": null, // editable
    "start_validity_date": null, // editable
    "end_validity_date": null, // editable
    "status": "Active" // editable
  }
};

export const NOT_APPLICABLE: string = "Not applicable";

export const PRODUCTS_FIELDS_NULL_TO_NON_APPLICABLE: string[] = [
  "tenor", "option_margin_type", "exchange_product_code", "exchange_product_name"
];

export interface ExcelExportResponse {
  url: string;
};