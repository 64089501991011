<form [formGroup]="form">
    <rich-text-editor #instructions *ngIf="model && !isDisabled('instructions')" [metadata]="getRichTextMetadata('instructions')" title="Instructions" 
        [npcId]="npcId" [sectionId]="sectionId" fieldId="instructions" [isReadOnly]="isReadOnly('instructions')"
        (saveStatus)="onRichTextFieldSaveStatus($event, 'instructions')" (getStatus)="onRichTextFieldGetStatus($event, 'instructions')"
        [isDisplayed]="true" [isRequired]="false">
    </rich-text-editor>
    <br/>
    <div fxLayout="column">
        <mat-form-field class="form-element-full-width" *ngIf="!isDisabled('murexInstruments')">
            <mat-label>Murex Instruments</mat-label>
            <textarea matInput formControlName="murexInstruments" placeholder="Market risk">
            </textarea>
        </mat-form-field>
    </div>
    <div fxLayout="column">
        <mat-form-field class="form-element-full-width" *ngIf="!isDisabled('dealTicketPrefix')">
            <mat-label>Deal-Ticket pre-fix</mat-label>
            <textarea matInput formControlName="dealTicketPrefix" placeholder="Liquidity risk">
            </textarea>
        </mat-form-field>
    </div>
</form>